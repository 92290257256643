import ListTable from 'components/Control/ControlVision/ListTable'
import TableLoader from 'components/ui/Table/TableLoader'
import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect } from 'react'
import { useLocation } from 'wouter'

const ControlVisionList = () => {
  const { setTitle } = useContext(TitleContext)
  const [controlvisionList, controlvisionListAction] = useFetch()
  const [, setLocation] = useLocation()
  const { handleModal } = useContext(ModalContext)

  const [userList, userListAction] = useFetch()

  const [equipmentList, equipmentListAction] = useFetch()

  useEffect(() => {
    // Set title
    const controlvisionTitle = {
      name: 'Control Visió',
      buttons: [
        {
          name: 'Nou Control Visió',
          link: `/visioncontrol/nou/`
        }
      ]
    }
    setTitle(controlvisionTitle)

    // GET user
    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    userListAction(userListAPIParams)

    // GET equipment
    const equipmentListAPIParams = {
      url: `inventorymachine/isTool`,
      method: 'GET',
      messageKo: 'Error al recuperar dades'
    }
    equipmentListAction(equipmentListAPIParams)

    const controlvisionListAPIParams = {
      url: `visioncontrol`,
      method: 'GET',
      messageKo: 'Error al recuperar dades'
    }
    controlvisionListAction(controlvisionListAPIParams)

    //* Avoid bug that when inventory is deleted and user is redirected to this page, modal is still open
    handleModal('hide')
  }, [])

  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/visioncontrol/${item.Id}`)
  }

  return controlvisionList.data ? (
    <div className="flex flex-col w-full p-4 bg-white">
      <ListTable
        users={userList}
        equipments={equipmentList}
        items={controlvisionList.data}
        handleClick={handleClick}
      />
    </div>
  ) : (
    <div className="flex justify-center w-full">
      <div className="w-10/12">
        <TableLoader />
      </div>
    </div>
  )
}

export default ControlVisionList
