import esquemaTeixiduria from 'assets/img/esquema-teixiduria.png'
import ToolTipIcon from 'components/icons/ToolTipIcon'
import { ModalContext } from 'contexts/ModalContext'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import ThreadTable from './threadTableCompare'

export default function DescriptionForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter,
  threads
}) {
  // Context
  const { handleModal } = useContext(ModalContext)

  // Fetch
  const [getApiCallGetSemiFinished, setApiCallSemiFinished] = useFetch()

  useEffect(async () => {
    // CRUD: GET (Semifinished):
    const apiCallGetSemiFinished = {
      url: `semifinished`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    await Promise.all([setApiCallSemiFinished(apiCallGetSemiFinished)]).catch(
      (e) => {
        console.log(e)
      }
    )
  }, [])

  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Semielaborat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Semielaborat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.SemiFinished !== valuesAfter?.SemiFinished
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.SemiFinished
            : valuesAfter?.SemiFinished}
        </div>
      </div>

      {/* Tipus lligat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Tipus lligat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            getApiCallGetSemiFinished?.data?.find(
              (option) => option.semi === valuesBefore.SemiFinished
            )?.ligado !==
            getApiCallGetSemiFinished?.data?.find(
              (option) => option.semi === valuesAfter.SemiFinished
            )?.ligado
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? getApiCallGetSemiFinished?.data?.find(
                (option) => option.semi === valuesBefore.SemiFinished
              )?.ligado
            : getApiCallGetSemiFinished?.data?.find(
                (option) => option.semi === valuesAfter.SemiFinished
              )?.ligado}
        </div>
      </div>

      {/* Tabla: Ideal | Tol.Màx. */}
      <div className="grid grid-cols-12 col-span-12">
        <div className="grid grid-cols-12 col-span-12">
          <div className="grid grid-cols-12 col-span-9 col-start-4 col-end-13 gap-x-2">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 text-sm border-b border-r border-black font-ms-semi">
                Ideal
              </div>

              <div className="flex flex-row items-center justify-center w-full col-span-6 col-end-13 text-sm border-b border-black font-ms-semi">
                Tol. Màx.
              </div>
            </div>
          </div>
        </div>

        {/* Campo 'Passades': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Passades:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="w-full grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <div
                  className={`${
                    valuesBefore?.Repetitions !== valuesAfter?.Repetitions
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.Repetitions
                    : valuesAfter?.Repetitions}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div
                  className={`${
                    valuesBefore?.RepetitionsTolerance !==
                    valuesAfter?.RepetitionsTolerance
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.RepetitionsTolerance
                    : valuesAfter?.RepetitionsTolerance}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <div
                  className={`${
                    valuesBefore?.RepetitionsV2 !== valuesAfter?.RepetitionsV2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.RepetitionsV2
                    : valuesAfter?.RepetitionsV2}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div
                  className={`${
                    valuesBefore?.RepetitionsToleranceV2 !==
                    valuesAfter?.RepetitionsToleranceV2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.RepetitionsToleranceV2
                    : valuesAfter?.RepetitionsToleranceV2}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>u/cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Columnes': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Columnes:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="w-full grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <div
                  className={`${
                    valuesBefore?.Columns !== valuesAfter?.Columns
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.Columns
                    : valuesAfter?.Columns}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div
                  className={`${
                    valuesBefore?.ColumnsTolerance !==
                    valuesAfter?.ColumnsTolerance
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.ColumnsTolerance
                    : valuesAfter?.ColumnsTolerance}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <div
                  className={`${
                    valuesBefore?.ColumnsV2 !== valuesAfter?.ColumnsV2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.ColumnsV2
                    : valuesAfter?.ColumnsV2}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div
                  className={`${
                    valuesBefore?.ColumnsToleranceV2 !==
                    valuesAfter?.ColumnsToleranceV2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.ColumnsToleranceV2
                    : valuesAfter?.ColumnsToleranceV2}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>u/cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Gramatge': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Gramatge:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="w-full grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <div
                  className={`${
                    valuesBefore?.Weight !== valuesAfter?.Weight
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left' ? valuesBefore?.Weight : valuesAfter?.Weight}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div
                  className={`${
                    valuesBefore?.WeightTolerance !==
                    valuesAfter?.WeightTolerance
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.WeightTolerance
                    : valuesAfter?.WeightTolerance}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <div
                  className={`${
                    valuesBefore?.WeightV2 !== valuesAfter?.WeightV2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.WeightV2
                    : valuesAfter?.WeightV2}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div
                  className={`${
                    valuesBefore?.WeightToleranceV2 !==
                    valuesAfter?.WeightToleranceV2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.WeightToleranceV2
                    : valuesAfter?.WeightToleranceV2}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>g/m2</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Ample': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Ample:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="w-full grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <div
                  className={`${
                    valuesBefore?.Width !== valuesAfter?.Width
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left' ? valuesBefore?.Width : valuesAfter?.Width}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div
                  className={`${
                    valuesBefore?.WidthTolerance !== valuesAfter?.WidthTolerance
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.WidthTolerance
                    : valuesAfter?.WidthTolerance}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <div
                  className={`${
                    valuesBefore?.WidthV2 !== valuesAfter?.WidthV2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.WidthV2
                    : valuesAfter?.WidthV2}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div
                  className={`${
                    valuesBefore?.WidthToleranceV2 !==
                    valuesAfter?.WidthToleranceV2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.WidthToleranceV2
                    : valuesAfter?.WidthToleranceV2}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Gruix': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Gruix:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="w-full grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <div
                  className={`${
                    valuesBefore?.Thickness !== valuesAfter?.Thickness
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.Thickness
                    : valuesAfter?.Thickness}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div
                  className={`${
                    valuesBefore?.ThicknessTolerance !==
                    valuesAfter?.ThicknessTolerance
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.ThicknessTolerance
                    : valuesAfter?.ThicknessTolerance}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <div
                  className={`${
                    valuesBefore?.ThicknessV2 !== valuesAfter?.ThicknessV2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.ThicknessV2
                    : valuesAfter?.ThicknessV2}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div
                  className={`${
                    valuesBefore?.ThicknessToleranceV2 !==
                    valuesAfter?.ThicknessToleranceV2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.ThicknessToleranceV2
                    : valuesAfter?.ThicknessToleranceV2}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>mm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Rdt Nominal': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Rdt Nominal:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="w-full grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <div
                  className={`${
                    valuesBefore?.RDTNominal !== valuesAfter?.RDTNominal
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.RDTNominal
                    : valuesAfter?.RDTNominal}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div
                  className={`${
                    valuesBefore?.RDTNominalTolerance !==
                    valuesAfter?.RDTNominalTolerance
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.RDTNominalTolerance
                    : valuesAfter?.RDTNominalTolerance}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <div
                  className={`${
                    valuesBefore?.RDTNominalV2 !== valuesAfter?.RDTNominalV2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.RDTNominalV2
                    : valuesAfter?.RDTNominalV2}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div
                  className={`${
                    valuesBefore?.RDTNominalToleranceV2 !==
                    valuesAfter?.RDTNominalToleranceV2
                      ? side === 'left'
                        ? 'bg-red-100 border-red-300'
                        : isAnswered
                        ? 'bg-emerald-100 border-emerald-300'
                        : 'bg-amber-100 border-amber-300'
                      : 'border-gray-300 bg-gray-100'
                  } w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border outline-none text-grey-darker`}
                >
                  {side === 'left'
                    ? valuesBefore?.RDTNominalToleranceV2
                    : valuesAfter?.RDTNominalToleranceV2}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>m/kg</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Comentari': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="block col-span-12 my-1 text-sm text-grey-darker font-ms-semi">
            Comentari:
          </div>

          <div
            className={`${
              valuesBefore?.LaboratoryComment !== valuesAfter?.LaboratoryComment
                ? side === 'left'
                  ? 'bg-red-100 border-red-300'
                  : isAnswered
                  ? 'bg-emerald-100 border-emerald-300'
                  : 'bg-amber-100 border-amber-300'
                : 'border-gray-300 bg-gray-100'
            } col-span-12 flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border`}
          >
            {side === 'left'
              ? valuesBefore?.LaboratoryComment
              : valuesAfter?.LaboratoryComment}
          </div>
        </div>
      </div>

      {/* Lligat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Lligat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.TiedDescription !== valuesAfter?.TiedDescription
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.TiedDescription
            : valuesAfter?.TiedDescription}
        </div>
      </div>

      {/* Diagrama: */}
      <div className="flex col-span-12 rounded">
        <p className="mr-1 text-right">
          1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />
          10
          <br />
          11
          <br />
          12
          <br />
        </p>

        <div
          name="EncryptedCode"
          className={`${
            valuesBefore?.EncryptedCode !== valuesAfter?.EncryptedCode
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } w-full px-2 py-1 pt-0 overflow-hidden leading-tight border rounded-sm resize-none faden`}
          style={{
            lineHeight: '1.5rem',
            whiteSpace: 'pre-wrap'
          }}
        >
          {side === 'left'
            ? valuesBefore?.EncryptedCode
            : valuesAfter?.EncryptedCode}
        </div>

        <div
          onClick={() =>
            handleModal(<img src={esquemaTeixiduria} alt="llegenda" />)
          }
          className="cursor-pointer"
          style={{ height: 'fit-content' }}
        >
          <ToolTipIcon width={20} height={20} className="ml-2 mr-1" />
        </div>
      </div>

      {/* Màquina */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-sem">
        Màquina:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-4/12 h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.MachineCode !== valuesAfter?.MachineCode
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.MachineCode
            : valuesAfter?.MachineCode}
        </div>
        <div
          className={`flex items-center w-8/12 h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.Machine !== valuesAfter?.Machine
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Machine : valuesAfter?.Machine}
        </div>
      </div>

      {/* Plegador */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-sem">
        Plegador:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.FoldType !== valuesAfter?.FoldType
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.FoldType : valuesAfter?.FoldType}
        </div>
      </div>

      {/* GG - ø */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        GG - ø:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.GG !== valuesAfter?.GG
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.GG : valuesAfter?.GG}
        </div>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.O !== valuesAfter?.O
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.O : valuesAfter?.O}
        </div>
      </div>

      {/* Nº Jocs */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Nº Jocs:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.NGame !== valuesAfter?.NGame
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.NGame : valuesAfter?.NGame}
        </div>
      </div>

      {/* Agulles */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Agulles:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.Needle !== valuesAfter?.Needle
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Needle : valuesAfter?.Needle}
        </div>
      </div>

      {/* Rpm */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Rpm:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.Rpm !== valuesAfter?.Rpm
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Rpm : valuesAfter?.Rpm}
        </div>
      </div>

      {/* Rpm Mín. */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Rpm Mín.:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.RpmMin !== valuesAfter?.RpmMin
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.RpmMin : valuesAfter?.RpmMin}
        </div>
      </div>

      {/* V. / Kg */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Kg:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.TurnsPerKg !== valuesAfter?.TurnsPerKg
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.TurnsPerKg : valuesAfter?.TurnsPerKg}
        </div>
        <span>&plusmn;</span>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.TurnsPerKgTolerance !==
            valuesAfter?.TurnsPerKgTolerance
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.TurnsPerKgTolerance
            : valuesAfter?.TurnsPerKgTolerance}
        </div>
      </div>

      {/* V. / Peça (Reals) */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Peça (Reals):
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.TurnsPerPart !== valuesAfter?.TurnsPerPart
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.TurnsPerPart
            : valuesAfter?.TurnsPerPart}
        </div>
      </div>

      {/* V. / Peça (Fitxa) */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Peça (Fitxa):
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.TurnsPerPartByOperator !==
            valuesAfter?.TurnsPerPartByOperator
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.TurnsPerPartByOperator
            : valuesAfter?.TurnsPerPartByOperator}
        </div>
        <span>&plusmn;</span>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.TurnsPerPartByOperatorTolerance !==
            valuesAfter?.TurnsPerPartByOperatorTolerance
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.TurnsPerPartByOperatorTolerance
            : valuesAfter?.TurnsPerPartByOperatorTolerance}
        </div>
      </div>

      {/* Kg peça */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Kg peça:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.KgPart !== valuesAfter?.KgPart
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.KgPart : valuesAfter?.KgPart}
        </div>
        <span>&plusmn;</span>
        <div
          className={`flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.KgPartTolerance !== valuesAfter?.KgPartTolerance
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.KgPartTolerance
            : valuesAfter?.KgPartTolerance}
        </div>
      </div>

      {/* Pass. Seguretat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Pass. Seguretat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center ${
            valuesBefore?.SecurityStep === 'true' ||
            valuesBefore?.SecurityStep ||
            valuesAfter?.SecurityStep === 'true' ||
            valuesAfter?.SecurityStep
              ? 'w-4/12'
              : 'w-full'
          } h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
            valuesBefore?.SecurityStep !== valuesAfter?.SecurityStep
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.SecurityStep
              ? 'Sí'
              : 'No'
            : valuesAfter?.SecurityStep
            ? 'Sí'
            : 'No'}
        </div>

        {(valuesBefore?.SecurityStep === 'true' ||
          valuesBefore?.SecurityStep ||
          valuesAfter?.SecurityStep === 'true' ||
          valuesAfter?.SecurityStep) && (
          <div
            className={`flex items-center w-8/12 h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 border ${
              valuesBefore?.SecurityStepDescription !==
              valuesAfter?.SecurityStepDescription
                ? side === 'left'
                  ? 'bg-red-100 border-red-300'
                  : isAnswered
                  ? 'bg-emerald-100 border-emerald-300'
                  : 'bg-amber-100 border-amber-300'
                : 'border-gray-300 bg-gray-100'
            } rounded-sm overflow-ellipsis`}
          >
            {side === 'left'
              ? valuesBefore?.SecurityStepDescription === undefined ||
                !valuesBefore?.SecurityStepDescription
                ? 'Segons cartell'
                : valuesBefore?.SecurityStepDescription
              : valuesAfter?.SecurityStepDescription === undefined ||
                !valuesAfter?.SecurityStepDescription
              ? 'Segons cartell'
              : valuesAfter?.SecurityStepDescription}
          </div>
        )}
      </div>

      {/* Tabla: Hilos */}
      <div className="w-full col-span-12">
        <ThreadTable
          threads={threads}
          numberGames={
            side === 'left'
              ? valuesBefore?.NGame
                ? Number(valuesBefore?.NGame)
                : 0
              : valuesAfter?.NGame
              ? Number(valuesAfter?.NGame)
              : 0
          }
          side={side}
          isAnswered={isAnswered}
          valuesBefore={valuesBefore}
          valuesAfter={valuesAfter}
        />
      </div>
    </div>
  )
}

DescriptionForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object,
  threads: PropTypes.any
}
