import Block from 'components/FTTeixiduria/Details/Block/BlockProto'
import { TitleContext } from 'contexts/TitleContext'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import Select from 'react-select'

export default function ProtoDetail() {
  // Contextos:
  const { setTitle } = useContext(TitleContext)

  // Estados:
  const [machineCode, setMachineCode] = useState('PLT')

  // Variables:
  const now = new Date()
  const day = now.getDate().toString().padStart(2, '0')
  const month = (now.getMonth() + 1).toString().padStart(2, '0')
  const year = now.getFullYear()
  const hours = now.getHours().toString().padStart(2, '0')
  const minutes = now.getMinutes().toString().padStart(2, '0')
  const formattedDateTime = `${day}/${month}/${year} - ${hours}:${minutes}h`
  const machineCodes = [
    { label: 'PLT', value: 'PLT' },
    { label: 'PAL', value: 'PAL' },
    { label: 'MCH', value: 'MCH' },
    { label: 'SNT', value: 'SNT' },
    { label: 'MYR', value: 'MYR' },
    { label: 'TRT', value: 'TRT' },
    { label: 'JMB', value: 'JMB' }
  ]

  useEffect(() => {
    const title = {
      name: `FITXA TÈCNICA PROTO`,
      button: 'print'
    }
    setTitle(title)
  }, [])

  // ############################################################

  return (
    <div className="p-4 print:p-0">
      <h1 className="hidden w-full print:flex print:justify-around print:items-center print:pb-2 print:m-0">
        <span className="flex items-center justify-center h-full text-2xl font-ms-bold">
          FITXA TÈCNICA TEIXIDURIA PROTO
        </span>

        <span className="flex flex-row items-center justify-end h-full pace-x-6">
          <span className="flex flex-col items-start justify-center h-full text-base">
            <span>{formattedDateTime}</span>
            <span>Format-IATF-018-G/3</span>
          </span>
        </span>
      </h1>

      <div className="grid grid-flow-row grid-cols-4 gap-2 print:grid-cols-8 auto-rows-max">
        {/* FILTRE */}
        <div className="flex flex-col grid-cols-3 col-span-4 bg-white border-2 border-collapse border-gray-300 rounded shadow-md print:hidden print:shadow-none print:col-span-8">
          <div className="flex flex-row items-center justify-between w-full text-sm uppercase bg-gray-300 font-ms-bold">
            <span className="py-2 pl-2 print:py-1">Configuració</span>
          </div>

          <div className="grid grid-cols-3 p-2">
            {/* Campo 'Tipus màquina': */}
            <div className="flex flex-row w-full col-span-4 space-x-2">
              <p className="flex items-center text-sm text-grey-darker font-ms-semi">
                Tipus màquina:
              </p>

              <div className="flex flex-row items-center justify-center flex-grow">
                <Select
                  className="flex-grow leading-tight print:hidden text-grey-darker"
                  onChange={(e) => {
                    setMachineCode(e.value)
                  }}
                  placeholder="Seleccionar..."
                  options={machineCodes}
                  getOptionLabel={(o) => o.label}
                  getOptionValue={(o) => o.value}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: '2px solid #d1d5db',
                      boxShadow: state.isFocused ? 'none' : 'none',
                      '&:hover': {
                        border: '2px solid #9ca3af'
                      },
                      margin: '0px',
                      minHeight: 'fit-content',
                      cursor: 'pointer'
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      color: '#333333',
                      backgroundColor: state.isFocused && '#d1d5db',
                      '&:hover': {
                        backgroundColor: '#f3f4f6'
                      }
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: '0px 8px',
                      margin: '0px'
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: '2px 8px'
                    })
                  }}
                  value={machineCodes.find(
                    (option) => option.value === machineCode
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="h-px col-span-4 my-2 bg-gray-200 border-0 print:hidden" />

        {/* DESCRIPCIÓ */}
        <Block
          className="grid-cols-3 col-span-4 print:col-span-8"
          title="Descripció"
          machineCode={machineCode}
        />

        {/* PLEGADOR / CALANDRA: */}
        <Block
          className="grid-cols-3 col-span-3 print:col-span-5"
          title="Plegador / Calandra"
          machineCode={machineCode}
        />

        {/* PINYONS / POLITJA */}
        <Block
          className="print:col-span-3"
          title="Pinyons / Politja"
          machineCode={machineCode}
        />

        {/* GRADUACIÓ DEL PUNT I CONSUM */}
        <Block
          className="grid-cols-3 col-span-3 print:col-span-6"
          title="Graduació del punt i consum"
          machineCode={machineCode}
        />

        {/* ALÇADA I SINCRONISME */}
        <Block
          className="print:col-span-2"
          title="Alçada i sincronisme"
          machineCode={machineCode}
        />

        {/* OBSERVACIONS / NOTES */}
        <Block
          className="grid-cols-3 col-span-4 print:col-span-8"
          title="Observacions / Notes"
          machineCode={machineCode}
        />
      </div>
    </div>
  )
}

ProtoDetail.propTypes = {
  params: PropTypes.any.isRequired
}
