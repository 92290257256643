import { TitleContext } from 'contexts/TitleContext'
import ForecastTrainingForm from 'forms/RRHH/ForecastTrainings/ForecastTrainingForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'wouter'

export default function ForecastTrainingDetail({ params }) {
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const [getApiCallResult, setGetApiCall] = useFetch()
  const [getPostApiCallResult, setPostApiCall] = useFetch()

  const isNew = params.id === 'nou'

  // Sirve para crear un objeto con las variables que necesitaremos, a partir de los datos que nos llegan de la API:
  const [fields, setFields] = useState({
    Id: 0,
    DetailCourse: '',
    Description: '',
    Entity: '',
    Hours: 0,
    Price: 0,
    PrevisionDate: '',
    Priority: 0,
    MonitoringActivated: false,
    TrainingPlan_Id: null,
    CourseYear: null,
    CourseState: 3
  })

  // En caso de que el componente en el que nos encontramos ahora, se use para
  // modificar un registro estableceremos como título de la página 'Modificar':
  const titleEdit = {
    name: `Modificar registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/pla-formacio`
      }
    ],
    deleteForecastTrainingBtn: true,
    forecastTrainingId: params.id
  }

  // En caso de que el componente en el que nos encontramos ahora, se use para
  // crear un nuevo registro estableceremos como título de la página 'Crear':
  const titleCreate = {
    name: `Crear registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/pla-formacio`
      }
    ]
  }

  useEffect(() => {
    // Si el id del registro no es 'nou' significa que el usuario quiere editar un registro ya existente:
    if (params.id !== 'nou') {
      // Llamamos a la API para recoger el registro que se quiere editar:
      const apiCallGet = {
        url: `forecasttraining/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP'
      }
      setGetApiCall(apiCallGet)
    } else {
      // Pondremos el título de crear:
      setTitle(titleCreate)
    }
  }, [])

  // useEffect() para crear un objeto con los datos que nos llegan de la API, para eso se usa el useState() [fields, setFields]:
  useEffect(() => {
    if (getApiCallResult.data) {
      setFields({
        Id: getApiCallResult.data.Id,
        DetailCourse: getApiCallResult.data.DetailCourse,
        Description: getApiCallResult.data.Description,
        Involved: getApiCallResult.data.Involved,
        Entity: getApiCallResult.data.Entity,
        Hours: getApiCallResult.data.Hours,
        Price: getApiCallResult.data.Price,
        PrevisionDate: getApiCallResult?.data?.PrevisionDate?.slice(0, 10),
        Priority: getApiCallResult.data.Priority,
        MonitoringActivated: getApiCallResult.data.MonitoringActivated,
        TrainingPlan_Id: getApiCallResult.data.TrainingPlan_Id,
        CourseYear: getApiCallResult.data.CourseYear,
        CourseState: getApiCallResult.data.CourseState
      })

      // Pondremos el título de editar:
      titleEdit.deleteForecastTrainingBtn =
        !getApiCallResult.data.MonitoringActivated
      setTitle(titleEdit)
    }

    if (getPostApiCallResult.data) {
      setFields({
        Id: getPostApiCallResult.data.Id,
        DetailCourse: getPostApiCallResult.data.DetailCourse,
        Description: getPostApiCallResult.data.Description,
        Involved: getPostApiCallResult.data.Involved,
        Entity: getPostApiCallResult.data.Entity,
        Hours: getPostApiCallResult.data.Hours,
        Price: getPostApiCallResult.data.Price,
        PrevisionDate: getPostApiCallResult.data.PrevisionDate,
        Priority: getPostApiCallResult.data.Priority,
        MonitoringActivated: getPostApiCallResult.data.MonitoringActivated,
        TrainingPlan_Id: getPostApiCallResult.data.TrainingPlan_Id,
        CourseYear: getPostApiCallResult.data.CourseYear,
        CourseState: getPostApiCallResult.data.CourseState
      })

      // Pondremos el título de editar:
      titleEdit.deleteForecastTrainingBtn =
        !getPostApiCallResult.data.MonitoringActivated
      setTitle(titleEdit)
    }
  }, [getApiCallResult.data, getPostApiCallResult.data])

  // Función para guardar el documento:
  const handleSubmit = async (values) => {
    let url = `forecasttraining`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    } else {
      values.CourseYear = new Date().getFullYear()
      values.CourseState = 3
    }

    // Send PUT or POST //
    const apiCall = {
      url,
      method,
      body: values,
      messageKo: 'Error al actualitzar el pla de formació',
      messageOk: 'Pla de formació actualitzat!'
    }
    await setPostApiCall(apiCall)

    if (!getPostApiCallResult.error) setLocation('/pla-formacio')
  }

  // Función para crear un registro de 'Seguiment' a partir del 'Pla de Formació' que queremos activar:
  const activateMonitoring = async (values) => {
    const url = `trainingplan`
    const method = 'post'

    // El 'Pla de Formació' y el 'Seguiment' tienen casi los mismos campos, el 'Seguiment' tiene fecha de inicio,
    // fecha finalización, fecha de verificación y estado. Recogemos los datos del 'Pla de Formació', añadimos
    // los campos adicionales y creamos un objeto 'Seguiment':
    const adaptedValues = {
      DetailCourse: values.DetailCourse,
      Description: values.Description,
      Entity: values.Entity,
      Hours: values.Hours,
      Price: values.Price,
      StartDate: null,
      EndData: null,
      VerificationDate: null,
      ValuationState: 3,
      ForecastTraining_Id: values.Id,
      CourseYear: new Date().getFullYear(),
      CourseState: 1
    }

    // Send PUT or POST //
    const apiCallPost = {
      url,
      method,
      body: adaptedValues,
      messageKo: 'Error al crear el Seguiment',
      messageOk: 'Seguiment creat!'
    }
    await setPostApiCall(apiCallPost)
  }

  return (
    <div className="w-full p-4 bg-white">
      <ForecastTrainingForm
        handleSubmit={handleSubmit}
        activateMonitoring={activateMonitoring}
        fields={fields}
        params={params}
        isNew={isNew}
      />
    </div>
  )
}

ForecastTrainingDetail.propTypes = {
  params: PropTypes.any
}
