import corroEnrollatdorJMB2024 from 'assets/icons/corro-enrotllador-JMB-2024.png'
import corroEnrotllador from 'assets/icons/corro-enrotllador.png'
import pinyonsPlegador from 'assets/icons/pinyons-plegador.png'
import relacioPlegadorJMB2024 from 'assets/icons/relacio-plegador-JMB-2024.png'
import tensioPlegadorJMB2024 from 'assets/icons/tensio-plegador-JMB-2024.png'
import tensioPlegador from 'assets/icons/tensio-plegador.png'
import PropTypes from 'prop-types'

export default function FolderForm({ values, machineCode }) {
  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Campos si 'machineCode' es 'PAL': A. Calandra */}
      {/* Campos si 'machineCode' es 'MCH': A. Calandra, Posició */}
      {/* Campos si 'machineCode' es 'SNT': A. Calandra, Pinyons Plegador */}
      {/* Campos si 'machineCode' es 'MYR': A. Calandra, Pinyons Plegador, Tensió */}
      {/* Campos si 'machineCode' es 'TRT': A. Calandra, Posició, Torque */}
      {/* Campos si 'machineCode' es 'JMB': A. Calandra, ?, Pinyons Plegador, Tensió Plegador */}
      {/* Campos si 'machineCode' es 'PLT': A. Calandra, Delta, Pinyons Plegador, Tensió del Plegador, Corró enrotllador */}

      {/* A. Calandra */}
      {/* Se muestra en todos los tipos de 'machineCode', en 'JMB', 'PLT', 'PAL', 'TRT', 'MCH', 'MYR' y 'SNT': */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        A. Calandra:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Calandra}
        </div>
      </div>

      {/* Delta: */}
      {/* Se muestra si 'machineCode' es 'PLT': */}
      {machineCode === 'PLT' && (
        <>
          <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
            Delta:
          </div>
          <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
            <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
              {values?.Delta}
            </div>
          </div>
        </>
      )}

      {/* Piston JUM y Pinyons Plegador (3, 4) */}
      {/* Se muestra si 'machineCode' es 'JMB': */}
      {/* TODO */}
      {machineCode === 'JMB' && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center w-full pb-1 text-sm text-grey-darker font-ms-semi">
            Relació Plegador:
          </label>

          <div
            className="flex flex-row justify-center items-center py-4 px-6 space-x-6"
            style={{
              backgroundImage: `url(${relacioPlegadorJMB2024})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              width: 'fit-content',
              height: 'fit-content'
            }}
          >
            <div className="py-1 min-h-8 leading-tight border-b border-gray-300 w-20">
              {values?.PistonJUM_v2}
            </div>

            <div className="py-1 min-h-8 leading-tight border-b border-gray-300 w-20">
              {values?.PistonJUM2_v2}
            </div>
          </div>
        </div>
      )}

      {/* Pinyons Plegador (1, 2) */}
      {/* Se muestra si 'machineCode' es 'MYR', 'SNT', 'PLT': */}
      {machineCode !== 'TRT' &&
        machineCode !== 'PAL' &&
        machineCode !== 'MCH' &&
        machineCode !== 'JMB' && (
          <div className="col-span-12">
            <label
              className="flex items-center w-full py-1 text-sm text-grey-darker font-ms-semi"
              htmlFor="FolderingPiston1"
            >
              Pinyons Plegador (1, 2):
            </label>

            <div className="relative">
              <img src={pinyonsPlegador} alt="pinyons plegador" width={150} />

              <div className="absolute py-1 leading-tight border-b border-gray-300 sm:top-0 sm:left-8 sm:w-10 lg:top-3 lg:left-14 lg:w-20">
                {values?.FolderingPiston1}
              </div>

              <div className="absolute py-1 leading-tight border-b border-gray-300 sm:top-6 sm:left-8 sm:w-10 lg:top-14 lg:left-14 lg:w-20">
                {values?.FolderingPiston2}
              </div>
            </div>
          </div>
        )}

      {/* --------------------------------------------------- */}

      {(machineCode === 'PLT' ||
        machineCode === 'JMB' ||
        machineCode === 'MCH' ||
        machineCode === 'TRT' ||
        machineCode === 'MYR') && (
        <hr className="h-px col-span-12 my-4 bg-gray-200 border-0"></hr>
      )}

      {/* --------------------------------------------------- */}

      {/* Tensió Plegador (1, 2) */}
      {/* Se muestra si 'machineCode' es 'PLT': */}
      {machineCode === 'PLT' && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center w-full pb-3 text-sm text-grey-darker font-ms-semi">
            Tensió Plegador (1, 2):
          </label>

          <div className="grid w-full grid-cols-2 gap-x-2">
            <div className="flex flex-col justify-center col-span-1 space-y-2">
              <div className={`w-full flex flex-row`}>
                <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P1:
                </label>

                <div className="flex flex-row items-center justify-center flex-grow">
                  <div className="w-full py-0.5 border-b border-gray-300 text-grey-darker">
                    {values?.P1}
                  </div>
                </div>
              </div>

              <div className={`w-full flex flex-row`}>
                <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P2:
                </label>

                <div className="flex flex-row items-center justify-center flex-grow">
                  <div className="w-full py-0.5 border-b border-gray-300 text-grey-darker">
                    {values?.P2}
                  </div>
                </div>
              </div>

              <div className={`w-full flex flex-row`}>
                <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P3:
                </label>

                <div className="flex flex-row items-center justify-center flex-grow">
                  <div className="w-full py-0.5 border-b border-gray-300 text-grey-darker">
                    {values?.P3}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center col-span-1">
              <div className="relative">
                <img src={tensioPlegador} alt="pinyons plegador" width={100} />

                <div className="absolute top-0 p-0.5 leading-tight bg-white border border-gray-300 rounded lg:left-0 sm:left-8 sm:w-10 lg:w-16">
                  {values?.FolderTension1}
                </div>

                <div className="absolute bottom-0 p-0.5 leading-tight bg-white border border-gray-300 rounded sm:w-10 sm:left-8 lg:left-0 lg:w-16">
                  {values?.FolderTension2}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Tensió Plegador (3, 4) */}
      {/* Se muestra si 'machineCode' es 'JMB': */}
      {machineCode === 'JMB' && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center w-full pb-1 text-sm text-grey-darker font-ms-semi">
            Tensió Plegador:
          </label>

          <div
            className="flex flex-row justify-center items-center py-4 px-6 space-x-6"
            style={{
              backgroundImage: `url(${tensioPlegadorJMB2024})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              width: 'fit-content',
              height: 'fit-content'
            }}
          >
            <div className="py-1 min-h-8 leading-tight border-b border-gray-300 w-20">
              {values?.FolderTension1_v2}
            </div>

            <div className="py-1 min-h-8 leading-tight border-b border-gray-300 w-20">
              {values?.FolderTension2_v2}
            </div>
          </div>
        </div>
      )}

      {/* Posició */}
      {/* Se muestra si 'machineCode' es 'MCH': */}
      {machineCode === 'MCH' && (
        <div className="flex flex-row w-full col-span-12">
          <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Posició:
          </label>

          <div className="flex flex-row items-center justify-center flex-grow">
            <div className="w-full py-0.5 border-b border-gray-300 text-grey-darker">
              {values?.PositionMonarch}
            </div>
          </div>
        </div>
      )}

      {/* Posició y Torque */}
      {/* Se muestra si 'machineCode' es 'TRT': */}
      {machineCode === 'TRT' && (
        <div className="col-span-12">
          <div className="flex flex-row w-full">
            <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Posició:
            </label>

            <div className="flex flex-row items-center justify-center flex-grow">
              <div className="w-full py-0.5 border-b border-gray-300 text-grey-darker">
                {values?.PositionTerrot1}
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full pt-2">
            <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Torque:
            </label>

            <div className="flex flex-row items-center justify-center flex-grow">
              <div className="w-full py-0.5 border-b border-gray-300 text-grey-darker">
                {values?.PositionTerrot2}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Tensió */}
      {/* Se muestra si 'machineCode' es 'MYR': */}
      {machineCode === 'MYR' && (
        <div className="flex flex-row w-full col-span-12">
          <label className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Tensió:
          </label>

          <div className="flex flex-row items-center justify-center flex-grow">
            <div className="w-full py-0.5 border-b border-gray-300 text-grey-darker">
              {values?.TensionMayer}
            </div>
          </div>
        </div>
      )}

      {/* --------------------------------------------------- */}

      {(machineCode === 'PLT' || machineCode === 'JMB') && (
        <hr className="h-px col-span-12 my-4 bg-gray-200 border-0"></hr>
      )}

      {/* --------------------------------------------------- */}

      {/* Corró enrotllador */}
      {/* Se muestra si 'machineCode' es 'PLT': */}
      {machineCode === 'PLT' && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center w-full pb-3 text-sm text-grey-darker font-ms-semi">
            Corró enrotllador:
          </label>

          <div className="relative w-full">
            <img
              src={corroEnrotllador}
              alt="pinyons plegador"
              width={120}
              className="mx-auto sm:w-20 lg:w-28"
            />

            <div className="absolute p-0.5 leading-tight border-b border-gray-300 sm:text-xs lg:text-base sm:top-0 sm:left-0 top-6 sm:w-10 lg:left-10 lg:w-16">
              {values?.RollingRoller1}
            </div>

            <div className="absolute top-0 p-0.5 leading-tight border-b border-gray-300 sm:w-10 sm:text-xs lg:text-base sm:right-0 lg:right-11 lg:w-16">
              {values?.RollingRoller2}
            </div>

            <div className="absolute p-0.5 leading-tight border-b border-gray-300 sm:w-10 sm:text-xs lg:text-base sm:left-0 bottom-6 lg:left-10 lg:w-16">
              {values?.RollingRoller3}
            </div>

            <div className="absolute bottom-0 p-0.5 leading-tight border-b border-gray-300 sm:w-10 sm:text-xs lg:text-base sm:right-0 lg:right-10 lg:w-16">
              {values?.RollingRoller4}
            </div>
          </div>
        </div>
      )}

      {/* Tensió Plegador (3, 4) */}
      {/* Se muestra si 'machineCode' es 'JMB': */}
      {machineCode === 'JMB' && (
        <div className="flex flex-col w-full col-span-12">
          <label className="flex items-center w-full pb-1 text-sm text-grey-darker font-ms-semi">
            Corró Enrotllador:
          </label>

          <div className="flex flex-row space-x-4">
            <div className="flex justify-start items-start pt-6">
              <div className="py-1 mb-8 min-h-8 leading-tight border-b border-gray-300 w-20">
                {values?.RollingRoller1_v2}
              </div>
            </div>
            <div>
              <img
                src={corroEnrollatdorJMB2024}
                alt="pinyons plegador"
                width={71}
              />
            </div>
            <div className="flex justify-end items-end pb-6">
              <div className="py-1 mt-8 min-h-8 leading-tight border-b border-gray-300 w-20">
                {values?.RollingRoller2_v2}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

FolderForm.propTypes = {
  values: PropTypes.object,
  machineCode: PropTypes.string
}
