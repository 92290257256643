import PropTypes from 'prop-types'
import { ImCheckmark, ImCross } from 'react-icons/im'

export default function ThreadTable({ threads, numberGames }) {
  return (
    <table className="relative w-full px-4 border table-fixed border-primary">
      <thead className="w-full text-white bg-primary-dark">
        <tr className="w-full divide-x divide-red-200 divide-dashed">
          <th className="w-1/12 text-sm"></th>
          <th className="w-6/12 text-sm print:w-7/12">Matèria</th>
          <th className="w-1/12 text-sm">GRS</th>
          <th className="w-2/12 text-sm">Nº Cons</th>
          <th className="w-2/12 text-sm py-2">Percentatge</th>
        </tr>
      </thead>

      <tbody className="w-full divide-y divide-red-200 divide-dashed">
        {threads?.length > 0 &&
          threads
            .filter((thread) => thread.HistoryThread)
            .map((thread, index) => (
              <tr
                className="w-full print:divide-x print:divide-dashed print:divide-red-200"
                key={index}
              >
                {/* Campo 'Fil x': */}
                <td className="w-1/12 text-center">Fil {index}</td>

                {/* Campo 'Matèria': */}
                <td className="w-6/12 print:w-7/12 py-1.5">
                  <div className="flex flex-row items-center justify-center">
                    <div className="w-full appearance-none py-0.5 px-2 border-gray-300 text-grey-darker print-background print:py-0">
                      {thread?.HistoryThread?.ThreadMatter}
                    </div>
                  </div>
                </td>

                {/* Campo 'GRS': */}
                <td className="w-2/12 text-center">
                  {thread?.HistoryThread?.GRS ? (
                    <div className="flex items-center justify-center">
                      <p className="flex items-center justify-center w-6 h-6 text-lg font-bold bg-green-200 border-2 border-green-300 rounded-full print:hidden">
                        <ImCheckmark size={12} />
                      </p>
                      <p className="items-center justify-center hidden w-6 h-6 text-lg font-bold rounded-full print:flex">
                        <ImCheckmark size={8} />
                      </p>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <p className="flex items-center justify-center w-6 h-6 text-lg font-bold bg-red-200 border-2 border-red-300 rounded-full print:hidden">
                        <ImCross size={12} />
                      </p>
                      <p className="items-center justify-center hidden w-6 h-6 text-lg font-bold rounded-full print:flex">
                        <ImCross size={8} />
                      </p>
                    </div>
                  )}
                </td>

                {/* Campo 'Nº Cons': */}
                <td className="w-2/12 text-center">
                  <div className="w-full appearance-none py-0.5 px-2 border-gray-300 text-grey-darker print-background print:py-0">
                    {thread?.HistoryThread?.NCones}
                  </div>
                </td>

                {/* Campo 'Percentatge': */}
                <td className="w-2/12 text-center">
                  <div className="w-full appearance-none py-0.5 px-2 border-gray-300 text-grey-darker print-background print:py-0">
                    {thread?.HistoryThread?.NCones !== 0 &&
                    thread?.HistoryThread?.NCones &&
                    numberGames !== 0 &&
                    numberGames
                      ? ((thread?.HistoryThread?.NCones / numberGames) * 100)
                          .toFixed()
                          .toString() + ' %'
                      : 0 + ' %'}
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  )
}

ThreadTable.propTypes = {
  threads: PropTypes.any,
  numberGames: PropTypes.number
}
