export default function ObservationsForm() {
  const inputStyles =
    'w-full py-0.5 print:py-0 px-2 bg-white border-2 rounded border-gray-300 appearance-none outline-none print:rounded-none print:border print:border-l-0 print:border-t-0 print:border-r-0'

  return (
    <div className="grid grid-cols-2 gap-2 p-2">
      {/* Campo 'Observació': */}
      <div className="items-center block col-span-1 print:bg-white">
        <p className="block mb-1 text-sm text-grey-darker font-ms-semi col-start-full">
          Observació:
        </p>

        <input className={`${inputStyles} h-24 resize-none`} disabled />
      </div>

      {/* Campo 'NOTA IMPORTANT': */}
      <div className="items-center block col-span-1 print:bg-white">
        <p className="block mb-1 text-sm text-grey-darker font-ms-semi col-start-full">
          NOTA IMPORTANT:
        </p>

        <input
          className={`${inputStyles} h-24 resize-none`}
          style={{
            backgroundColor: 'rgba(233, 255, 0, 0.5)',
            color: '#333333',
            borderColor: '#D3E600'
          }}
          disabled
        />
      </div>

      {/* Campo 'Data edició': */}
      <div className="grid grid-cols-12 col-span-1 print:bg-white">
        <p className="self-center block col-span-3 col-start-2 text-sm text-grey-darker font-ms-semi col-start-full">
          Data edició:
        </p>

        <input className={`${inputStyles} col-span-8`} disabled />
      </div>

      {/* Campo 'Data edició': */}
      <div className="grid grid-cols-12 col-span-1 print:bg-white">
        <p className="self-center block col-span-3 col-start-2 text-sm text-grey-darker font-ms-semi col-start-full">
          Realitzat per:
        </p>

        <input className={`${inputStyles} col-span-8`} disabled />
      </div>
    </div>
  )
}

ObservationsForm.propTypes = {}
