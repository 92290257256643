import alcades from 'assets/icons/alcades.png'
import PropTypes from 'prop-types'

export default function HeightForm({ values }) {
  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Sincronit. */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Sincronit.:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Synchronization}
        </div>
      </div>

      {/* Formació */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Formació:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Formation}
        </div>
      </div>

      {/* Campo 'Alçada plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label
          className="flex items-center col-span-4 py-1 text-sm text-grey-darker font-ms-semi"
          htmlFor="DishHeight1"
        >
          Alçada plat:
        </label>

        <div className="relative col-span-3">
          <img src={alcades} alt="pinyons plegador" className="w-36" />

          <div className="absolute left-0 px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 sm:w-2/5 lg:w-16">
            {values?.DishHeight1}
          </div>

          <div className="absolute px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 left-20 sm:w-2/5 lg:w-16">
            {values?.DishHeight2}
          </div>
        </div>
      </div>

      {/* Campo 'Desplaçament plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label
          className="flex items-center col-span-4 pb-1 text-sm text-grey-darker font-ms-semi"
          htmlFor="DishDisplacement1"
        >
          Desplaçament plat:
        </label>

        <div className="relative col-span-3">
          <img src={alcades} alt="Pinyons Plegador" className="w-36" />

          <div className="absolute left-0 px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 sm:w-2/5 lg:w-16">
            {values?.DishDisplacement1}
          </div>

          <div className="absolute px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 left-20 sm:w-2/5 lg:w-16">
            {values?.DishDisplacement2}
          </div>
        </div>
      </div>
    </div>
  )
}

HeightForm.propTypes = {
  values: PropTypes.object
}
