import PropTypes from 'prop-types'
import { ImCheckmark, ImCross } from 'react-icons/im'

export default function ThreadTable({
  threads,
  numberGames,
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  console.log(
    'HistoryThreads',
    threads?.filter((thread) => thread.HistoryThread)
  )
  console.log(
    'HasBeenRemoved',
    threads?.filter((thread) => !thread.HasBeenRemoved)
  )
  console.log(
    'HasBeenRemoved',
    JSON.stringify(threads?.filter((thread) => thread.HistoryThread)) !==
      JSON.stringify(threads?.filter((thread) => !thread.HasBeenRemoved))
  )

  return (
    <table
      className={`relative w-full px-4 border-2 table-fixed print:w-11/12 print:border ${
        JSON.stringify(threads?.filter((thread) => thread.HistoryThread)) !==
        JSON.stringify(threads?.filter((thread) => !thread.HasBeenRemoved))
          ? side === 'left'
            ? 'border-red-300'
            : isAnswered
            ? 'border-emerald-300'
            : 'border-amber-300'
          : 'border-gray-300'
      }`}
    >
      <thead
        className={`${
          JSON.stringify(threads?.filter((thread) => thread.HistoryThread)) !==
          JSON.stringify(threads?.filter((thread) => !thread.HasBeenRemoved))
            ? side === 'left'
              ? 'bg-red-100 border-red-300'
              : isAnswered
              ? 'bg-emerald-100 border-emerald-300'
              : 'bg-amber-100 border-amber-300'
            : 'border-gray-300 bg-gray-100'
        } focus-within: w-full`}
      >
        <tr
          className={`w-full divide-x divide-dashed ${
            JSON.stringify(
              threads?.filter((thread) => thread.HistoryThread)
            ) !==
            JSON.stringify(threads?.filter((thread) => !thread.HasBeenRemoved))
              ? side === 'left'
                ? 'divide-red-200'
                : isAnswered
                ? 'divide-emerald-200'
                : 'divide-amber-200'
              : 'divide-gray-200'
          } `}
        >
          <th className="w-1/12 text-sm"></th>
          <th className="w-6/12 text-sm print:w-7/12">Matèria</th>
          <th className="w-1/12 text-sm">GRS</th>
          <th className="w-2/12 text-sm">Nº Cons</th>
          <th className="w-2/12 text-sm">Percentatge</th>
        </tr>
      </thead>

      <tbody
        className={`${
          JSON.stringify(threads?.filter((thread) => thread.HistoryThread)) !==
          JSON.stringify(threads?.filter((thread) => !thread.HasBeenRemoved))
            ? side === 'left'
              ? 'divide-red-200'
              : isAnswered
              ? 'divide-emerald-200'
              : 'divide-amber-200'
            : 'divide-gray-200'
        } w-full divide-y divide-dashed`}
      >
        {threads?.length > 0 &&
          threads
            ?.filter((thread) =>
              side === 'left' ? thread.HistoryThread : !thread.HasBeenRemoved
            )
            .map((thread, index) => (
              <tr
                className={`w-full print:divide-x print:divide-dashed print:divide-red-200`}
                key={index}
              >
                {/* Campo 'Fil x': */}
                <td className="w-1/12 text-center">Fil {index}</td>

                {/* Campo 'Matèria': */}
                <td className="w-6/12 print:w-7/12 py-1.5">
                  <div className="flex flex-row items-center justify-center">
                    <div className="w-full appearance-none py-0.5 px-2 border-gray-300 text-grey-darker print-background print:py-0">
                      {side === 'left'
                        ? thread?.HistoryThread?.ThreadMatter
                        : thread?.ThreadMatter}
                    </div>
                  </div>
                </td>

                {/* Campo 'GRS': */}
                <td className="w-2/12 text-center">
                  {side === 'left' ? (
                    thread?.HistoryThread?.GRS ? (
                      <div className="flex items-center justify-center">
                        <p className="flex items-center justify-center w-6 h-6 text-lg font-bold">
                          <ImCheckmark size={12} />
                        </p>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        <p className="flex items-center justify-center w-6 h-6 text-lg font-bold">
                          <ImCross size={12} />
                        </p>
                      </div>
                    )
                  ) : thread?.GRS ? (
                    <div className="flex items-center justify-center">
                      <p className="flex items-center justify-center w-6 h-6 text-lg font-bold">
                        <ImCheckmark size={12} />
                      </p>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <p className="flex items-center justify-center w-6 h-6 text-lg font-bold">
                        <ImCross size={12} />
                      </p>
                    </div>
                  )}
                </td>

                {/* Campo 'Nº Cons': */}
                <td className="w-2/12 text-center">
                  <div className="w-full appearance-none py-0.5 px-2 border-gray-300 text-grey-darker print-background print:py-0">
                    {side === 'left'
                      ? thread?.HistoryThread?.NCones
                      : thread?.NCones}
                  </div>
                </td>

                {/* Campo 'Percentatge': */}
                <td className="w-2/12 text-center">
                  <div className="w-full appearance-none py-0.5 px-2 border-gray-300 text-grey-darker print-background print:py-0">
                    {side === 'left'
                      ? thread?.HistoryThread?.NCones !== 0 &&
                        thread?.HistoryThread?.NCones &&
                        numberGames !== 0 &&
                        numberGames
                        ? ((thread?.HistoryThread?.NCones / numberGames) * 100)
                            .toFixed()
                            .toString() + ' %'
                        : 0 + ' %'
                      : thread?.NCones !== 0 &&
                        thread?.NCones &&
                        numberGames !== 0 &&
                        numberGames
                      ? ((thread?.NCones / numberGames) * 100)
                          .toFixed()
                          .toString() + ' %'
                      : 0 + ' %'}
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  )
}

ThreadTable.propTypes = {
  threads: PropTypes.any,
  numberGames: PropTypes.number,
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
