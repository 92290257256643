import PropTypes from 'prop-types'
import { useState, useRef, useEffect, useContext } from 'react'
import useFetch from 'hooks/useFetch'
import Block from 'components/FTTeixiduria/Details/Block/Block'
import { ModalContext } from 'contexts/ModalContext'
import DeleteModal from 'components/DeleteModal'
import { UserContext } from 'contexts/UserContext'
import { TitleContext } from 'contexts/TitleContext'
import { FaTrash } from 'react-icons/fa'
import { MdEdit, MdOutlineUploadFile } from 'react-icons/md'
import { CgSpinner } from 'react-icons/cg'
import logoGRS from 'assets/img/logo-GRS.png'
import { Link, useLocation } from 'wouter'
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineHistory,
  AiOutlinePrinter
} from 'react-icons/ai'

export default function FTTeixiduriaDetail({ params }) {
  // Referéncias:
  const hiddenFileInput = useRef(null)

  // Context:
  const { setTitle } = useContext(TitleContext)
  const { user } = useContext(UserContext)
  const [, setLocation] = useLocation()
  const { handleModal } = useContext(ModalContext)

  // useFetch()
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallGetUser, setApiCallGetUser] = useFetch()
  const [getApiCallGetUsers, setApiCallGetUsers] = useFetch()
  const [getApiCallGetRequest, setApiCallGetRequest] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const [getApiCallPostDocument, setApiCallPostDocument] = useFetch()
  const [getApiCallDeleteDocument, setApiCallDeleteDocument] = useFetch()

  // Estados:
  const [documentsArray, setDocumentsArray] = useState([])
  const [canRequest, setCanRequest] = useState(false)
  const [canCopy, setCanCopy] = useState(false)
  const [canCheckHistory, setCanCheckHistory] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [users, setUsers] = useState(null)
  const [getAllowEdit, setAllowEdit] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  // Variables:
  const readOnly = user.Role.Id === 2
  const isNew = !!(params.id === 'nou')

  const now = new Date()
  const day = now.getDate().toString().padStart(2, '0')
  const month = (now.getMonth() + 1).toString().padStart(2, '0')
  const year = now.getFullYear()
  const hours = now.getHours().toString().padStart(2, '0')
  const minutes = now.getMinutes().toString().padStart(2, '0')
  const formattedDateTime = `${day}/${month}/${year} - ${hours}:${minutes}h`

  const [getFieldsState, setFieldsState] = useState({
    Id: 0,

    // Campos apartado 'Descripció':
    SemiFinished: '',
    SemiFinishedLong: '',
    SecurityStep: false,
    SecurityStepDescription: '',
    Is3D: false,
    Press3D: '',
    EncryptedCode: '',
    TiedDescription: '',
    Repetitions: '',
    RepetitionsV2: '',
    RepetitionsTolerance: '1',
    RepetitionsToleranceV2: '1',
    Columns: '',
    ColumnsV2: '',
    ColumnsTolerance: '1',
    ColumnsToleranceV2: '1',
    Weight: '',
    WeightV2: '',
    WeightTolerance: '3',
    WeightToleranceV2: '3',
    Width: '',
    WidthV2: '',
    WidthTolerance: '3',
    WidthToleranceV2: '3',
    Thickness: '',
    ThicknessV2: '',
    ThicknessTolerance: '0.02',
    ThicknessToleranceV2: '0.02',
    RDTNominal: '',
    RDTNominalV2: '',
    RDTNominalTolerance: '',
    RDTNominalToleranceV2: '',
    Machine: '',
    MachineCode: '',
    FoldType: '',
    GG: '',
    O: '',
    NGame: '',
    Needle: '',
    Rpm: 0,
    RpmMin: 0,
    TurnsPerKg: '',
    TurnsPerKgTolerance: '2',
    TurnsPerPart: '',
    TurnsPerPartByOperator: '',
    TurnsPerPartByOperatorTolerance: '100',
    KgH_100: '',
    KgH_80: '',
    KgD_100: '',
    KgD_80: '',
    KgPart: '',
    KgPartTolerance: '2',

    // Campos apartado 'Plegador / Calandra':
    Synchronization: '',
    Formation: '',
    DishHeight1: '',
    DishHeight2: '',
    DishDisplacement1: '',
    DishDisplacement2: '',

    // Campos apartado 'Pinyols / Politja':
    Piston1: '',
    Piston2: '',
    Piston3: '',
    Piston4: '',
    Piston5: '',
    Piston6: '',
    Piston7: '',
    Piston8: '',
    Piston9: '',
    Piston10: '',
    JUM1: '',
    JUM2: '',
    JUM3: '',
    JUM4: '',
    PistonTolerance1: '5',
    PistonTolerance2: '5',
    PistonTolerance3: '5',
    PistonTolerance4: '5',

    // Campos apartado 'Graduació del punt i consum':
    CylinderHeight1: '',
    CylinderHeight2: '',
    PositionTurntables: '',
    PositionGuiafils: '',

    // Campos apartado 'Alçada i sincronisme':
    Calandra: '',
    Delta: '',
    FolderingPiston1: '',
    FolderingPiston2: '',
    FolderingPiston3: '',
    FolderingPiston4: '',
    FolderTension1: '',
    FolderTension1_v2: '',
    FolderTension2: '',
    FolderTension2_v2: '',
    FolderTension3: '',
    FolderTension4: '',
    P1: '',
    P2: '',
    P3: '',
    PistonJUM: '',
    PistonJUM_v2: '',
    PistonJUM2: '',
    PistonJUM2_v2: '',
    RollingRoller1: '',
    RollingRoller1_v2: '',
    RollingRoller2: '',
    RollingRoller2_v2: '',
    RollingRoller3: '',
    RollingRoller4: '',
    TensionMayer: '',
    PositionMonarch: '',
    PositionTerrot1: '',
    PositionTerrot2: '',

    // Campos apartado 'Observacions / Notes':
    DestacableText: '',
    Obseravtion: '',
    Edition: '',
    EditionDate: '',
    UserDone: '',
    UserApproved: '',
    PassTensiometer: false,
    TSWeavingThreads: [],
    TSWeavingDocuments: [],
    TSWeavingMachineGroups: []
  })

  // #########################################################################
  // TECHNICALSHEET (FITXA TÈCNICA)
  // #########################################################################
  // CRUD: GET (FT Teixiduria + Usuarios)
  useEffect(() => {
    const title = {
      name: `Fitxa Tècnica`,
      buttons: [
        {
          name: 'Tornar',
          link: '/ft-teixiduria'
        }
      ]
    }
    setTitle(title)

    const apiCallUsers = {
      url: `user/active`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }
    setApiCallGetUsers(apiCallUsers)
  }, [])

  // Get users api call response
  useEffect(() => {
    if (getApiCallGetUsers.data && !users) {
      setUsers(getApiCallGetUsers.data)

      const apiCallGetUser = {
        url: `user/${user.Id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGetUser(apiCallGetUser)
    }
  }, [getApiCallGetUsers.data])

  // Get user api call response
  useEffect(() => {
    if (getApiCallGetUser.data && !currentUser) {
      setCurrentUser(getApiCallGetUser.data)

      setAllowEdit(
        user.Role.Id === 1 &&
          (user.Id === 818 ||
            user.Id === 7 ||
            user.Id === 4 ||
            user.Id === 11 ||
            user.Id === 8)
      )

      const apiCallGet = {
        url: `tsweaving/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGet(apiCallGet)
    }
  }, [getApiCallGetUser.data])

  // Get technicalsheet api call response
  useEffect(() => {
    if (getApiCallGet.data && currentUser && getFieldsState.Id === 0) {
      const id = getApiCallGet.data?.Id
      console.log('🚀 ~ useEffect ~ getApiCallGet.data:', getApiCallGet.data)

      const apiCallGetRequest = {
        url: `tsweaving/request/last/${id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGetRequest(apiCallGetRequest)
    }
  }, [getApiCallGet.data, currentUser])

  // Get technicalsheet request api call response
  useEffect(() => {
    if (getApiCallGet.data && currentUser && getFieldsState.Id === 0) {
      const apiData = JSON.parse(JSON.stringify(getApiCallGet.data))
      console.log('🚀 ~ useEffect ~ apiData:', apiData)
      setFieldsState({
        Id: getApiCallGet.data?.Id,
        SemiFinished: getApiCallGet.data?.SemiFinished,
        SemiFinishedLong: getApiCallGet.data?.SemiFinishedLong,
        SecurityStep: getApiCallGet.data?.SecurityStep,
        SecurityStepDescription: getApiCallGet.data?.SecurityStepDescription,
        Is3D: getApiCallGet.data?.Is3D,
        Press3D: getApiCallGet.data?.Press3D,
        EncryptedCode: getApiCallGet.data?.EncryptedCode,
        TiedDescription: getApiCallGet.data?.TiedDescription,
        Repetitions: Number(
          getApiCallGet.data?.Repetitions?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.Repetitions % 1 === 0 ? 0 : 2),
        RepetitionsV2: Number(
          getApiCallGet.data?.RepetitionsV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.RepetitionsV2 % 1 === 0 ? 0 : 2),
        RepetitionsTolerance: Number(
          getApiCallGet.data?.RepetitionsTolerance?.replace(/,/g, '.') ?? '1'
        ).toFixed(getApiCallGet.data?.RepetitionsTolerance % 1 === 0 ? 0 : 2),
        RepetitionsToleranceV2: Number(
          getApiCallGet.data?.RepetitionsToleranceV2?.replace(/,/g, '.') ?? '1'
        ).toFixed(getApiCallGet.data?.RepetitionsToleranceV2 % 1 === 0 ? 0 : 2),
        Columns: Number(
          getApiCallGet.data?.Columns?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.Columns % 1 === 0 ? 0 : 2),
        ColumnsV2: Number(
          getApiCallGet.data?.ColumnsV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.ColumnsV2 % 1 === 0 ? 0 : 2),
        ColumnsTolerance: Number(
          getApiCallGet.data?.ColumnsTolerance?.replace(/,/g, '.') ?? '1'
        ).toFixed(getApiCallGet.data?.ColumnsTolerance % 1 === 0 ? 0 : 2),
        ColumnsToleranceV2: Number(
          getApiCallGet.data?.ColumnsToleranceV2?.replace(/,/g, '.') ?? '1'
        ).toFixed(getApiCallGet.data?.ColumnsToleranceV2 % 1 === 0 ? 0 : 2),
        Weight: Number(
          getApiCallGet.data?.Weight?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.Weight % 1 === 0 ? 0 : 2),
        WeightV2: Number(
          getApiCallGet.data?.WeightV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.WeightV2 % 1 === 0 ? 0 : 2),
        WeightTolerance: Number(
          getApiCallGet.data?.WeightTolerance?.replace(/,/g, '.') ?? '3'
        ).toFixed(getApiCallGet.data?.WeightTolerance % 1 === 0 ? 0 : 2),
        WeightToleranceV2: Number(
          getApiCallGet.data?.WeightToleranceV2?.replace(/,/g, '.') ?? '3'
        ).toFixed(getApiCallGet.data?.WeightToleranceV2 % 1 === 0 ? 0 : 2),
        Width: Number(
          getApiCallGet.data?.Width?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.Width % 1 === 0 ? 0 : 2),
        WidthV2: Number(
          getApiCallGet.data?.WidthV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.WidthV2 % 1 === 0 ? 0 : 2),
        WidthTolerance: Number(
          getApiCallGet.data?.WidthTolerance?.replace(/,/g, '.') ?? '3'
        ).toFixed(getApiCallGet.data?.WidthTolerance % 1 === 0 ? 0 : 2),
        WidthToleranceV2: Number(
          getApiCallGet.data?.WidthToleranceV2?.replace(/,/g, '.') ?? '3'
        ).toFixed(getApiCallGet.data?.WidthToleranceV2 % 1 === 0 ? 0 : 2),
        Thickness: Number(
          getApiCallGet.data?.Thickness?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.Thickness % 1 === 0 ? 0 : 2),
        ThicknessV2: Number(
          getApiCallGet.data?.ThicknessV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.ThicknessV2 % 1 === 0 ? 0 : 2),
        ThicknessTolerance: Number(
          getApiCallGet.data?.ThicknessTolerance?.replace(/,/g, '.') ?? '0.02'
        ).toFixed(getApiCallGet.data?.ThicknessTolerance % 1 === 0 ? 0 : 2),
        ThicknessToleranceV2: Number(
          getApiCallGet.data?.ThicknessToleranceV2?.replace(/,/g, '.') ?? '0.02'
        ).toFixed(getApiCallGet.data?.ThicknessToleranceV2 % 1 === 0 ? 0 : 2),
        RDTNominal: Number(
          getApiCallGet.data?.RDTNominal?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.RDTNominal % 1 === 0 ? 0 : 2),
        RDTNominalV2: Number(
          getApiCallGet.data?.RDTNominalV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.RDTNominalV2 % 1 === 0 ? 0 : 2),
        RDTNominalTolerance: Number(
          getApiCallGet.data?.RDTNominalTolerance?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.RDTNominalTolerance % 1 === 0 ? 0 : 2),
        RDTNominalToleranceV2: Number(
          getApiCallGet.data?.RDTNominalToleranceV2?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.RDTNominalToleranceV2 % 1 === 0 ? 0 : 2),
        LaboratoryComment: getApiCallGet.data?.LaboratoryComment,
        Machine: getApiCallGet.data?.Machine,
        MachineCode: getApiCallGet.data?.MachineCode,
        FoldType: getApiCallGet.data?.FoldType,
        GG: getApiCallGet.data?.GG,
        O: getApiCallGet.data?.O,
        NGame: getApiCallGet.data?.NGame,
        Needle: getApiCallGet.data?.Needle,
        Rpm: Number(
          getApiCallGet.data?.Rpm?.toString().replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.Rpm % 1 === 0 ? 0 : 1),
        RpmMin: Number(
          getApiCallGet.data?.RpmMin?.toString().replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.RpmMin % 1 === 0 ? 0 : 1),
        TurnsPerKg: Math.round(
          Number(getApiCallGet.data?.TurnsPerKg?.replace(/,/g, '.') ?? 0)
        ),
        TurnsPerKgTolerance: Math.round(
          Number(getApiCallGet.data?.TurnsPerKgTolerance ?? '2')
        ),
        TurnsPerPart: Math.round(
          Number(getApiCallGet.data?.TurnsPerPart?.replace(/,/g, '.') ?? 0)
        ),
        TurnsPerPartByOperator: Math.round(
          Number(
            getApiCallGet.data?.TurnsPerPartByOperator?.replace(/,/g, '.') ?? 0
          )
        ),
        TurnsPerPartByOperatorTolerance: Math.round(
          Number(getApiCallGet.data?.TurnsPerPartByOperatorTolerance ?? '100')
        ),
        KgH_100: getApiCallGet.data?.KgH_100,
        KgH_80: getApiCallGet.data?.KgH_80,
        KgD_100: getApiCallGet.data?.KgD_100,
        KgD_80: getApiCallGet.data?.KgD_80,
        KgPart: Number(
          getApiCallGet.data?.KgPart?.replace(/,/g, '.') ?? 0
        ).toFixed(getApiCallGet.data?.KgPart % 1 === 0 ? 0 : 1),
        KgPartTolerance: Number(
          getApiCallGet.data?.KgPartTolerance?.replace(/,/g, '.') ?? '2'
        ).toFixed(getApiCallGet.data?.KgPartTolerance % 1 === 0 ? 0 : 1),
        Synchronization: getApiCallGet.data?.Synchronization,
        Formation: getApiCallGet.data?.Formation,
        DishHeight1: getApiCallGet.data?.DishHeight1,
        DishHeight2: getApiCallGet.data?.DishHeight2,
        DishDisplacement1: getApiCallGet.data?.DishDisplacement1,
        DishDisplacement2: getApiCallGet.data?.DishDisplacement2,
        Piston1: getApiCallGet.data?.Piston1,
        Piston2: getApiCallGet.data?.Piston2,
        Piston3: getApiCallGet.data?.Piston3,
        Piston4: getApiCallGet.data?.Piston4,
        Piston5: getApiCallGet.data?.Piston5,
        Piston6: getApiCallGet.data?.Piston6,
        Piston7: getApiCallGet.data?.Piston7,
        Piston8: getApiCallGet.data?.Piston8,
        Piston9: getApiCallGet.data?.Piston9,
        Piston10: getApiCallGet.data?.Piston10,
        JUM1: getApiCallGet.data?.JUM1,
        JUM2: getApiCallGet.data?.JUM2,
        JUM3: getApiCallGet.data?.JUM3,
        JUM4: getApiCallGet.data?.JUM4,
        PistonTolerance1: getApiCallGet.data?.PistonTolerance1 ?? '5',
        PistonTolerance2: getApiCallGet.data?.PistonTolerance2 ?? '5',
        PistonTolerance3: getApiCallGet.data?.PistonTolerance3 ?? '5',
        PistonTolerance4: getApiCallGet.data?.PistonTolerance4 ?? '5',
        CylinderHeight1: getApiCallGet.data?.CylinderHeight1,
        CylinderHeight2: getApiCallGet.data?.CylinderHeight2,
        PositionTurntables: getApiCallGet.data?.PositionTurntables,
        PositionGuiafils: getApiCallGet.data?.PositionGuiafils,
        Calandra: getApiCallGet.data?.Calandra,
        Delta: getApiCallGet.data?.Delta,
        FolderingPiston1: getApiCallGet.data?.FolderingPiston1,
        FolderingPiston2: getApiCallGet.data?.FolderingPiston2,
        FolderingPiston3: getApiCallGet.data?.FolderingPiston3,
        FolderingPiston4: getApiCallGet.data?.FolderingPiston4,
        FolderTension1: getApiCallGet.data?.FolderTension1,
        FolderTension2: getApiCallGet.data?.FolderTension2,
        FolderTension3: getApiCallGet.data?.FolderTension3,
        FolderTension4: getApiCallGet.data?.FolderTension4,
        P1: getApiCallGet.data?.P1,
        P2: getApiCallGet.data?.P2,
        P3: getApiCallGet.data?.P3,
        PistonJUM: getApiCallGet.data?.PistonJUM,
        PistonJUM2: getApiCallGet.data?.PistonJUM2,
        RollingRoller1: getApiCallGet.data?.RollingRoller1,
        RollingRoller2: getApiCallGet.data?.RollingRoller2,
        RollingRoller3: getApiCallGet.data?.RollingRoller3,
        RollingRoller4: getApiCallGet.data?.RollingRoller4,
        TensionMayer: getApiCallGet.data?.TensionMayer,
        PositionMonarch: getApiCallGet.data?.PositionMonarch,
        PositionTerrot1: getApiCallGet.data?.PositionTerrot1,
        PositionTerrot2: getApiCallGet.data?.PositionTerrot2,
        DestacableText: getApiCallGet.data?.DestacableText,
        Obseravtion: getApiCallGet.data?.Obseravtion,
        Edition: getApiCallGet.data?.Edition,
        EditionDate: getApiCallGet.data?.EditionDate,
        UserDone: getApiCallGet.data?.UserDone,
        UserApproved: getApiCallGet.data?.UserApproved,
        PassTensiometer: getApiCallGet.data?.PassTensiometer,
        TSWeavingThreads: getApiCallGet.data?.TSWeavingThreads,
        TSWeavingDocuments: getApiCallGet.data?.TSWeavingDocuments,
        TSWeavingMachineGroups: getApiCallGet.data?.TSWeavingMachineGroups
      })

      // Permissions
      let allow = false
      const userId = user.Id
      const userRole = user.Role.Id

      if (
        userRole === 1 &&
        (userId === 818 ||
          userId === 7 ||
          userId === 4 ||
          userId === 11 ||
          userId === 8)
      ) {
        allow = true
      } else {
        allow = false
      }

      setCanRequest(allow)

      // -----------------------------------

      let allowCheckHistory = false

      if (
        userRole === 1 &&
        (userId === 818 ||
          userId === 7 ||
          userId === 4 ||
          userId === 11 ||
          userId === 8)
      ) {
        allowCheckHistory = true
      } else {
        allowCheckHistory = false
      }

      setCanCheckHistory(allowCheckHistory)

      // -----------------------------------

      let allowCopy = false

      if (
        userRole === 1 &&
        (userId === 818 ||
          userId === 7 ||
          userId === 4 ||
          userId === 11 ||
          userId === 8)
      ) {
        allowCopy = true
      } else {
        allowCopy = false
      }

      setCanCopy(allowCopy)
      // Permissions
    }
  }, [getApiCallGetRequest.data])

  // #########################################################################
  // DOCUMENTS
  // #########################################################################
  // CRUD: POST (FT Teixiduria Documento)
  const uploadDocument = (e) => {
    const files = e.currentTarget.files
    //* Handle multiple files

    const formData = new FormData()
    if (files.length > 1) {
      Array.from(files).forEach((file) => {
        formData.append('document', file)
      })
    } else {
      formData.append('document', files[0])
    }

    const tsweavingParams = {
      url: `tsweaving/${getApiCallPost.data?.Id}/documents`,
      method: 'POST',
      formData: formData,
      messageOk: 'Documento subido!',
      messageKo: 'Error al subir documentos'
    }

    setApiCallPostDocument(tsweavingParams)

    setIsUploading(true)
  }

  useEffect(() => {
    if (getApiCallPostDocument.data && isUploading === true) {
      const newDocuments = JSON.parse(getApiCallPostDocument.data)
      setDocumentsArray(newDocuments)
      setIsUploading(false)
    }
  }, [getApiCallPostDocument])

  // CRUD: DELETE (FT Teixiduria Documento)
  const deleteDocument = (id) => {
    const deleteDocumentParams = {
      url: `tsweaving/${params.id}/document/${id}`,
      method: 'DELETE',
      messageOk: 'Documento eliminado!',
      messageKo: 'Error al eliminar documentos'
    }
    setApiCallDeleteDocument(deleteDocumentParams)
    findDeletedDocument(id)
    handleModal('hide')
  }

  const findDeletedDocument = (id) => {
    const newDocumentsArray = documentsArray.filter(
      (document) => document.Id !== id
    )
    setDocumentsArray(newDocumentsArray)
  }

  // ############################################################

  const renderTableData = () => {
    if (documentsArray?.length > 0)
      return documentsArray.map((document) => {
        return (
          <td key={document.Id} className="border-b border-gray-300">
            <button className="block w-auto p-1 m-2 text-sm rounded cursor-pointer focus:shadow-outline hover:bg-gray-300">
              <a
                href={`${
                  process.env.REACT_APP_BASE_UPLOADS_URL
                }/uploads/tsweaving/${
                  getFieldsState.Id
                }/documents/${encodeURIComponent(document.Name)}`}
                target="_blank"
                rel="noreferrer"
              >
                {document.Name}
              </a>
            </button>
            <button
              disabled={readOnly}
              className={`m-2 mx-auto p-2 rounded-lg w-auto block font-medium text-sm     focus:shadow-outline hover:bg-gray-300 ${
                readOnly && 'bg-gray-300'
              } ${readOnly ? 'cursor-default' : 'cursor-pointer'}`}
              onClick={() =>
                handleModal(
                  <DeleteModal
                    deleteUserOrFT={deleteDocument}
                    element="fitxer"
                    id={document.Id}
                    closeModal={() => handleModal('hide')}
                  />
                )
              }
            >
              <FaTrash size={17} />
            </button>
          </td>
        )
      })
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  return users !== null && getFieldsState.SemiFinished ? (
    <>
      {/* Options bar */}
      <div
        className="sticky top-0 z-50 flex items-end justify-between w-full col-span-2 px-4 py-3 text-sm uppercase border-b-2 print:hidden border-charcoal"
        style={{ backdropFilter: 'blur(25px)' }}
      >
        {/* AMAGADA / VISIBLE */}
        <div className="inline-flex items-start space-x-2">
          {/* {canRequest && (
            <div className="inline-flex items-center justify-between h-10 px-5 py-2 leading-normal text-center text-gray-600 uppercase transition duration-300 bg-white border-2 border-blue-200 rounded w-fit text-md font-ms-bold">
              {getFieldsState.IsOperatorHidden ? (
                <AiOutlineEyeInvisible className="xl:mr-2" size={25} />
              ) : (
                <AiOutlineEye className="xl:mr-2" size={25} />
              )}
              {getFieldsState.IsOperatorHidden
                ? 'Amagada per Operaris'
                : 'Visible per Operaris'}
            </div>
          )} */}
        </div>

        {/* COPIAR / IMPRIMIR / HISTORIAL / SOL·LICITAR */}
        <div className="inline-flex items-start space-x-2">
          {/* {canCopy && (
            <button
              type="button"
              className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center text-gray-600 uppercase transition duration-300 bg-white border-2 border-gray-600 rounded d-flex text-md font-ms-bold hover:bg-gray-600 hover:text-white print:hidden"
              onClick={() =>
                handleModal(
                  <CloneFTModal
                    cloneFT={cloneFT}
                    closeModal={() => handleModal('hide')}
                    options={options}
                    currentArticleCode={getFieldsState.Article}
                    currentArticleDescription={
                      getFieldsState.ArticleDescription
                    }
                  />
                )
              }
              disabled
            >
              <MdContentCopy className="xl:mr-2" size={22} />
              <span className="hidden xl:inline-flex">Copiar</span>
            </button>
          )} */}

          {/* {getAllowEdit ? (
            <button
              type="button"
              className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center text-gray-600 uppercase transition duration-300 bg-white border-2 border-gray-600 rounded d-flex text-md font-ms-bold hover:bg-gray-600 hover:text-white print:hidden"
              onClick={() => window.print()}
            >
              <AiOutlinePrinter className="xl:mr-2" size={25} />
              <span className="hidden xl:inline-flex">Imprimir</span>
            </button>
          ) : (
            <button
              type="button"
              className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center text-gray-600 uppercase transition duration-300 bg-white border-2 border-gray-600 rounded d-flex text-md font-ms-bold print:hidden"
              onClick={() => window.print()}
              disabled
            >
              <AiOutlinePrinter className="xl:mr-2" size={25} />
              <span className="hidden xl:inline-flex">Imprimir</span>
            </button>
          )} */}

          <button
            type="button"
            className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center text-gray-600 uppercase transition duration-300 bg-white border-2 border-gray-600 rounded d-flex text-md font-ms-bold hover:bg-gray-600 hover:text-white print:hidden"
            onClick={() => window.print()}
          >
            <AiOutlinePrinter className="xl:mr-2" size={25} />
            <span className="hidden xl:inline-flex">Imprimir</span>
          </button>

          {canCheckHistory && (
            <Link
              to={`/ft-teixiduria/requests/${params.id}`}
              className="inline-flex items-center h-10 px-5 py-2 leading-normal text-center uppercase transition duration-300 bg-white border-2 rounded d-flex text-amber-600 border-amber-600 text-md font-ms-bold hover:bg-amber-600 hover:text-white print:hidden"
            >
              <AiOutlineHistory className="xl:mr-2" size={22} />
              <span className="hidden xl:inline-flex">Historial</span>
            </Link>
          )}

          {canRequest && (
            <Link
              to={`/ft-teixiduria/request-create/${encodeURIComponent(
                getFieldsState.Id
              )}`}
              className="relative inline-flex items-center h-10 px-5 py-2 leading-normal text-center text-teal-500 uppercase transition duration-300 bg-white border-2 border-teal-500 rounded text-md font-ms-bold hover:bg-teal-500 hover:text-white print:hidden"
            >
              <MdEdit className="xl:mr-2" size={22} />
              <span className="hidden xl:inline-flex">Sol·licitar</span>
              {/* <span className="absolute top-0 right-0 w-5 h-5 transform translate-x-1/2 -translate-y-1/2 bg-red-400 border-2 border-white rounded-full"></span> */}
            </Link>
          )}
        </div>
      </div>

      {/* Data */}
      <div className="p-4 space-y-2 print:p-0">
        {/* FITXA TÈCNICA */}
        <div
          style={{
            pageBreakAfter: 'always',
            pageBreakInside: 'avoid'
          }}
        >
          {/* VISIBLE EN IMPRESIÓN */}
          <h1 className="hidden w-full print:flex print:justify-around print:items-center print:pb-1 print:m-0">
            <span className="flex items-center justify-center h-full text-2xl font-ms-bold">
              FITXA TÈCNICA TEIXIDURIA
            </span>

            <span className="flex flex-row items-center justify-end h-full space-x-6">
              <span className="flex flex-col items-start justify-center h-full text-base">
                <span>{formattedDateTime}</span>
                <span>Format-IATF-013-C</span>
              </span>
            </span>
          </h1>

          <div className="relative grid grid-flow-row grid-cols-12 gap-2 print:gap-1 auto-rows-max">
            {/* DESCRIPCIÓ */}
            <Block
              title="Descripció"
              className="flex flex-col col-span-12"
              values={getFieldsState}
            />

            {/* PLEGADOR / CALANDRA: */}
            <Block
              title="Plegador / Calandra"
              className="flex flex-col col-span-9"
              values={getFieldsState}
            />

            {/* PINYONS / POLITJA */}
            <Block
              title="Pinyons / Politja"
              className="flex flex-col col-span-3"
              values={getFieldsState}
            />

            {/* GRADUACIÓ DEL PUNT I CONSUM */}
            <Block
              title="Graduació del punt i consum"
              className="flex flex-col col-span-9"
              values={getFieldsState}
            />

            {/* ALÇADA I SINCRONISME */}
            <Block
              title="Alçada i sincronisme"
              className="flex flex-col col-span-3"
              values={getFieldsState}
            />

            {/* OBSERVACIONS / NOTES DE FITXA TÈCNICA DE TEIXIDURIA */}
            <Block
              title="Observacions i notes de fitxa tècnica de teixiduria"
              className="flex flex-col col-span-12"
              values={getFieldsState}
            />
          </div>

          {getAllowEdit && (
            <div className="flex items-center w-full mt-2 h-100 print:hidden">
              <table className="mr-8 bg-white border border-gray-300 rounded-sm shadow-md ">
                <thead>
                  <tr>
                    <th
                      className="relative w-64 h-12 px-4 text-sm text-left text-white font-ms-bold bg-primary"
                      colSpan={documentsArray?.length}
                    >
                      FITXERS ADJUNTS
                      <button
                        className={` absolute right-2  bottom-2 p-1 font-ms-semi px-1 rounded-lg  transition-colors text-white duration-300     focus:shadow-outline hover:bg-white hover:text-primary    ${
                          readOnly && 'bg-gray-300'
                        } ${readOnly ? 'cursor-default' : 'cursor-pointer'}`}
                        onClick={handleClick}
                      >
                        <MdOutlineUploadFile size={30} />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>{renderTableData()}</tr>
                </tbody>
              </table>

              <input
                ref={hiddenFileInput}
                accept=".pdf, .xlsx"
                type="file"
                name="file"
                multiple="multiple"
                className=" h-1/6   hidden py-2 ml-2 p-2.5 text-primary font-ms-semi transition-colors duration-150  bg-white border border-primary rounded-lg focus:shadow-outline hover:bg-secondary hover:text-white cursor-pointer text-grey-darker "
                onChange={(e) => uploadDocument(e)}
              />
            </div>
          )}
        </div>

        {/* ÚLTIMA SOL·LICITUD ACCEPTADA */}
        {getApiCallGetRequest.data.RequestId !== 0 && (
          <div
            className="print:hidden"
            style={{
              pageBreakBefore: 'always',
              pageBreakInside: 'avoid'
            }}
          >
            <div className="relative grid grid-flow-row grid-cols-12 gap-2 auto-rows-max">
              <Block
                title="Última modificació"
                className="flex flex-col col-span-12"
                values={getApiCallGetRequest.data}
              />
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

FTTeixiduriaDetail.propTypes = {
  params: PropTypes.any.isRequired
}
