import { Field } from 'formik'
import PropTypes from 'prop-types'
import { BiPlusMedical } from 'react-icons/bi'
import { CgSpinner } from 'react-icons/cg'
import { FaTrash } from 'react-icons/fa'
import Select, { components } from 'react-select'

export default function GameTable({
  games,
  threadOptions,
  setFieldValue,
  setRequestFieldsState,
  fullValues
}) {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CgSpinner size={20} className="animate-spin" />
      </components.DropdownIndicator>
    )
  }

  const handleAddGame = () => {
    const newGame = {
      RequestMachineGroupId: 0,
      TSWeavingID: 0,
      IdThread: 0,
      KgH: 0,
      LFA: 0,
      IntMachineGroup: 0,
      Cones: 0,
      NoniusC: 0,
      NoniusP: 0,
      Spending: 0,
      Heads: 1,
      TolSpending: '7',
      Pressure: '3 - 10',
      ThreadMatter: null,
      Size: null,
      Game: null,
      GameNumber:
        games.length > 0
          ? String(Number(games[games.length - 1].GameNumber) + 1)
          : '1',
      Title: null,
      HasBeenRemoved: 0,
      HistoryMachineGroup: null
    }

    games.push(newGame)

    setRequestFieldsState((prevState) => ({
      ...prevState,
      RequestMachineGroups: games
    }))
  }

  const handleGameDelete = (e, index) => {
    games[index].HasBeenRemoved = 1

    setRequestFieldsState((prevState) => ({
      ...prevState,
      RequestMachineGroups: games
    }))
  }

  // Método para actualizar solamente 'MachineGroup':
  const update5 = (currentMachineGroup, index) => {
    console.log('🚀 ~ update5 ~ index:', index)
    console.log('🚀 ~ update5 ~ currentMachineGroup:', currentMachineGroup)
    // Recogemos el número de 'Agujas':
    if (fullValues?.RequestArticle?.Needle == null)
      setFieldValue('RequestArticle.Needle', '0')

    const needles = getNumber(fullValues?.RequestArticle?.Needle)

    // Recogemos las 'RPM (Revoluciones Por Minuto)':
    const Rpm = parseFloat(fullValues?.RequestArticle?.Rpm)

    // Calculation LFA
    let LFA = 0
    if (currentMachineGroup?.Spending !== 0 && needles !== 0) {
      LFA = parseFloat(
        ((currentMachineGroup?.Spending / needles) * 100).toFixed(2)
      )
    }

    // Recogemos los campos 'Title' y 'Heads'
    const numCabos = currentMachineGroup.Heads
    let numTitle
    if (currentMachineGroup?.Title == null) numTitle = 0
    numTitle = getNumber(currentMachineGroup?.Title)

    // Comprobamos la unidad de medida de la máquina (TEX, DTEX, DNS, NM, NE, NA o NB):
    const measureUnitTitle = currentMachineGroup?.Title
      ? currentMachineGroup.Title.split(' ').pop().toUpperCase()
      : ''

    // Fórmulas según Excel: Cálculo producciones (Tickets relacionados: #29473, #33208, #89001).
    // - Más información sobre las fórmulas y las conversiones en el Jedi: https://jedi.gestinet.com/books/cetriko/page/formules-de-produccio-fitxes-tecniques-teixiduria
    let convertToTex = 0

    if (measureUnitTitle.toUpperCase() === 'TEX') {
      convertToTex = Number((numTitle * numCabos).toFixed(10))
    } else if (measureUnitTitle.toUpperCase() === 'DTEX') {
      convertToTex = Number(((numTitle * numCabos) / 10).toFixed(10))
    } else if (measureUnitTitle.toUpperCase() === 'DNS') {
      convertToTex = Number(((numTitle * numCabos) / 9).toFixed(10))
    } else if (measureUnitTitle.toUpperCase() === 'NM') {
      convertToTex = Number((1000 / (numTitle / numCabos)).toFixed(10))
    } else if (measureUnitTitle.toUpperCase() === 'NE') {
      convertToTex = Number(
        ((453.6 / 768) * (1000 / (numTitle / numCabos))).toFixed(10)
      )
    } else if (measureUnitTitle.toUpperCase() === 'NA') {
      convertToTex = Number(
        ((440 / 777.5) * (1000 / (numTitle / numCabos))).toFixed(10)
      )
    } else if (measureUnitTitle.toUpperCase() === 'NB') {
      convertToTex = Number(
        ((8.33 / 777.5) * (1000 * (numTitle / numCabos))).toFixed(10)
      )
    } else {
      convertToTex = 0
    }

    // Ticket relacionat: #33208
    // - Calcular Kg/h:
    let updateKgH = 0

    // Función nueva para calcular Kg/h y redondeamos a 10 decimales:
    if (Rpm !== 0) {
      updateKgH = parseFloat(
        (
          (convertToTex *
            currentMachineGroup.Spending *
            Rpm *
            60 *
            currentMachineGroup.Cones) /
          100000000
        ).toFixed(10)
      )
      currentMachineGroup.KgH = updateKgH
    } else {
      currentMachineGroup.KgH = 0
    }

    currentMachineGroup.LFA = LFA

    console.log(
      '🚀 ~ update5 ~ fullValues.RequestMachineGroups:',
      fullValues.RequestMachineGroups
    )
    fullValues.RequestMachineGroups[index] = currentMachineGroup
    setFieldValue(
      'RequestArticle.RequestMachineGroups',
      fullValues.RequestMachineGroups
    )

    update5B()
  }

  // Método para actualizar solamente 'TSWeaving':
  const update5B = () => {
    // #################################################################################################
    // Inicio cálculos de 'Vueltas'
    // #################################################################################################

    // Recogemos todas las máquinas relacionadas con el 'id' especificado y sumamos los 'Kg' por 'Hora'
    // de todas las máquinas que se devuelvan, juntas:
    let sumKgH100 = 0
    fullValues?.RequestMachineGroups.forEach((machine) => {
      // Redondeamos a 10 para que todos los cálculos se hagan con 10 decimales:
      const roundedMachineGroupKgH = parseFloat(machine.KgH.toFixed(10))
      sumKgH100 = parseFloat((sumKgH100 + roundedMachineGroupKgH).toFixed(10))
    })

    if (sumKgH100 > 0) {
      // Recogemos 'RPM (Revoluciones Por Minuto) y 'KgPart (Kg por pieza)':
      let Rpm = 0
      const RpmSubstitution = fullValues?.RequestArticle?.Rpm?.toString()
      Rpm = parseFloat(RpmSubstitution).toFixed(1)
      setFieldValue(
        'RequestArticle.Rpm',
        Number(Rpm?.toString().replace(/,/g, '.') ?? 0).toFixed(
          Rpm % 1 === 0 ? 0 : 1
        )
      )

      let partKg = 0
      if (fullValues?.RequestArticle?.KgPart == null) {
        setFieldValue('RequestArticle.KgPart', '0')
      } else {
        const partKgSubstitution = fullValues?.RequestArticle?.KgPart.toString()
        const partKgReplaced = partKgSubstitution.replace('.', ',')
        partKg = parseFloat(partKgReplaced).toFixed(1)
      }

      // Convertimos la suma de 'Kg' por 'Hora' de las máquinas que se nos devuelve de la Base de Datos
      // a un número con 2 decimales y otro con 1 decimal:
      const roundedSumKgH10Decimals = parseFloat(sumKgH100.toFixed(10))

      if (Rpm !== 0) {
        // Total 'Vueltas' por 'Kg' y redondeamos a 1 decimales:
        const turnsPerKg = parseFloat(
          ((Rpm * 60) / roundedSumKgH10Decimals).toFixed(10)
        )

        // Total 'Vueltas' por 'Kg' y redondeamos a 0 decimales:
        const turnsPerPart = parseFloat(
          (((Rpm * 60) / roundedSumKgH10Decimals) * partKg).toFixed(10)
        )

        setFieldValue(
          'RequestArticle.TurnsPerKg',
          Math.round(Number(turnsPerKg?.toString().replace(/,/g, '.') ?? 0))
        )
        setFieldValue(
          'RequestArticle.TurnsPerPart',
          Math.round(Number(turnsPerPart?.toString().replace(/,/g, '.') ?? 0))
        )
        setFieldValue(
          'RequestArticle.TurnsPerPartByOperator',
          Math.round(Number(turnsPerPart?.toString().replace(/,/g, '.') ?? 0))
        )
      } else {
        setFieldValue('RequestArticle.TurnsPerKg', '0')
        setFieldValue('RequestArticle.TurnsPerPart', '0')
        setFieldValue('RequestArticle.TurnsPerPartByOperator', '0')
      }

      // #################################################################################################
      // Fin cálculos de 'Vueltas'
      // #################################################################################################

      // #################################################################################################
      // Inicio cálculos de 'KgH_100', 'KgD_100', 'KgH_80' y 'KgD_80'
      // #################################################################################################

      // Total 'Kg' por 'Hora' cuando la máquina está al 100% y redondeamos a 10 decimales:
      const totalKgH100 = parseFloat(roundedSumKgH10Decimals.toFixed(2))

      // Total 'Kg' por 'Hora' cuando la máquina está al 80% y redondeamos a 10 decimales:
      const totalKgH80 = parseFloat((roundedSumKgH10Decimals * 0.8).toFixed(2))

      // Total 'Kg' por 'Dia' cuando la máquina está al 100% y redondeamos a 10 decimales:
      const totalKgD100 = parseFloat((roundedSumKgH10Decimals * 24).toFixed(2))

      // Total 'Kg' por 'Dia' cuando la máquina está al 80% y redondeamos a 10 decimales:
      const totalKgD80 = parseFloat(
        (roundedSumKgH10Decimals * 24 * 0.8).toFixed(2)
      )

      setFieldValue('RequestArticle.KgH_100', totalKgH100.toString())
      setFieldValue('RequestArticle.KgH_80', totalKgH80.toString())
      setFieldValue('RequestArticle.KgD_100', totalKgD100.toString())
      setFieldValue('RequestArticle.KgD_80', totalKgD80.toString())

      // #################################################################################################
      // Fin cálculos de 'KgH_100', 'KgD_100', 'KgH_80' y 'KgD_80'
      // #################################################################################################
    } else {
      setFieldValue('RequestArticle.TurnsPerKg', '0')
      setFieldValue('RequestArticle.TurnsPerPart', '0')
      setFieldValue('RequestArticle.TurnsPerPartByOperator', '0')
    }
  }

  const getNumber = (text) => {
    let b = '' // Initialize an empty string to collect digits
    let val = 0 // Initialize the return value to 0

    // Loop through each character in the input text
    for (let i = 0; i < text.length; i++) {
      // Check if the character is a digit
      if (/\d/.test(text[i])) {
        b += text[i] // Append digit to string `b`
      }
    }

    // If `b` contains any digits, parse it to an integer
    if (b.length > 0) {
      val = parseInt(b, 10)
    }

    return val // Return the parsed number
  }

  return (
    <table className="relative w-full px-4 border-2 table-fixed border-primary print:border">
      <thead className="w-full text-white bg-primary-dark">
        <tr className="w-full divide-x divide-red-200 divide-dashed">
          <th className="w-1/12 overflow-hidden text-sm truncate">Joc</th>
          <th className="w-6/12 overflow-hidden text-sm truncate">Matèria</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Caps</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Cons</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tipus</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Consum</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tol.</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tensió</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">NonC</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">NonP</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Kg/h</th>

          <th className="w-1/12 print:hidden">
            <div className="items-center w-full flex-justify-center tooltip">
              <button
                onClick={() => handleAddGame()}
                className="p-2 py-1 text-sm transition-colors duration-150 rounded-lg appearance-none cursor-pointer font-ms-semi hover:bg-white hover:text-primary focus:shadow-outline"
              >
                <BiPlusMedical size={25} />
              </button>
            </div>
          </th>
        </tr>
      </thead>

      <tbody className="w-full divide-y divide-dashed divide-red-50">
        {games?.length > 0 &&
          games
            .filter((game) => !game.HasBeenRemoved)
            .map((game, index) => (
              <tr
                className="w-full print:divide-x print:divide-dashed print:divide-red-50"
                key={index}
              >
                {/* Campo 'Joc': */}
                <td className="w-1/12 text-xs print:w-9">
                  <div className="flex items-center justify-center print:h-100">
                    <Field
                      type="text"
                      name={`RequestMachineGroups[${index}].Game`}
                      className="rounded w-1/2 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </td>

                {/* Campo 'Matèria': */}
                <td className="w-6/12 print:w-4/12 text-xs py-1 print:py-0.5">
                  <div className="flex items-center justify-center text-xs print:h-100">
                    {threadOptions ? (
                      <Select
                        className="w-11/12 text-xs leading-tight print:hidden text-grey-darker"
                        defaultValue={threadOptions.find(
                          (option) =>
                            option.ThreadMatter === game.ThreadMatter &&
                            option.ThreadId === game.IdThread
                        )}
                        onChange={(e) => {
                          setFieldValue(
                            `RequestMachineGroups[${index}].IdThread`,
                            e.ThreadId
                          )
                          setFieldValue(
                            `RequestMachineGroups[${index}].ThreadMatter`,
                            e.ThreadMatter
                          )
                          setFieldValue(
                            `RequestMachineGroups[${index}].Title`,
                            e.ThreadTitle
                          )
                        }}
                        options={threadOptions}
                        getOptionLabel={(o) => o.ThreadMatter}
                        getOptionValue={(o) => o.ThreadId}
                        placeholder="Seleccionar..."
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: '1px solid #d1d5db',
                            boxShadow: state.isFocused ? 'none' : 'none',
                            '&:hover': {
                              border: '1px solid #9ca3af'
                            },
                            margin: '0px',
                            minHeight: 'fit-content'
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: '#333333',
                            backgroundColor: state.isFocused && '#d1d5db',
                            '&:hover': {
                              backgroundColor: '#f3f4f6'
                            }
                          }),
                          valueContainer: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '0px 8px',
                            margin: '0px'
                          }),
                          dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '2px 8px'
                          })
                        }}
                      />
                    ) : (
                      <Select
                        components={{ DropdownIndicator }}
                        className="w-11/12 leading-tight print:hidden text-grey-darker"
                        placeholder=""
                        options={threadOptions}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: '2px solid #d1d5db',
                            boxShadow: state.isFocused ? 'none' : 'none',
                            '&:hover': {
                              border: '2px solid #9ca3af'
                            },
                            margin: '0px',
                            minHeight: 'fit-content'
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: '#333333',
                            backgroundColor: state.isFocused && '#d1d5db',
                            '&:hover': {
                              backgroundColor: '#f3f4f6'
                            }
                          }),
                          valueContainer: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '0px 8px',
                            margin: '0px'
                          }),
                          dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '2px 8px',
                            color: '#333333'
                          })
                        }}
                        defaultValue={{
                          label: game.ThreadMatter,
                          value: game.IdThread,
                          title: game.Title
                        }}
                        isDisabled
                      />
                    )}
                  </div>
                </td>

                {/* Campo 'Caps': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <Field
                      type="text"
                      name={`RequestMachineGroups[${index}].Heads`}
                      className="rounded w-11/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                      onBlur={() => update5(game, index)}
                    />
                  </div>
                </td>

                {/* Campo 'Cons': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <Field
                      type="text"
                      name={`RequestMachineGroups[${index}].Cones`}
                      className="rounded w-11/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                      onBlur={() => update5(game, index)}
                    />
                  </div>
                </td>

                {/* Campo 'Tipus': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <Field
                      as="select"
                      type="text"
                      name={`RequestMachineGroups[${index}].Size`}
                      className="rounded w-11/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none cursor-pointer hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                    >
                      <option value="LFA">LFA</option>
                      <option value="W">W</option>
                      <option value="MRA">MRA</option>
                      <option value="SCH">SCH</option>
                    </Field>
                  </div>
                </td>

                {/* Campo 'Consum': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <Field
                      type="text"
                      name={`RequestMachineGroups[${index}].Spending`}
                      className="rounded w-11/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                      onBlur={() => update5(game, index)}
                    />
                  </div>
                </td>

                {/* Campo 'Tol.': */}
                <td className="w-1/12 text-xs">
                  <div className="flex flex-row items-center justify-center print:h-100">
                    <span>&plusmn;</span>
                    <Field
                      type="text"
                      name={`RequestMachineGroups[${index}].TolSpending`}
                      className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                      onBlur={() => update5(game, index)}
                    />
                  </div>
                </td>

                {/* Campo 'Tensió': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <Field
                      type="text"
                      name={`RequestMachineGroups[${index}].Pressure`}
                      className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                      onBlur={() => update5(game, index)}
                    />
                  </div>
                </td>

                {/* Campo 'NonC': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <Field
                      type="text"
                      name={`RequestMachineGroups[${index}].NoniusC`}
                      className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                      onBlur={() => update5(game, index)}
                    />
                  </div>
                </td>

                {/* Campo 'NonP': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <Field
                      type="text"
                      name={`RequestMachineGroups[${index}].NoniusP`}
                      className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                      onBlur={() => update5(game, index)}
                    />
                  </div>
                </td>

                {/* Campo 'Kg/H': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 truncate leading-none">
                      {fullValues?.RequestMachineGroups[index]?.KgH}
                    </div>
                  </div>
                </td>

                {/* Botón eliminar: */}
                <td className="w-1/12">
                  <div className="flex items-center justify-center">
                    <button
                      className="p-2 font-medium text-black bg-white rounded-lg cursor-pointer print:hidden focus:shadow-outline hover:border-gray-400 focus:border-gray-400 focus:outline-none"
                      type="button"
                      onClick={(e) => handleGameDelete(e, index)}
                    >
                      <FaTrash size={17} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  )
}

GameTable.propTypes = {
  games: PropTypes.any,
  threadOptions: PropTypes.any,
  setFieldValue: PropTypes.func,
  setRequestFieldsState: PropTypes.func,
  fullValues: PropTypes.object
}
