import Form1 from 'forms/ftteixiduriarequest/descriptionView'
import Form2 from 'forms/ftteixiduriarequest/folderView'
import Form5 from 'forms/ftteixiduriarequest/heightView'
import Form6 from 'forms/ftteixiduriarequest/observationsView'
import Form4 from 'forms/ftteixiduriarequest/pointGraduationView'
import Form3 from 'forms/ftteixiduriarequest/sprocketsView'
import PropTypes from 'prop-types'

export default function Block({ title, className, values, threads, games }) {
  return (
    <div
      className={`${className && className} 
      rounded border-2 shadow-md border-gray-300`}
    >
      <div className="flex items-center justify-between w-full px-2 py-2 text-sm uppercase bg-gray-300 print:py-1 font-ms-bold">
        <span>{title}</span>
      </div>

      {(() => {
        switch (title) {
          case 'Descripció':
            return <Form1 values={values} threads={threads} />
          case 'Plegador / Calandra':
            return (
              <Form2
                values={values}
                machineCode={values?.Machine?.slice(0, 3)}
                // machineCode="PLT"
              />
            )
          case 'Pinyons / Politja':
            return (
              <Form3
                values={values}
                machineCode={values?.Machine?.slice(0, 3)}
                // machineCode="PLT"
              />
            )
          case 'Graduació del punt i consum':
            return <Form4 values={values} games={games} />
          case 'Alçada i sincronisme':
            return <Form5 values={values} />
          case 'Observacions / Notes de fitxa tècnica de teixiduria':
            return <Form6 values={values} />
          default:
            return null
        }
      })()}
    </div>
  )
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  values: PropTypes.object,
  threads: PropTypes.any,
  games: PropTypes.any
}
