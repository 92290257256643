import PropTypes from 'prop-types'

export default function ObservationsForm({ fields }) {
  return (
    <div className="grid grid-cols-2 gap-2 p-2">
      {/* Campo 'Observació': */}
      <div className="items-center block col-span-1">
        <label className="block mb-1 text-sm text-grey-darker font-ms-semi col-start-full">
          Observació:
        </label>

        <div className="w-full h-24 px-2 py-1 align-middle border-2 border-gray-300 rounded text-grey-darker">
          {fields?.Obseravtion}
        </div>
      </div>

      {/* Campo 'NOTA IMPORTANT': */}
      <div className="items-center block col-span-1">
        <label className="block mb-1 text-sm text-grey-darker font-ms-semi col-start-full">
          NOTA IMPORTANT:
        </label>

        <div
          className="w-full h-24 px-2 py-1 align-middle border-2 border-gray-300 rounded text-grey-darker"
          style={{
            backgroundColor: 'rgba(233, 255, 0, 0.5)',
            color: '#333333',
            borderColor: '#D3E600'
          }}
        >
          {fields?.DestacableText}
        </div>
      </div>

      {/* Campo 'Edició': */}
      <div className="grid grid-cols-12 col-span-1">
        <label className="self-center block col-span-3 col-start-2 text-sm text-grey-darker font-ms-semi col-start-full">
          Edició:
        </label>

        <div className="rounded col-span-7 py-0.5 px-2 border-2 border-gray-300 text-grey-darker">
          {fields?.Edition}
        </div>
      </div>

      {/* Campo 'Data edició': */}
      <div className="grid grid-cols-12 col-span-1">
        <label className="self-center block col-span-3 col-start-2 text-sm text-grey-darker font-ms-semi col-start-full">
          Data edició:
        </label>

        <div className="rounded col-span-7 py-0.5 px-2 border-2 border-gray-300 text-grey-darker">
          {fields?.EditionDate}
        </div>
      </div>
    </div>
  )
}

ObservationsForm.propTypes = {
  fields: PropTypes.object
}
