export default function ThreadTable() {
  const fieldStyle = 'h-6 w-full flex items-center justify-center'

  return (
    <div className="w-full p-2 border-t border-dashed print:flex print:flex-row print:justify-between print:items-center print:text-sm print:space-x-2">
      <table className="relative w-full px-4 border-2 table-fixed print:w-11/12 border-primary print:border">
        <thead className="w-full text-white bg-primary-dark">
          <tr className="w-full divide-x divide-red-200 divide-dashed">
            <th className="w-1/12 py-1.5 text-sm print:py-0"></th>
            <th className="w-7/12 py-1.5 text-sm print:py-0">Matèria</th>
            <th className="w-1/12 py-1.5 text-sm print:py-0">GRS</th>
            <th className="w-2/12 py-1.5 text-sm print:py-0">Nº Cons</th>
            <th className="w-2/12 py-1.5 text-sm print:py-0">Percentatge</th>
          </tr>
        </thead>

        <tbody className="w-full divide-y divide-red-200 divide-dashed">
          {[...Array(4)].map((_, index) => (
            <tr
              className="w-full divide-x divide-red-200 divide-dashed"
              key={index}
            >
              {/* Campo 'Fil x': */}
              <td className="w-1/12 text-center">Fil {index}</td>

              {/* Campo 'Matèria': */}
              <td className="w-6/12 print:w-7/12 py-1.5">
                <div className="flex flex-row items-center justify-center w-full">
                  <div className={fieldStyle} />
                </div>
              </td>

              {/* Campo 'GRS': */}
              <td className="w-2/12 text-center">
                <div className={fieldStyle} />
              </td>

              {/* Campo 'Nº Cons': */}
              <td className="w-2/12 text-center">
                <div className={fieldStyle} />
              </td>

              {/* Campo 'Percentatge': */}
              <td className="w-2/12 text-center">
                <div className={fieldStyle} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex-row items-center justify-center hidden w-1/12 print:flex">
        <span className="w-6 h-6 border border-black"></span>
      </div>
    </div>
  )
}

ThreadTable.propTypes = {}
