import { TitleContext } from 'contexts/TitleContext'
import CheckListForm from 'forms/Control/CheckList/CheckListForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { useLocation } from 'wouter'

export default function CheckListDetail({ params }) {
  // Context
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()

  // Fetch
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallGetUsers, setApiCallGetUsers] = useFetch()
  const [getApiCallGetEquipments, setApiCallGetEquipments] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const isNew = params.id === 'nou'

  // Var
  const [fields, setFields] = useState({
    Id: 0,
    EquipmentId: 0,
    EquipmentName: '',
    Turn: '',
    Shed: '',
    UserId: 0,
    UserName: '',
    OF: '',
    Date: '',
    Verification1: false,
    Verification2: false,
    Verification3: false,
    Verification4: false,
    Verification5: false,
    Verification6: false,
    Verification7: false,
    Verification8: false,
    Verification9: false,
    Verification10: false,
    Verification11: false,
    Verification12: false,
    Verification13: false,
    Verification14: false,
    Verification15: false,
    Verification16: false,
    IsOK: false,
    Description: ''
  })

  // Get users
  useEffect(() => {
    const title = {
      name: isNew ? 'Crear registre' : 'Modificar registre',
      deleteCheckListBtn: !isNew,
      checklistId: !isNew ? params.id : null,
      button: !isNew ? 'print' : null,
      buttons: [
        {
          name: 'Tornar',
          link: `/checklist`
        }
      ]
    }
    setTitle(title)

    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar Users del ECAP'
    }
    setApiCallGetUsers(userListAPIParams)
  }, [])

  // Get equipments
  useEffect(() => {
    if (getApiCallGetUsers.data) {
      const equipmentListAPIParams = {
        url: `inventorymachine/isTool`,
        method: 'GET',
        messageKo: 'Error al recuperar dades'
      }
      setApiCallGetEquipments(equipmentListAPIParams)
    }
  }, [getApiCallGetUsers.data])

  // Get checklist
  useEffect(() => {
    if (getApiCallGetUsers.data && !isNew) {
      const apiCallGet = {
        url: `checklist/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades'
      }
      setApiCallGet(apiCallGet)
    }
  }, [getApiCallGetEquipments.data])

  // Prepare checklist values
  useEffect(() => {
    if (getApiCallGet.data) {
      setFields({
        Id: getApiCallGet.data?.Id,
        EquipmentId: getApiCallGet.data?.EquipmentId,
        EquipmentName: getApiCallGet.data?.EquipmentName,
        Turn: getApiCallGet.data?.Turn,
        Shed: getApiCallGet.data?.Shed,
        UserId: getApiCallGet.data?.UserId,
        UserName: getApiCallGet.data?.UserName,
        OF: getApiCallGet.data?.OF,
        Date: getApiCallGet.data?.Date,
        Verification1: getApiCallGet.data?.Verification1,
        Verification2: getApiCallGet.data?.Verification2,
        Verification3: getApiCallGet.data?.Verification3,
        Verification4: getApiCallGet.data?.Verification4,
        Verification5: getApiCallGet.data?.Verification5,
        Verification6: getApiCallGet.data?.Verification6,
        Verification7: getApiCallGet.data?.Verification7,
        Verification8: getApiCallGet.data?.Verification8,
        Verification9: getApiCallGet.data?.Verification9,
        Verification10: getApiCallGet.data?.Verification10,
        Verification11: getApiCallGet.data?.Verification11,
        Verification12: getApiCallGet.data?.Verification12,
        Verification13: getApiCallGet.data?.Verification13,
        Verification14: getApiCallGet.data?.Verification14,
        Verification15: getApiCallGet.data?.Verification15,
        Verification16: getApiCallGet.data?.Verification16,
        Description: getApiCallGet.data?.Description,
        IsOK: getApiCallGet.data?.IsOK
      })
    }
  }, [getApiCallGet.data])

  const handleSubmit = async (values) => {
    let url = `checklist`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    }

    const apiCallPost = {
      url,
      method,
      body: values,
      messageKo: isNew
        ? 'Error guardant el registre!'
        : 'Error actualitzant el registre!',
      messageOk: isNew ? 'Registre guardat!' : 'Registre actualitzat!'
    }
    await setApiCallPost(apiCallPost)
    if (!getApiCallPost.error) setLocation('/checklist')
  }

  return (isNew &&
    getApiCallGetUsers.data !== null &&
    getApiCallGetUsers.data !== undefined &&
    getApiCallGetUsers.data[0] !== null &&
    getApiCallGetEquipments.data !== null &&
    getApiCallGetEquipments.data !== undefined &&
    getApiCallGetEquipments.data[0] !== null) ||
    (!isNew && fields.Id !== 0) ? (
    <div className="w-full p-4 bg-white">
      <CheckListForm
        users={getApiCallGetUsers.data}
        equipments={getApiCallGetEquipments.data}
        fields={fields}
        handleSubmit={handleSubmit}
      />
    </div>
  ) : (
    <div className="inline-flex items-center justify-start w-full p-4">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

CheckListDetail.propTypes = {
  params: PropTypes.any
}
