import alcades from 'assets/icons/alcades.png'
import PropTypes from 'prop-types'

export default function HeightForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Sincronit. */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Sincronit.:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`${
            valuesBefore?.Synchronization !== valuesAfter?.Synchronization
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.Synchronization
            : valuesAfter?.Synchronization}
        </div>
      </div>

      {/* Formació */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Formació:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`${
            valuesBefore?.Formation !== valuesAfter?.Formation
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Formation : valuesAfter?.Formation}
        </div>
      </div>

      {/* Campo 'Alçada plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label
          className="flex items-center col-span-4 py-1 text-sm text-grey-darker font-ms-semi"
          htmlFor="DishHeight1"
        >
          Alçada plat:
        </label>

        <div className="relative col-span-3">
          <img src={alcades} alt="pinyons plegador" className="w-36" />

          <div
            className={`${
              valuesBefore?.DishHeight1 !== valuesAfter?.DishHeight1
                ? side === 'left'
                  ? 'bg-red-100 border-red-300'
                  : isAnswered
                  ? 'bg-emerald-100 border-emerald-300'
                  : 'bg-amber-100 border-amber-300'
                : 'border-gray-300 bg-gray-100'
            } absolute left-0 px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 sm:w-2/5 lg:w-16`}
          >
            {side === 'left'
              ? valuesBefore?.DishHeight1
              : valuesAfter?.DishHeight1}
          </div>

          <div
            className={`${
              valuesBefore?.DishHeight2 !== valuesAfter?.DishHeight2
                ? side === 'left'
                  ? 'bg-red-100 border-red-300'
                  : isAnswered
                  ? 'bg-emerald-100 border-emerald-300'
                  : 'bg-amber-100 border-amber-300'
                : 'border-gray-300 bg-gray-100'
            } absolute px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 left-20 sm:w-2/5 lg:w-16`}
          >
            {side === 'left'
              ? valuesBefore?.DishHeight2
              : valuesAfter?.DishHeight2}
          </div>
        </div>
      </div>

      {/* Campo 'Desplaçament plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label
          className="flex items-center col-span-4 pb-1 text-sm text-grey-darker font-ms-semi"
          htmlFor="DishDisplacement1"
        >
          Desplaçament plat:
        </label>

        <div className="relative col-span-3">
          <img src={alcades} alt="Pinyons Plegador" className="w-36" />

          <div
            className={`${
              valuesBefore?.DishDisplacement1 !== valuesAfter?.DishDisplacement1
                ? side === 'left'
                  ? 'bg-red-100 border-red-300'
                  : isAnswered
                  ? 'bg-emerald-100 border-emerald-300'
                  : 'bg-amber-100 border-amber-300'
                : 'border-gray-300 bg-gray-100'
            } absolute left-0 px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 sm:w-2/5 lg:w-16`}
          >
            {side === 'left'
              ? valuesBefore?.DishDisplacement1
              : valuesAfter?.DishDisplacement1}
          </div>

          <div
            className={`${
              valuesBefore?.DishDisplacement1 !== valuesAfter?.DishDisplacement1
                ? side === 'left'
                  ? 'bg-red-100 border-red-300'
                  : isAnswered
                  ? 'bg-emerald-100 border-emerald-300'
                  : 'bg-amber-100 border-amber-300'
                : 'border-gray-300 bg-gray-100'
            } absolute px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 left-20 sm:w-2/5 lg:w-16`}
          >
            {side === 'left'
              ? valuesBefore?.DishDisplacement1
              : valuesAfter?.DishDisplacement1}
          </div>
        </div>
      </div>
    </div>
  )
}

HeightForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
