import alcades from 'assets/icons/alcades.png'
import PropTypes from 'prop-types'

export default function HeightForm({ fields }) {
  return (
    <div className="grid items-center grid-cols-12 p-2 auto-rows-min md:gap-y-1 lg:gap-y-3">
      {/* Sincronit. */}
      <div className="col-span-12 mt-5 text-sm lg:mt-5 xl:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Sincronit.:
      </div>
      <div className="flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 min-h-8 lg:col-span-8 xl:col-span-9">
        <div className="w-full p-0.5 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end">
          {fields?.Synchronization}
        </div>
      </div>

      {/* Formació */}
      <div className="col-span-12 mt-5 text-sm lg:mt-5 xl:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Formació:
      </div>
      <div className="flex items-center col-span-12 mt-0 space-x-2 cursor-not-allowed lg:mt-5 xl:mt-0 min-h-8 lg:col-span-8 xl:col-span-9">
        <div className="w-full p-0.5 leading-tight text-base border-b border-gray-300 overflow-ellipsis overflow-hidden flex items-center place-self-end">
          {fields?.Formation}
        </div>
      </div>

      {/* Campo 'Alçada plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label className="flex items-center col-span-4 py-1 text-sm text-grey-darker font-ms-semi">
          Alçada plat:
        </label>

        <div className="relative col-span-3">
          <img src={alcades} alt="pinyons plegador" className="w-36" />

          <div className="absolute left-0 px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 sm:w-2/5 lg:w-16">
            {fields?.DishHeight1}
          </div>

          <div className="absolute px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 left-20 sm:w-2/5 lg:w-16">
            {fields?.DishHeight2}
          </div>
        </div>
      </div>

      {/* Campo 'Desplaçament plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label className="flex items-center col-span-4 pb-1 text-sm text-grey-darker font-ms-semi">
          Desplaçament plat:
        </label>

        <div className="relative col-span-3">
          <img src={alcades} alt="Pinyons Plegador" className="w-36" />

          <div className="absolute left-0 px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 sm:w-2/5 lg:w-16">
            {fields?.DishDisplacement1}
          </div>

          <div className="absolute px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 left-20 sm:w-2/5 lg:w-16">
            {fields?.DishDisplacement2}
          </div>
        </div>
      </div>
    </div>
  )
}

HeightForm.propTypes = {
  fields: PropTypes.object
}
