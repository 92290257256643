import BlockVersion3 from 'components/FTTeixiduriaRequest/Request/Block/BlockCompare'
import BlockVersion2 from 'components/FTTeixiduriaRequest/Request/Block/BlockCreate'
import BlockVersion1 from 'components/FTTeixiduriaRequest/Request/Block/BlockView'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import { CgSpinner } from 'react-icons/cg'
import { FaCheck, FaRegClock, FaTimes } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { useLocation } from 'wouter'
import * as Yup from 'yup'

export default function FTTeixiduriaRequest({ params }) {
  // Refs
  const submitActionRef = useRef()

  // Context:
  const { setTitle } = useContext(TitleContext)
  const [, setLocation] = useLocation()
  const { user } = useContext(UserContext)
  const [ramTab, setRamTab] = useState(1)

  // Fetch
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallGetUser, setApiCallGetUser] = useFetch()
  const [getApiCallGetUsers, setApiCallGetUsers] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()

  // State:
  const [getIsAnswered, setIsAnswered] = useState(false)
  const [canRespond, setCanRespond] = useState(false)
  const [users, setUsers] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)

  // Vars
  const isViewScreen = params.type === 'view'
  const [getRequestFieldsState, setRequestFieldsState] = useState({
    RequestId: 0,
    ArticleId: '',
    State: '',
    Reason: '',
    ResponderComment: '',
    UpdatedAt: '',
    RequesterId: null,
    ResponderId: null,
    RequestArticle: null,
    RequestMachineGroups: null,
    RequestThreads: null
  })

  // Formik validation
  const validationSchema1 = Yup.object().shape({
    Reason: Yup.string().required(
      '(*No es pot crear una sol·licitud sense especificar un motiu.)'
    )
  })

  const validationSchema2 = Yup.object().shape({
    Reason: Yup.string().required(
      '(*No es pot crear una sol·licitud sense especificar un motiu.)'
    ),
    ResponderComment: Yup.string().required(
      '(*No es pot respondre a una sol·licitud sense especificar un comentari.)'
    )
  })

  // ###########################################

  // Get api calls
  useEffect(() => {
    const title = {
      name: `Sol·licitud`,
      buttons: [
        {
          name: 'Tornar',
          link: isViewScreen
            ? `/ft-teixiduria/requests/${params.id}`
            : `/ft-teixiduria/${params.id}`
        }
      ]
    }
    setTitle(title)

    const apiCallGetUsers = {
      url: `user/active`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP!'
    }
    setApiCallGetUsers(apiCallGetUsers)
  }, [])

  // Get users api call responde
  useEffect(() => {
    if (getApiCallGetUsers.data) {
      setUsers(getApiCallGetUsers.data)

      const apiCallGetUser = {
        url: `user/${user.Id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGetUser(apiCallGetUser)
    }
  }, [getApiCallGetUsers.data])

  // Get user api call response
  useEffect(() => {
    if (getApiCallGetUser.data && users) {
      setCurrentUser(getApiCallGetUser.data)

      const apiCallGet = {
        url: isViewScreen
          ? `tsweaving/request/${params.idRequest}`
          : `tsweaving/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades del ECAP!'
      }
      setApiCallGet(apiCallGet)
    }
  }, [getApiCallGetUser.data])

  // Get request api call response
  useEffect(() => {
    if (getApiCallGet.data && currentUser) {
      const apiData = JSON.parse(JSON.stringify(getApiCallGet.data))

      if (isViewScreen) {
        // Check if current user can manage requests
        const allow = checkPermissions(apiData)
        setCanRespond(allow)

        // Create request object
        setRequestFieldsState({
          RequestId: apiData?.RequestId ?? 0,
          ArticleId: apiData?.Id ?? '',
          State: apiData?.State ?? 'P',
          Reason: apiData?.Reason ?? '',
          ResponderComment: apiData?.ResponderComment ?? '',
          UpdatedAt: apiData?.UpdatedAt ?? '',
          RequesterId: apiData?.RequesterId ?? null,
          ResponderId: apiData?.ResponderId ?? null,
          RequestArticle: apiData?.RequestArticle ?? null,
          RequestMachineGroups: apiData?.RequestMachineGroups ?? null,
          RequestThreads: apiData?.RequestThreads ?? null
        })

        // Set request state
        setIsAnswered(apiData?.State === 'A')
      } else {
        const requestArticle = buildRequestArticle(apiData)
        const requestMachineGroups = buildRequestMachineGroups(apiData)
        const requestThreads = buildRequestThreads(apiData)

        setRequestFieldsState({
          RequestId: 0,
          ArticleId: apiData?.Id ?? 0,
          State: 'P',
          Reason: '',
          ResponderComment: '',
          UpdatedAt: '',
          RequesterId: user.Id ?? null,
          ResponderId: null,
          RequestArticle: requestArticle,
          RequestMachineGroups: requestMachineGroups,
          RequestThreads: requestThreads
        })
      }
    }
  }, [getApiCallGet.data, currentUser])

  const checkPermissions = (apiData) => {
    const allow = true
    // let allow = false

    // const requestArticleClientManagerArray =
    //   apiData?.RequestArticle?.ClientManager === '' ||
    //   apiData?.RequestArticle?.ClientManager === null
    //     ? []
    //     : apiData?.RequestArticle?.ClientManager?.replace(' ', '').split(',')

    // apiData.RequestArticle.ClientManager = requestArticleClientManagerArray

    // if (apiData?.RequestArticle?.RequestArticleId !== 0) {
    //   const historyArticleClientManagerArray =
    //     apiData?.RequestArticle?.HistoryArticle?.ClientManager === '' ||
    //     apiData?.RequestArticle?.HistoryArticle?.ClientManager === null
    //       ? []
    //       : apiData?.RequestArticle?.HistoryArticle?.ClientManager?.replace(
    //           ' ',
    //           ''
    //         ).split(',')

    //   apiData.RequestArticle.HistoryArticle.ClientManager =
    //     historyArticleClientManagerArray
    // }

    // const userRol = user.Role.Id
    // let articleEnterprice = null
    // const canRespondCetriko = currentUser?.CanRespondFinishedRequestForCetriko
    // const canRespondCervera = currentUser?.CanRespondFinishedRequestForCervera

    // const isC = apiData.RequestArticle?.HistoryArticle?.IsFromCetriko
    // const isQ = apiData.RequestArticle?.HistoryArticle?.IsFromCervera

    // if (isC && !isQ) {
    //   articleEnterprice = 'C'
    // } else if (!isC && isQ) {
    //   articleEnterprice = 'Q'
    // } else {
    //   articleEnterprice = 'C + Q'
    // }

    // if (userRol === 1) {
    //   if (
    //     articleEnterprice === 'C + Q' &&
    //     (canRespondCetriko || canRespondCervera)
    //   ) {
    //     allow = true
    //   } else if (articleEnterprice === 'C' && canRespondCetriko) {
    //     allow = true
    //   } else if (articleEnterprice === 'Q' && canRespondCervera) {
    //     allow = true
    //   } else {
    //     allow = false
    //   }
    // } else {
    //   allow = false
    // }

    // if (!allow) {
    //   if (requestArticleClientManagerArray.includes(user.Id.toString())) {
    //     allow = true
    //   }
    // }

    return allow
  }

  const buildRequestArticle = (apiData) => {
    const requestArticle = {
      RequestArticleId: 0,
      ArticleId: apiData?.Id,
      // --------------------------------------------------------------------------------------
      // Descripció
      SemiFinished: apiData?.SemiFinished ?? '',
      SemiFinishedLong: apiData?.SemiFinishedLong ?? '',
      SecurityStep: apiData?.SecurityStep ?? false,
      SecurityStepDescription: apiData?.SecurityStepDescription ?? '',
      Is3D: apiData?.Is3D ?? false,
      Press3D: apiData?.Press3D ?? '',
      EncryptedCode: apiData?.EncryptedCode ?? '',
      TiedDescription: apiData?.TiedDescription ?? '',
      Repetitions: Number(
        apiData?.Repetitions?.replace(/,/g, '.') ?? 0
      ).toFixed(apiData?.Repetitions % 1 === 0 ? 0 : 2), // Ideal
      RepetitionsV2: Number(
        apiData?.RepetitionsV2?.replace(/,/g, '.') ?? 0
      ).toFixed(apiData?.RepetitionsV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
      RepetitionsTolerance: Number(
        apiData?.RepetitionsTolerance?.replace(/,/g, '.') ?? '1'
      ).toFixed(apiData?.RepetitionsTolerance % 1 === 0 ? 0 : 2), // Ideal
      RepetitionsToleranceV2: Number(
        apiData?.RepetitionsToleranceV2?.replace(/,/g, '.') ?? '1'
      ).toFixed(apiData?.RepetitionsToleranceV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
      Columns: Number(apiData?.Columns?.replace(/,/g, '.') ?? 0).toFixed(
        apiData?.Columns % 1 === 0 ? 0 : 2
      ), // Ideal
      ColumnsV2: Number(apiData?.ColumnsV2?.replace(/,/g, '.') ?? 0).toFixed(
        apiData?.ColumnsV2 % 1 === 0 ? 0 : 2
      ), // Tol. Màx.
      ColumnsTolerance: Number(
        apiData?.ColumnsTolerance?.replace(/,/g, '.') ?? '1'
      ).toFixed(apiData?.ColumnsTolerance % 1 === 0 ? 0 : 2), // Ideal
      ColumnsToleranceV2: Number(
        apiData?.ColumnsToleranceV2?.replace(/,/g, '.') ?? '1'
      ).toFixed(apiData?.ColumnsToleranceV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
      Weight: Number(apiData?.Weight?.replace(/,/g, '.') ?? 0).toFixed(
        apiData?.Weight % 1 === 0 ? 0 : 2
      ), // Ideal
      WeightV2: Number(apiData?.WeightV2?.replace(/,/g, '.') ?? 0).toFixed(
        apiData?.WeightV2 % 1 === 0 ? 0 : 2
      ), // Tol. Màx.
      WeightTolerance: Number(
        apiData?.WeightTolerance?.replace(/,/g, '.') ?? '3'
      ).toFixed(apiData?.WeightTolerance % 1 === 0 ? 0 : 2), // Ideal
      WeightToleranceV2: Number(
        apiData?.WeightToleranceV2?.replace(/,/g, '.') ?? '3'
      ).toFixed(apiData?.WeightToleranceV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
      Width: Number(apiData?.Width?.replace(/,/g, '.') ?? 0).toFixed(
        apiData?.Width % 1 === 0 ? 0 : 2
      ), // Ideal
      WidthV2: Number(apiData?.WidthV2?.replace(/,/g, '.') ?? 0).toFixed(
        apiData?.WidthV2 % 1 === 0 ? 0 : 2
      ), // Tol. Màx.
      WidthTolerance: Number(
        apiData?.WidthTolerance?.replace(/,/g, '.') ?? '3'
      ).toFixed(apiData?.WidthTolerance % 1 === 0 ? 0 : 2), // Ideal
      WidthToleranceV2: Number(
        apiData?.WidthToleranceV2?.replace(/,/g, '.') ?? '3'
      ).toFixed(apiData?.WidthToleranceV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
      Thickness: Number(apiData?.Thickness?.replace(/,/g, '.') ?? 0).toFixed(
        apiData?.Thickness % 1 === 0 ? 0 : 2
      ), // Ideal
      ThicknessV2: Number(
        apiData?.ThicknessV2?.replace(/,/g, '.') ?? 0
      ).toFixed(apiData?.ThicknessV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
      ThicknessTolerance: Number(
        apiData?.ThicknessTolerance?.replace(/,/g, '.') ?? '0.02'
      ).toFixed(apiData?.ThicknessTolerance % 1 === 0 ? 0 : 2), // Ideal
      ThicknessToleranceV2: Number(
        apiData?.ThicknessToleranceV2?.replace(/,/g, '.') ?? '0.02'
      ).toFixed(apiData?.ThicknessToleranceV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
      RDTNominal: Number(apiData?.RDTNominal?.replace(/,/g, '.') ?? 0).toFixed(
        apiData?.RDTNominal % 1 === 0 ? 0 : 2
      ), // Ideal
      RDTNominalV2: Number(
        apiData?.RDTNominalV2?.replace(/,/g, '.') ?? 0
      ).toFixed(apiData?.RDTNominalV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
      RDTNominalTolerance: Number(
        apiData?.RDTNominalTolerance?.replace(/,/g, '.') ?? 0
      ).toFixed(apiData?.RDTNominalTolerance % 1 === 0 ? 0 : 2), // Ideal
      RDTNominalToleranceV2: Number(
        apiData?.RDTNominalToleranceV2?.replace(/,/g, '.') ?? 0
      ).toFixed(apiData?.RDTNominalToleranceV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
      LaboratoryComment: apiData?.LaboratoryComment ?? '',
      Machine: apiData?.Machine ?? '',
      MachineCode: apiData?.MachineCode ?? '',
      FoldType: apiData?.FoldType ?? '',
      GG: apiData?.GG ?? '',
      O: apiData?.O ?? '',
      NGame: apiData?.NGame ?? '',
      Needle: apiData?.Needle ?? '',
      RpmMax: apiData?.RpmMax ?? '',
      Rpm: Number(apiData?.Rpm?.toString().replace(/,/g, '.') ?? 0).toFixed(
        apiData?.Rpm % 1 === 0 ? 0 : 1
      ),
      RpmMin: Number(
        apiData?.RpmMin?.toString().replace(/,/g, '.') ?? 0
      ).toFixed(apiData?.RpmMin % 1 === 0 ? 0 : 1),
      TurnsPerKg: Math.round(
        Number(apiData?.TurnsPerKg?.replace(/,/g, '.') ?? 0)
      ),
      TurnsPerKgTolerance: Math.round(
        Number(apiData?.TurnsPerKgTolerance ?? '2')
      ),
      TurnsPerPart: Math.round(
        Number(apiData?.TurnsPerPart?.replace(/,/g, '.') ?? 0)
      ),
      TurnsPerPartByOperator: Math.round(
        Number(apiData?.TurnsPerPartByOperator?.replace(/,/g, '.') ?? 0)
      ),
      TurnsPerPartByOperatorTolerance: Math.round(
        Number(apiData?.TurnsPerPartByOperatorTolerance ?? '100')
      ),
      KgH_100: apiData?.KgH_100 ?? '',
      KgH_80: apiData?.KgH_80 ?? '',
      KgD_100: apiData?.KgD_100 ?? '',
      KgD_80: apiData?.KgD_80 ?? '',
      KgPart: Number(apiData?.KgPart?.replace(/,/g, '.') ?? 0).toFixed(
        apiData?.KgPart % 1 === 0 ? 0 : 1
      ),
      KgPartTolerance: Number(
        apiData?.KgPartTolerance?.replace(/,/g, '.') ?? '2'
      ).toFixed(apiData?.KgPartTolerance % 1 === 0 ? 0 : 1),
      // --------------------------------------------------------------------------------------
      // Alçada i sincronisme
      Synchronization: apiData?.Synchronization ?? '',
      Formation: apiData?.Formation ?? '',
      DishHeight1: apiData?.DishHeight1 ?? '',
      DishHeight2: apiData?.DishHeight2 ?? '',
      DishDisplacement1: apiData?.DishDisplacement1 ?? '',
      DishDisplacement2: apiData?.DishDisplacement2 ?? '',
      // --------------------------------------------------------------------------------------
      // Pinyons / Politja
      Piston1: apiData?.Piston1 ?? '',
      Piston2: apiData?.Piston2 ?? '',
      Piston3: apiData?.Piston3 ?? '',
      Piston4: apiData?.Piston4 ?? '',
      Piston5: apiData?.Piston5 ?? '',
      Piston6: apiData?.Piston6 ?? '',
      Piston7: apiData?.Piston7 ?? '',
      Piston8: apiData?.Piston8 ?? '',
      Piston9: apiData?.Piston9 ?? '',
      Piston10: apiData?.Piston10 ?? '',
      JUM1: apiData?.JUM1 ?? '',
      JUM2: apiData?.JUM2 ?? '',
      JUM3: apiData?.JUM3 ?? '',
      JUM4: apiData?.JUM4 ?? '',
      PistonTolerance1: apiData?.PistonTolerance1 ?? '',
      PistonTolerance2: apiData?.PistonTolerance2 ?? '',
      PistonTolerance3: apiData?.PistonTolerance3 ?? '',
      PistonTolerance4: apiData?.PistonTolerance4 ?? '',
      // --------------------------------------------------------------------------------------
      // Graduació del punt i consum
      CylinderHeight1: apiData?.CylinderHeight1 ?? '',
      CylinderHeight2: apiData?.CylinderHeight2 ?? '',
      PositionTurntables: apiData?.PositionTurntables ?? '',
      PositionGuiafils: apiData?.PositionGuiafils ?? '',
      // --------------------------------------------------------------------------------------
      // Plegador / Calandra
      Calandra: apiData?.Calandra ?? '',
      Delta: apiData?.Delta ?? '',
      FolderingPiston1: apiData?.FolderingPiston1 ?? '',
      FolderingPiston2: apiData?.FolderingPiston2 ?? '',
      FolderingPiston3: apiData?.FolderingPiston3 ?? '',
      FolderingPiston4: apiData?.FolderingPiston4 ?? '',
      FolderTension1: apiData?.FolderTension1 ?? '',
      FolderTension1_v2: apiData?.FolderTension1_v2 ?? '',
      FolderTension2: apiData?.FolderTension2 ?? '',
      FolderTension2_v2: apiData?.FolderTension2_v2 ?? '',
      FolderTension3: apiData?.FolderTension3 ?? '',
      FolderTension4: apiData?.FolderTension4 ?? '',
      P1: apiData?.P1 ?? '',
      P2: apiData?.P2 ?? '',
      P3: apiData?.P3 ?? '',
      PistonJUM: apiData?.PistonJUM ?? '',
      PistonJUM_v2: apiData?.PistonJUM_v2 ?? '',
      PistonJUM2: apiData?.PistonJUM2 ?? '',
      PistonJUM2_v2: apiData?.PistonJUM2_v2 ?? '',
      RollingRoller1: apiData?.RollingRoller1 ?? '',
      RollingRoller1_v2: apiData?.RollingRoller1_v2 ?? '',
      RollingRoller2: apiData?.RollingRoller2 ?? '',
      RollingRoller2_v2: apiData?.RollingRoller2_v2 ?? '',
      RollingRoller3: apiData?.RollingRoller3 ?? '',
      RollingRoller4: apiData?.RollingRoller4 ?? '',
      TensionMayer: apiData?.TensionMayer ?? '',
      PositionMonarch: apiData?.PositionMonarch ?? '',
      PositionTerrot1: apiData?.PositionTerrot1 ?? '',
      PositionTerrot2: apiData?.PositionTerrot2 ?? '',
      // --------------------------------------------------------------------------------------
      // Observacions / Notes de fitxa tècnica de teixiduria
      DestacableText: apiData?.DestacableText ?? '',
      Obseravtion: apiData?.Obseravtion ?? '',
      Edition: apiData?.Edition ?? '',
      EditionDate: apiData?.EditionDate ?? '',
      UserDone: apiData?.UserDone ?? '',
      UserApproved: apiData?.UserApproved ?? '',
      PassTensiometer: apiData?.PassTensiometer ?? false,
      // --------------------------------------------------------------------------------------
      // History Article
      HistoryArticle: {
        HistoryArticleId: 0,
        ArticleId: apiData?.Id ?? 0,
        // --------------------------------------------------------------------------------------
        // Descripció
        SemiFinished: apiData?.SemiFinished ?? '',
        SemiFinishedLong: apiData?.SemiFinishedLong ?? '',
        SecurityStep: apiData?.SecurityStep ?? false,
        SecurityStepDescription: apiData?.SecurityStepDescription ?? '',
        Is3D: apiData?.Is3D ?? false,
        Press3D: apiData?.Press3D ?? '',
        EncryptedCode: apiData?.EncryptedCode ?? '',
        TiedDescription: apiData?.TiedDescription ?? '',
        Repetitions: Number(
          apiData?.Repetitions?.replace(/,/g, '.') ?? 0
        ).toFixed(apiData?.Repetitions % 1 === 0 ? 0 : 2), // Ideal
        RepetitionsV2: Number(
          apiData?.RepetitionsV2?.replace(/,/g, '.') ?? 0
        ).toFixed(apiData?.RepetitionsV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
        RepetitionsTolerance: Number(
          apiData?.RepetitionsTolerance?.replace(/,/g, '.') ?? '1'
        ).toFixed(apiData?.RepetitionsTolerance % 1 === 0 ? 0 : 2), // Ideal
        RepetitionsToleranceV2: Number(
          apiData?.RepetitionsToleranceV2?.replace(/,/g, '.') ?? '1'
        ).toFixed(apiData?.RepetitionsToleranceV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
        Columns: Number(apiData?.Columns?.replace(/,/g, '.') ?? 0).toFixed(
          apiData?.Columns % 1 === 0 ? 0 : 2
        ), // Ideal
        ColumnsV2: Number(apiData?.ColumnsV2?.replace(/,/g, '.') ?? 0).toFixed(
          apiData?.ColumnsV2 % 1 === 0 ? 0 : 2
        ), // Tol. Màx.
        ColumnsTolerance: Number(
          apiData?.ColumnsTolerance?.replace(/,/g, '.') ?? '1'
        ).toFixed(apiData?.ColumnsTolerance % 1 === 0 ? 0 : 2), // Ideal
        ColumnsToleranceV2: Number(
          apiData?.ColumnsToleranceV2?.replace(/,/g, '.') ?? '1'
        ).toFixed(apiData?.ColumnsToleranceV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
        Weight: Number(apiData?.Weight?.replace(/,/g, '.') ?? 0).toFixed(
          apiData?.Weight % 1 === 0 ? 0 : 2
        ), // Ideal
        WeightV2: Number(apiData?.WeightV2?.replace(/,/g, '.') ?? 0).toFixed(
          apiData?.WeightV2 % 1 === 0 ? 0 : 2
        ), // Tol. Màx.
        WeightTolerance: Number(
          apiData?.WeightTolerance?.replace(/,/g, '.') ?? '3'
        ).toFixed(apiData?.WeightTolerance % 1 === 0 ? 0 : 2), // Ideal
        WeightToleranceV2: Number(
          apiData?.WeightToleranceV2?.replace(/,/g, '.') ?? '3'
        ).toFixed(apiData?.WeightToleranceV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
        Width: Number(apiData?.Width?.replace(/,/g, '.') ?? 0).toFixed(
          apiData?.Width % 1 === 0 ? 0 : 2
        ), // Ideal
        WidthV2: Number(apiData?.WidthV2?.replace(/,/g, '.') ?? 0).toFixed(
          apiData?.WidthV2 % 1 === 0 ? 0 : 2
        ), // Tol. Màx.
        WidthTolerance: Number(
          apiData?.WidthTolerance?.replace(/,/g, '.') ?? '3'
        ).toFixed(apiData?.WidthTolerance % 1 === 0 ? 0 : 2), // Ideal
        WidthToleranceV2: Number(
          apiData?.WidthToleranceV2?.replace(/,/g, '.') ?? '3'
        ).toFixed(apiData?.WidthToleranceV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
        Thickness: Number(apiData?.Thickness?.replace(/,/g, '.') ?? 0).toFixed(
          apiData?.Thickness % 1 === 0 ? 0 : 2
        ), // Ideal
        ThicknessV2: Number(
          apiData?.ThicknessV2?.replace(/,/g, '.') ?? 0
        ).toFixed(apiData?.ThicknessV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
        ThicknessTolerance: Number(
          apiData?.ThicknessTolerance?.replace(/,/g, '.') ?? '0.02'
        ).toFixed(apiData?.ThicknessTolerance % 1 === 0 ? 0 : 2), // Ideal
        ThicknessToleranceV2: Number(
          apiData?.ThicknessToleranceV2?.replace(/,/g, '.') ?? '0.02'
        ).toFixed(apiData?.ThicknessToleranceV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
        RDTNominal: Number(
          apiData?.RDTNominal?.replace(/,/g, '.') ?? 0
        ).toFixed(apiData?.RDTNominal % 1 === 0 ? 0 : 2), // Ideal
        RDTNominalV2: Number(
          apiData?.RDTNominalV2?.replace(/,/g, '.') ?? 0
        ).toFixed(apiData?.RDTNominalV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
        RDTNominalTolerance: Number(
          apiData?.RDTNominalTolerance?.replace(/,/g, '.') ?? 0
        ).toFixed(apiData?.RDTNominalTolerance % 1 === 0 ? 0 : 2), // Ideal
        RDTNominalToleranceV2: Number(
          apiData?.RDTNominalToleranceV2?.replace(/,/g, '.') ?? 0
        ).toFixed(apiData?.RDTNominalToleranceV2 % 1 === 0 ? 0 : 2), // Tol. Màx.
        LaboratoryComment: apiData?.LaboratoryComment ?? '',
        Machine: apiData?.Machine ?? '',
        MachineCode: apiData?.MachineCode ?? '',
        FoldType: apiData?.FoldType ?? '',
        GG: apiData?.GG ?? '',
        O: apiData?.O ?? '',
        NGame: apiData?.NGame ?? '',
        Needle: apiData?.Needle ?? '',
        RpmMax: apiData?.RpmMax ?? '',
        Rpm: Number(apiData?.Rpm?.toString().replace(/,/g, '.') ?? 0).toFixed(
          apiData?.Rpm % 1 === 0 ? 0 : 1
        ),
        RpmMin: Number(
          apiData?.RpmMin?.toString().replace(/,/g, '.') ?? 0
        ).toFixed(apiData?.RpmMin % 1 === 0 ? 0 : 1),
        TurnsPerKg: Math.round(
          Number(apiData?.TurnsPerKg?.replace(/,/g, '.') ?? 0)
        ),
        TurnsPerKgTolerance: Math.round(
          Number(apiData?.TurnsPerKgTolerance ?? '2')
        ),
        TurnsPerPart: Math.round(
          Number(apiData?.TurnsPerPart?.replace(/,/g, '.') ?? 0)
        ),
        TurnsPerPartByOperator: Math.round(
          Number(apiData?.TurnsPerPartByOperator?.replace(/,/g, '.') ?? 0)
        ),
        TurnsPerPartByOperatorTolerance: Math.round(
          Number(apiData?.TurnsPerPartByOperatorTolerance ?? '100')
        ),
        KgH_100: apiData?.KgH_100 ?? '',
        KgH_80: apiData?.KgH_80 ?? '',
        KgD_100: apiData?.KgD_100 ?? '',
        KgD_80: apiData?.KgD_80 ?? '',
        KgPart: Number(apiData?.KgPart?.replace(/,/g, '.') ?? 0).toFixed(
          apiData?.KgPart % 1 === 0 ? 0 : 1
        ),
        KgPartTolerance: Number(
          apiData?.KgPartTolerance?.replace(/,/g, '.') ?? '2'
        ).toFixed(apiData?.KgPartTolerance % 1 === 0 ? 0 : 1),
        // --------------------------------------------------------------------------------------
        // Alçada i sincronisme
        Synchronization: apiData?.Synchronization ?? '',
        Formation: apiData?.Formation ?? '',
        DishHeight1: apiData?.DishHeight1 ?? '',
        DishHeight2: apiData?.DishHeight2 ?? '',
        DishDisplacement1: apiData?.DishDisplacement1 ?? '',
        DishDisplacement2: apiData?.DishDisplacement2 ?? '',
        // --------------------------------------------------------------------------------------
        // Pinyons / Politja
        Piston1: apiData?.Piston1 ?? '',
        Piston2: apiData?.Piston2 ?? '',
        Piston3: apiData?.Piston3 ?? '',
        Piston4: apiData?.Piston4 ?? '',
        Piston5: apiData?.Piston5 ?? '',
        Piston6: apiData?.Piston6 ?? '',
        Piston7: apiData?.Piston7 ?? '',
        Piston8: apiData?.Piston8 ?? '',
        Piston9: apiData?.Piston9 ?? '',
        Piston10: apiData?.Piston10 ?? '',
        JUM1: apiData?.JUM1 ?? '',
        JUM2: apiData?.JUM2 ?? '',
        JUM3: apiData?.JUM3 ?? '',
        JUM4: apiData?.JUM4 ?? '',
        PistonTolerance1: apiData?.PistonTolerance1 ?? '',
        PistonTolerance2: apiData?.PistonTolerance2 ?? '',
        PistonTolerance3: apiData?.PistonTolerance3 ?? '',
        PistonTolerance4: apiData?.PistonTolerance4 ?? '',
        // --------------------------------------------------------------------------------------
        // Graduació del punt i consum
        CylinderHeight1: apiData?.CylinderHeight1 ?? '',
        CylinderHeight2: apiData?.CylinderHeight2 ?? '',
        PositionTurntables: apiData?.PositionTurntables ?? '',
        PositionGuiafils: apiData?.PositionGuiafils ?? '',
        // --------------------------------------------------------------------------------------
        // Plegador / Calandra
        Calandra: apiData?.Calandra ?? '',
        Delta: apiData?.Delta ?? '',
        FolderingPiston1: apiData?.FolderingPiston1 ?? '',
        FolderingPiston2: apiData?.FolderingPiston2 ?? '',
        FolderingPiston3: apiData?.FolderingPiston3 ?? '',
        FolderingPiston4: apiData?.FolderingPiston4 ?? '',
        FolderTension1: apiData?.FolderTension1 ?? '',
        FolderTension1_v2: apiData?.FolderTension1_v2 ?? '',
        FolderTension2: apiData?.FolderTension2 ?? '',
        FolderTension2_v2: apiData?.FolderTension2_v2 ?? '',
        FolderTension3: apiData?.FolderTension3 ?? '',
        FolderTension4: apiData?.FolderTension4 ?? '',
        P1: apiData?.P1 ?? '',
        P2: apiData?.P2 ?? '',
        P3: apiData?.P3 ?? '',
        PistonJUM: apiData?.PistonJUM ?? '',
        PistonJUM_v2: apiData?.PistonJUM_v2 ?? '',
        PistonJUM2: apiData?.PistonJUM2 ?? '',
        PistonJUM2_v2: apiData?.PistonJUM2_v2 ?? '',
        RollingRoller1: apiData?.RollingRoller1 ?? '',
        RollingRoller1_v2: apiData?.RollingRoller1_v2 ?? '',
        RollingRoller2: apiData?.RollingRoller2 ?? '',
        RollingRoller2_v2: apiData?.RollingRoller2_v2 ?? '',
        RollingRoller3: apiData?.RollingRoller3 ?? '',
        RollingRoller4: apiData?.RollingRoller4 ?? '',
        TensionMayer: apiData?.TensionMayer ?? '',
        PositionMonarch: apiData?.PositionMonarch ?? '',
        PositionTerrot1: apiData?.PositionTerrot1 ?? '',
        PositionTerrot2: apiData?.PositionTerrot2 ?? '',
        // --------------------------------------------------------------------------------------
        // Observacions / Notes de fitxa tècnica de teixiduria
        DestacableText: apiData?.DestacableText ?? '',
        Obseravtion: apiData?.Obseravtion ?? '',
        Edition: apiData?.Edition ?? '',
        EditionDate: apiData?.EditionDate ?? '',
        UserDone: apiData?.UserDone ?? '',
        UserApproved: apiData?.UserApproved ?? '',
        PassTensiometer: apiData?.PassTensiometer ?? false
      }
    }
    console.log('🚀 ~ buildRequestArticle ~ requestArticle:', requestArticle)

    return requestArticle
  }

  const buildRequestMachineGroups = (apiData) => {
    const requestMachineGroups = []

    for (const machineGroup of apiData?.TSWeavingMachineGroups) {
      requestMachineGroups.push({
        RequestMachineGroupId: 0,
        MachineGroupId: machineGroup?.Id ?? 0,
        // --------------------------------------------------------------------------------------
        IdThread: machineGroup?.IdThread ?? 0,
        ThreadMatter: machineGroup?.ThreadMatter ?? '',
        Spending: machineGroup?.Spending ?? 0,
        TolSpending: machineGroup?.TolSpending ?? '',
        Size: machineGroup?.Size ?? '',
        Pressure: machineGroup?.Pressure ?? '',
        Game: machineGroup?.Game ?? '',
        NoniusC: machineGroup?.NoniusC ?? 0,
        NoniusP: machineGroup?.NoniusP ?? 0,
        LFA: machineGroup?.LFA ?? 0,
        KgH: machineGroup?.KgH ?? 0,
        Cones: machineGroup?.Cones ?? 0,
        TSWeavingID: machineGroup?.TSWeavingID ?? 0,
        GameNumber: machineGroup?.GameNumber ?? '',
        Heads: machineGroup?.Heads ?? 0,
        Title: machineGroup?.Title ?? '',
        IntMachineGroup: machineGroup?.IntMachineGroup ?? 0,
        // --------------------------------------------------------------------------------------
        // History MachineGroup
        HistoryMachineGroup: {
          HistoryMachineGroupId: 0,
          MachineGroupId: machineGroup?.Id ?? 0,
          // --------------------------------------------------------------------------------------
          IdThread: machineGroup?.IdThread ?? 0,
          ThreadMatter: machineGroup?.ThreadMatter ?? '',
          Spending: machineGroup?.Spending ?? 0,
          TolSpending: machineGroup?.TolSpending ?? '',
          Size: machineGroup?.Size ?? '',
          Pressure: machineGroup?.Pressure ?? '',
          Game: machineGroup?.Game ?? '',
          NoniusC: machineGroup?.NoniusC ?? 0,
          NoniusP: machineGroup?.NoniusP ?? 0,
          LFA: machineGroup?.LFA ?? 0,
          KgH: machineGroup?.KgH ?? 0,
          Cones: machineGroup?.Cones ?? 0,
          TSWeavingID: machineGroup?.TSWeavingID ?? 0,
          GameNumber: machineGroup?.GameNumber ?? '',
          Heads: machineGroup?.Heads ?? 0,
          Title: machineGroup?.Title ?? '',
          IntMachineGroup: machineGroup?.IntMachineGroup ?? 0
        }
      })
    }

    return requestMachineGroups
  }

  const buildRequestThreads = (apiData) => {
    const requestThreads = []

    for (const thread of apiData?.TSWeavingThreads) {
      requestThreads.push({
        RequestThreadId: 0,
        ThreadId: thread?.Id ?? 0,
        // --------------------------------------------------------------------------------------
        Thread: thread?.Thread ?? '',
        ThreadNumber: thread?.ThreadNumber ?? '',
        ThreadMatter: thread?.ThreadMatter ?? '',
        NCones: thread?.NCones ?? '',
        Percentage: thread?.Percentage ?? '',
        TSWeavingID: thread?.TSWeavingID ?? 0,
        ThreadTitle: thread?.ThreadTitle ?? '',
        GRS: thread?.GRS ?? false,
        TextGRS: thread?.TextGRS ?? '',
        HasBeenRemoved: thread?.HasBeenRemoved ?? 0,
        // --------------------------------------------------------------------------------------
        // History Thread
        HistoryThread: {
          HistoryThreadId: 0,
          ThreadId: thread?.Id ?? 0,
          // --------------------------------------------------------------------------------------
          Thread: thread?.Thread ?? '',
          ThreadNumber: thread?.ThreadNumber ?? '',
          ThreadMatter: thread?.ThreadMatter ?? '',
          NCones: thread?.NCones ?? '',
          Percentage: thread?.Percentage ?? '',
          TSWeavingID: thread?.TSWeavingID ?? 0,
          ThreadTitle: thread?.ThreadTitle ?? '',
          GRS: thread?.GRS ?? false,
          TextGRS: thread?.TextGRS ?? ''
        }
      })
    }

    return requestThreads
  }

  // Post
  const handleSubmit = (values) => {
    const preparedValues = JSON.parse(JSON.stringify(values))

    const apiCallPost = {
      url: 'tsweaving/request',
      method: 'POST',
      body: preparedValues,
      messageKo: `Error en la sol·licitud!`
    }
    setApiCallPost(apiCallPost)
  }

  // Put
  const handleUpdate = (values) => {
    const preparedValues = JSON.parse(JSON.stringify(values))
    preparedValues.ResponderId = user.Id

    console.log('TsWeaving Post')

    const apiCallPost = {
      url: `tsweaving/request-action/${
        submitActionRef.current === 'A' ? 'approve' : 'deny'
      }`,
      method: 'PUT',
      body: preparedValues,
      messageKo: `Error en la sol·licitud!`
    }
    setApiCallPost(apiCallPost)
  }

  // Get post/put response
  useEffect(() => {
    if (getApiCallPost.data) {
      setLocation(
        isViewScreen
          ? `/ft-teixiduria/requests/${params.id}`
          : `/ft-teixiduria/${params.id}`
      )
    }
  }, [getApiCallPost.data])

  useEffect(() => {
    console.log('Campos request en useEffect: ', getRequestFieldsState)
  }, [getRequestFieldsState])

  return (
    <>
      {getRequestFieldsState.RequestArticle !== null &&
      getRequestFieldsState.RequestRams !== null &&
      users ? (
        <Formik
          enableReinitialize={true}
          validationSchema={
            isViewScreen ? validationSchema2 : validationSchema1
          }
          initialValues={getRequestFieldsState}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true)
            if (isViewScreen) {
              handleUpdate(values)
            } else {
              handleSubmit(values)
            }
          }}
        >
          {({
            values,
            setFieldValue,
            errors,
            touched,
            props,
            handleBlur,
            handleChange,
            submitForm,
            isSubmitting
          }) => (
            <Form className="relative grid grid-flow-row grid-cols-12 p-4 gap-y-2 gap-x-4 auto-rows-max">
              {/* MOTIU */}
              <div className="flex flex-col col-span-12 bg-white border-2 border-blue-200 rounded shadow-md">
                <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                  MOTIU
                </div>

                <div className="p-3">
                  {isViewScreen ? (
                    <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                      <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                        {values?.Reason}
                      </div>
                    </div>
                  ) : (
                    <>
                      <Field
                        type="text"
                        name="Reason"
                        className={`w-full px-2 py-1 leading-tight border rounded-sm appearance-none
                          hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline ${
                            errors.Reason
                              ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                              : 'border-gray-300 bg-white hover:border-gray-400 focus:border-gray-400'
                          }`}
                      />
                      <ErrorMessage
                        name="Reason"
                        render={(message) => (
                          <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                            {message}
                          </span>
                        )}
                      />{' '}
                    </>
                  )}
                </div>
              </div>

              <div className="col-span-12">
                <hr
                  className={`w-3/4 h-1 mx-auto mt-12 
                      ${
                        isViewScreen
                          ? values.State === 'P'
                            ? canRespond
                              ? 'bg-gray-100'
                              : 'bg-amber-500'
                            : values.State === 'A'
                            ? 'bg-teal-500'
                            : 'bg-red-500'
                          : 'bg-gray-100'
                      } border-0 rounded`}
                />
              </div>

              {isViewScreen ? (
                values.State === 'P' ? (
                  canRespond ? (
                    <>
                      <div className="flex justify-end col-span-6 mt-6 mb-3">
                        <button
                          title="Acceptar"
                          type="submit"
                          className={`text-teal-500 bg-white hover:bg-teal-500 border-2 border-teal-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                          onClick={() => (submitActionRef.current = 'A')}
                          disabled={isSubmitting}
                        >
                          <FaCheck className="xl:mr-2" size={25} />
                          Acceptar
                        </button>
                      </div>

                      <div className="flex justify-start col-span-6 mt-6 mb-3">
                        <button
                          title="Denegar"
                          type="submit"
                          className={`text-red-500 bg-white hover:bg-red-500 border-2 border-red-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                          onClick={() => (submitActionRef.current = 'D')}
                          disabled={isSubmitting}
                        >
                          <FaTimes className="xl:mr-2" size={25} />
                          Denegar
                        </button>
                      </div>

                      {/* COMMENT */}
                      <div className="flex flex-col col-start-3 col-end-11 mt-3 mb-6 bg-white border-2 border-blue-200 rounded shadow-md">
                        <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                          Comentari del revisor
                        </div>
                        <div className="p-3">
                          <Field
                            type="text"
                            name="ResponderComment"
                            className={`w-full px-2 py-1 leading-tight border rounded-sm appearance-none
                              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline ${
                                errors.ResponderComment
                                  ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                                  : 'border-gray-300 bg-white hover:border-gray-400 focus:border-gray-400'
                              }`}
                          />
                          <ErrorMessage
                            name="ResponderComment"
                            render={(message) => (
                              <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                                {message}
                              </span>
                            )}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flex justify-center col-span-12 my-6">
                      <div className="cursor-default text-amber-500 transition duration-300 text-md uppercase font-ms-bold px-5 py-2.5 text-center inline-flex items-center">
                        <FaRegClock className="xl:mr-2" size={25} />
                        Pendent
                      </div>
                    </div>
                  )
                ) : values.State === 'A' ? (
                  <>
                    <div className="flex justify-center col-span-12 mt-6 mb-3">
                      <div className="cursor-default text-teal-500 transition duration-300 text-md uppercase font-ms-bold px-5 py-2.5 text-center inline-flex items-center">
                        <FaCheck className="xl:mr-2" size={25} />
                        Acceptada
                      </div>
                    </div>

                    {/* COMMENT */}
                    <div className="flex flex-col col-start-3 col-end-11 mt-3 mb-6 bg-white border-2 border-blue-200 rounded shadow-md">
                      <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                        Comentari del revisor
                      </div>
                      <div className="p-3">
                        <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                          <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                            {values?.ResponderComment}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-center col-span-12 mt-6 mb-3">
                      <div className="cursor-default text-red-500 transition duration-300 text-md uppercase font-ms-bold px-5 py-2.5 text-center inline-flex items-center">
                        <FaTimes className="xl:mr-2" size={25} />
                        Denegada
                      </div>
                    </div>

                    {/* COMMENT */}
                    <div className="flex flex-col col-start-3 col-end-11 mt-3 mb-6 bg-white border-2 border-blue-200 rounded shadow-md">
                      <div className="flex items-center justify-start w-full py-2 pl-2 text-sm uppercase bg-blue-200 font-ms-bold">
                        Comentari resposta
                      </div>
                      <div className="p-3">
                        <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed print:col-span-9 lg:col-span-8 xl:col-span-9">
                          <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis">
                            {values?.ResponderComment}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : (
                <div className="flex items-start justify-center col-span-12 mt-12 mb-10 space-x-2 uppercase">
                  <button
                    title="Sol·licitar"
                    type="submit"
                    className={`text-teal-500 bg-white hover:bg-teal-500 border-2 border-teal-500 transition duration-300 rounded text-md uppercase hover:text-white font-ms-bold px-5 py-2.5 text-center inline-flex items-center`}
                    disabled={isSubmitting}
                  >
                    <MdEdit className="xl:mr-2" size={25} />
                    Sol·licitar
                  </button>
                </div>
              )}

              <div className="col-span-12">
                <hr
                  className={`w-3/4 h-1 mx-auto mb-12 
                      ${
                        isViewScreen
                          ? values.State === 'P'
                            ? canRespond
                              ? 'bg-gray-100'
                              : 'bg-amber-500'
                            : values.State === 'A'
                            ? 'bg-teal-500'
                            : 'bg-red-500'
                          : 'bg-gray-100'
                      } border-0 rounded`}
                />
              </div>

              {isViewScreen ? (
                <>
                  {/* DADES ACTUALS | DESCRIPCIÓ */}
                  <BlockVersion3
                    title="Descripció"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    side="left"
                    isAnswered={getIsAnswered}
                    valuesBefore={values.RequestArticle.HistoryArticle}
                    valuesAfter={values.RequestArticle}
                    threads={values.RequestThreads}
                  />

                  {/* DADES SOL·LICITUD | DESCRIPCIÓ */}
                  <BlockVersion3
                    title="Descripció"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    side="right"
                    isAnswered={getIsAnswered}
                    valuesBefore={values.RequestArticle.HistoryArticle}
                    valuesAfter={values.RequestArticle}
                    threads={values.RequestThreads}
                  />

                  {/* DADES ACTUALS | PLEGADOR / CALANDRA */}
                  <BlockVersion3
                    title="Plegador / Calandra"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    side="left"
                    isAnswered={getIsAnswered}
                    valuesBefore={values.RequestArticle.HistoryArticle}
                    valuesAfter={values.RequestArticle}
                  />

                  {/* DADES SOL·LICITUD | PLEGADOR / CALANDRA */}
                  <BlockVersion3
                    title="Plegador / Calandra"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    side="right"
                    isAnswered={getIsAnswered}
                    valuesBefore={values.RequestArticle.HistoryArticle}
                    valuesAfter={values.RequestArticle}
                  />

                  {/* DADES ACTUALS | PINYONS / POLITJA */}
                  <BlockVersion3
                    title="Pinyons / Politja"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    side="left"
                    isAnswered={getIsAnswered}
                    valuesBefore={values.RequestArticle.HistoryArticle}
                    valuesAfter={values.RequestArticle}
                  />

                  {/* DADES SOL·LICITUD | PINYONS / POLITJA */}
                  <BlockVersion3
                    title="Pinyons / Politja"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    side="right"
                    isAnswered={getIsAnswered}
                    valuesBefore={values.RequestArticle.HistoryArticle}
                    valuesAfter={values.RequestArticle}
                  />

                  {/* DADES ACTUALS | GRADUACIÓ DEL PUNT I CONSUM */}
                  <BlockVersion3
                    title="Graduació del punt i consum"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    side="left"
                    isAnswered={getIsAnswered}
                    valuesBefore={values.RequestArticle.HistoryArticle}
                    valuesAfter={values.RequestArticle}
                    games={values.RequestMachineGroups}
                  />

                  {/* DADES SOL·LICITUD | GRADUACIÓ DEL PUNT I CONSUM */}
                  <BlockVersion3
                    title="Graduació del punt i consum"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    side="right"
                    isAnswered={getIsAnswered}
                    valuesBefore={values.RequestArticle.HistoryArticle}
                    valuesAfter={values.RequestArticle}
                    games={values.RequestMachineGroups}
                  />

                  {/* DADES ACTUALS | ALÇADA I SINCRONISME */}
                  <BlockVersion3
                    title="Alçada i sincronisme"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    side="left"
                    isAnswered={getIsAnswered}
                    valuesBefore={values.RequestArticle.HistoryArticle}
                    valuesAfter={values.RequestArticle}
                  />

                  {/* DADES SOL·LICITUD | ALÇADA I SINCRONISME */}
                  <BlockVersion3
                    title="Alçada i sincronisme"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    side="right"
                    isAnswered={getIsAnswered}
                    valuesBefore={values.RequestArticle.HistoryArticle}
                    valuesAfter={values.RequestArticle}
                  />

                  {/* DADES ACTUALS | OBSERVACIONS / NOTES DE FITXA TÈCNICA DE TEIXIDURIA */}
                  <BlockVersion3
                    title="Observacions / Notes de fitxa tècnica de teixiduria"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    side="left"
                    isAnswered={getIsAnswered}
                    valuesBefore={values.RequestArticle.HistoryArticle}
                    valuesAfter={values.RequestArticle}
                  />

                  {/* DADES SOL·LICITUD | OBSERVACIONS / NOTES DE FITXA TÈCNICA DE TEIXIDURIA */}
                  <BlockVersion3
                    title="Observacions / Notes de fitxa tècnica de teixiduria"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    side="right"
                    isAnswered={getIsAnswered}
                    valuesBefore={values.RequestArticle.HistoryArticle}
                    valuesAfter={values.RequestArticle}
                  />
                </>
              ) : (
                <>
                  {/* DADES ACTUALS | DESCRIPCIÓ */}
                  <BlockVersion1
                    title="Descripció"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    values={values.RequestArticle.HistoryArticle}
                    threads={values.RequestThreads}
                  />

                  {/* DADES SOL·LICITUD | DESCRIPCIÓ */}
                  <BlockVersion2
                    title="Descripció"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    setFieldValue={setFieldValue}
                    values={values.RequestArticle}
                    threads={values.RequestThreads}
                    setRequestFieldsState={setRequestFieldsState}
                    fullValues={values}
                  />

                  {/* DADES ACTUALS | PLEGADOR / CALANDRA */}
                  <BlockVersion1
                    title="Plegador / Calandra"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    values={values.RequestArticle.HistoryArticle}
                  />

                  {/* DADES SOL·LICITUD | PLEGADOR / CALANDRA */}
                  <BlockVersion2
                    title="Plegador / Calandra"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    values={values.RequestArticle}
                  />

                  {/* DADES ACTUALS | PINYONS / POLITJA */}
                  <BlockVersion1
                    title="Pinyons / Politja"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    values={values.RequestArticle.HistoryArticle}
                  />

                  {/* DADES SOL·LICITUD | PINYONS / POLITJA */}
                  <BlockVersion2
                    title="Pinyons / Politja"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    values={values.RequestArticle}
                  />

                  {/* DADES ACTUALS | GRADUACIÓ DEL PUNT I CONSUM */}
                  <BlockVersion1
                    title="Graduació del punt i consum"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    values={values.RequestArticle.HistoryArticle}
                    games={values.RequestMachineGroups}
                  />

                  {/* DADES SOL·LICITUD | GRADUACIÓ DEL PUNT I CONSUM */}
                  <BlockVersion2
                    title="Graduació del punt i consum"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    setFieldValue={setFieldValue}
                    values={values.RequestArticle}
                    games={values.RequestMachineGroups}
                    threads={values.RequestThreads}
                    setRequestFieldsState={setRequestFieldsState}
                    fullValues={values}
                  />

                  {/* DADES ACTUALS | ALÇADA I SINCRONISME */}
                  <BlockVersion1
                    title="Alçada i sincronisme"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    setFieldValue={setFieldValue}
                    values={values.RequestArticle.HistoryArticle}
                  />

                  {/* DADES SOL·LICITUD | ALÇADA I SINCRONISME */}
                  <BlockVersion2
                    title="Alçada i sincronisme"
                    className="flex flex-col col-span-12 lg:col-span-6"
                  />

                  {/* DADES ACTUALS | OBSERVACIONS / NOTES DE FITXA TÈCNICA DE TEIXIDURIA */}
                  <BlockVersion1
                    title="Observacions / Notes de fitxa tècnica de teixiduria"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    values={values.RequestArticle.HistoryArticle}
                  />

                  {/* DADES SOL·LICITUD | OBSERVACIONS / NOTES DE FITXA TÈCNICA DE TEIXIDURIA */}
                  <BlockVersion2
                    title="Observacions / Notes de fitxa tècnica de teixiduria"
                    className="flex flex-col col-span-12 lg:col-span-6"
                    values={values.RequestArticle}
                  />
                </>
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <div className="inline-flex items-center justify-start w-full p-4">
          <CgSpinner size={40} className="text-charcoal animate-spin" />
          <p className="pl-2 text-lg font-semibold text-charcoal">
            Carregant...
          </p>
        </div>
      )}
    </>
  )
}

FTTeixiduriaRequest.propTypes = {
  params: PropTypes.any.isRequired
}
