import pinyons from 'assets/icons/pinyons.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'

export default function SprocketsForm({ machineCode }) {
  const fieldStyles =
    'py-1 leading-tight text-center border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline hover:border-gray-400 focus:border-gray-400'

  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      <div className="relative w-full h-full col-span-12 mx-auto">
        <img
          src={pinyons}
          alt="pinyons plegador"
          className="mx-auto my-8"
          width={220}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Primera Fila de Arriba a Abajo (Derecha) */}
        <Field
          type="text"
          name="RequestArticle.Piston2"
          className={`${fieldStyles} absolute top-0 w-12 right-20`}
        />
        <div className="absolute top-0 right-6">
          &plusmn;
          <Field
            type="text"
            name="RequestArticle.PistonTolerance2"
            className={`${fieldStyles} w-8 py-1`}
          />
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Segunda Fila de Arriba a Abajo (Izquierda) */}
        <Field
          type="text"
          name="RequestArticle.Piston1"
          className={`${fieldStyles} absolute w-12 sm:top-5 lg:top-6 left-4`}
        />
        <div className="absolute inline-flex items-center sm:top-5 sm:left-20 lg:top-6 lg:left-20">
          &plusmn;
          <Field
            type="text"
            name="RequestArticle.PistonTolerance1"
            className={`${fieldStyles} w-8`}
          />
        </div>

        {/* NEW VERSION OF TOP ELEMENT */}
        {/* <div className="absolute inline-flex space-x-1 sm:top-5 lg:top-6 left-4">
          <div className="w-12 p-0.5 leading-tight bg-white border border-gray-300 rounded">
            {values?.Piston1}
          </div>
          <span>&plusmn;</span>
          <div className="w-8 p-0.5 leading-tight bg-white border border-gray-300 rounded">
            {values?.PistonTolerance1}
          </div>
        </div> */}

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Tercera Fila de Arriba a Abajo (Derecha) */}
        <Field
          type="text"
          name="RequestArticle.Piston4"
          className={`${fieldStyles} absolute w-12 lg:top-12 right-20 sm:top-12 lg:text-base`}
        />
        <div className="absolute inline-flex items-center top-12 right-6">
          &plusmn;
          <Field
            type="text"
            name="RequestArticle.PistonTolerance4"
            className={`${fieldStyles} w-8`}
          />
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Cuarta Fila de Arriba a Abajo (Izquierda) */}
        <Field
          type="text"
          name="RequestArticle.Piston3"
          className={`${fieldStyles} absolute sm:bottom-20 lg:bottom-16 sm:w-12 left-4 lg:w-12`}
        />
        <div className="absolute inline-flex items-center sm:left-20 bottom-16 lg:left-20">
          &plusmn;
          <Field
            type="text"
            name="RequestArticle.PistonTolerance3"
            className={`${fieldStyles} sm:w-8 lg:text-base lg:w-8`}
          />
        </div>

        {/* NEW VERSION OF TOP ELEMENT */}
        {/* <div className="absolute inline-flex space-x-1 sm:bottom-20 lg:bottom-16 left-4">
          <div className="w-12 p-0.5 leading-tight bg-white border border-gray-300 rounded">
            {values?.Piston3}
          </div>
          <span>&plusmn;</span>
          <div className="w-8 p-0.5 leading-tight bg-white border border-gray-300 rounded">
            {values?.PistonTolerance3}
          </div>
        </div> */}

        {/* ---------------------------------- */}

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <Field
          type="text"
          name="RequestArticle.Piston7"
          className={`${
            machineCode === 'JMB' ? 'hidden' : 'block'
          } ${fieldStyles} absolute sm:top-20 sm:right-28 sm:w-10 lg:top-28 2xl:right-44 lg:w-12`}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <Field
          type="text"
          name="RequestArticle.Piston6"
          className={` ${
            machineCode === 'JMB' ? 'hidden' : 'block'
          } ${fieldStyles} absolute top-28 right-14 2xl:right-28 w-12`}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <Field
          type="text"
          name="RequestArticle.Piston5"
          className={`${
            machineCode === 'JMB' ? 'hidden' : 'block'
          } ${fieldStyles} absolute sm:w-12 lg:text-base sm:left-9 sm:top-24 lg:top-36 2xl:left-28 lg:w-12`}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <Field
          type="text"
          name="RequestArticle.Piston8"
          className={` ${
            machineCode === 'JMB' ? 'hidden' : 'block'
          } ${fieldStyles} absolute sm:top-28 sm:right-28 sm:w-10 lg:top-36 2xl:right-44 lg:w-12`}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <Field
          type="text"
          name="RequestArticle.Piston9"
          className={`${
            machineCode === 'JMB' ? 'hidden' : 'block'
          } ${fieldStyles} absolute sm:top-32 sm:left-9 lg:top-44 2xl:left-28 w-12`}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <Field
          type="text"
          name="RequestArticle.Piston10"
          className={`${
            machineCode === 'JMB' ? 'hidden' : 'block'
          } ${fieldStyles} absolute sm:top-36 sm:w-10 lg:top-44 right-28 2xl:right-44 lg:w-12`}
        />

        {/* ---------------------------------- */}

        {/* JMB -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <Field
          type="text"
          name="RequestArticle.JUM1"
          className={`${
            machineCode === 'JMB' ? 'block' : 'hidden'
          } ${fieldStyles} absolute sm:top-28 sm:right-28 sm:w-10 lg:top-28 2xl:right-32 lg:w-12`}
        />

        {/* JMB -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <Field
          type="text"
          name="RequestArticle.JUM2"
          className={`${
            machineCode === 'JMB' ? 'block' : 'hidden'
          } ${fieldStyles} absolute sm:top-28 sm:right-28 sm:w-10 lg:top-28 2xl:right-56 lg:w-12`}
        />

        {/* JMB -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <Field
          type="text"
          name="RequestArticle.JUM3"
          className={`${
            machineCode === 'JMB' ? 'block' : 'hidden'
          } ${fieldStyles} absolute sm:top-28 sm:right-28 sm:w-10 lg:top-36 2xl:right-56 lg:w-12`}
        />

        {/* JMB -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <Field
          type="text"
          name="RequestArticle.JUM4"
          className={`${
            machineCode === 'JMB' ? 'block' : 'hidden'
          } ${fieldStyles} absolute sm:top-28 left-4 sm:w-10 lg:top-36 lg:w-12`}
        />
      </div>
    </div>
  )
}

SprocketsForm.propTypes = {
  machineCode: PropTypes.string
}
