import PropTypes from 'prop-types'

export default function GameTable({ games }) {
  const sizeOptions = [
    { value: 'LFA', label: 'LFA' },
    { value: 'W', label: 'W' },
    { value: 'MRA', label: 'MRA' },
    { value: 'SCH', label: 'SCH' }
  ]

  return (
    <table className="relative w-full px-4 border-2 table-fixed border-primary print:border">
      <thead className={`w-full bg-primary-dark text-white`}>
        <tr className="w-full divide-x divide-red-200 divide-dashed">
          <th className="w-1/12 overflow-hidden text-sm truncate">Joc</th>
          <th className="w-6/12 overflow-hidden text-sm truncate">Matèria</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Caps</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Cons</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tipus</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Consum</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tol.</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tensió</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">NonC</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">NonP</th>
          <th className="w-1/12 overflow-hidden text-sm truncate py-2">Kg/h</th>
        </tr>
      </thead>

      <tbody className="w-full divide-y divide-dashed divide-red-50">
        {games?.length > 0 &&
          games
            .filter((game) => game.HistoryMachineGroup)
            .map((game, index) => (
              <tr
                className="w-full print:divide-x print:divide-dashed print:divide-red-50"
                key={index}
              >
                {/* Campo 'Joc': */}
                <td className="w-1/12 text-xs print:w-9">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-1/2 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {game?.HistoryMachineGroup?.Game}
                    </div>
                  </div>
                </td>

                {/* Campo 'Matèria': */}
                <td className="w-6/12 print:w-4/12 text-xs py-1 print:py-0.5">
                  <div className="flex items-center justify-center text-xs print:h-100">
                    <div className="w-full appearance-none py-0.5 px-2 border-gray-300 outline-none text-grey-darker leading-tight print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 whitespace-normal">
                      {game?.HistoryMachineGroup?.ThreadMatter}
                    </div>
                  </div>
                </td>

                {/* Campo 'Caps': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-11/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {game?.HistoryMachineGroup?.Heads}
                    </div>
                  </div>
                </td>

                {/* Campo 'Cons': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-11/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {game?.HistoryMachineGroup?.Cones}
                    </div>
                  </div>
                </td>

                {/* Campo 'Tipus': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-11/12 print:w-full py-1 px-1 border border-gray-300 text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {(() => {
                        const foundValue = sizeOptions?.find(
                          (option) =>
                            option.value === game.HistoryMachineGroup.Size
                        )
                        return foundValue ? foundValue.label : ''
                      })()}
                    </div>
                  </div>
                </td>

                {/* Campo 'Consum': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-11/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {game?.HistoryMachineGroup?.Spending}
                    </div>
                  </div>
                </td>

                {/* Campo 'Tol.': */}
                <td className="w-1/12 text-xs">
                  <div className="flex flex-row items-center justify-center print:h-100">
                    <span>&plusmn;</span>
                    <div className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {game?.HistoryMachineGroup?.TolSpending}
                    </div>
                  </div>
                </td>

                {/* Campo 'Tensió': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {game?.HistoryMachineGroup?.Pressure}
                    </div>
                  </div>
                </td>

                {/* Campo 'NonC': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {game?.HistoryMachineGroup?.NoniusC}
                    </div>
                  </div>
                </td>

                {/* Campo 'NonP': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {game?.HistoryMachineGroup?.NoniusP}
                    </div>
                  </div>
                </td>

                {/* Campo 'Kg/H': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 truncate leading-none">
                      {game?.HistoryMachineGroup?.KgH}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  )
}

GameTable.propTypes = {
  games: PropTypes.array
}
