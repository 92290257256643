import PropTypes from 'prop-types'

export default function GameTable({ games, side, isAnswered }) {
  const sizeOptions = [
    { value: 'LFA', label: 'LFA' },
    { value: 'W', label: 'W' },
    { value: 'MRA', label: 'MRA' },
    { value: 'SCH', label: 'SCH' }
  ]

  return (
    <table
      className={`relative w-full px-4 border-2 table-fixed  ${
        JSON.stringify(games?.filter((game) => game.HistoryMachineGroup)) !==
        JSON.stringify(games?.filter((game) => !game.HasBeenRemoved))
          ? side === 'left'
            ? 'border-red-300'
            : isAnswered
            ? 'border-emerald-300'
            : 'border-amber-300'
          : 'border-gray-300'
      }`}
    >
      <thead
        className={`focus-within: w-full ${
          JSON.stringify(games?.filter((game) => game.HistoryMachineGroup)) !==
          JSON.stringify(games?.filter((game) => !game.HasBeenRemoved))
            ? side === 'left'
              ? 'bg-red-100 border-red-300'
              : isAnswered
              ? 'bg-emerald-100 border-emerald-300'
              : 'bg-amber-100 border-amber-300'
            : 'bg-gray-100 border-gray-300'
        }`}
      >
        <tr
          className={`w-full divide-x divide-dashed ${
            JSON.stringify(
              games?.filter((game) => game.HistoryMachineGroup)
            ) !== JSON.stringify(games?.filter((game) => !game.HasBeenRemoved))
              ? side === 'left'
                ? 'divide-red-300'
                : isAnswered
                ? 'divide-emerald-300'
                : 'divide-amber-300'
              : 'divide-gray-300'
          }`}
        >
          <th className="w-1/12 overflow-hidden text-sm truncate">Joc</th>
          <th className="w-6/12 overflow-hidden text-sm truncate">Matèria</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Caps</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Cons</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tipus</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Consum</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tol.</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Tensió</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">NonC</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">NonP</th>
          <th className="w-1/12 overflow-hidden text-sm truncate">Kg/h</th>
        </tr>
      </thead>

      <tbody
        className={`w-full divide-y divide-dashed ${
          JSON.stringify(games?.filter((game) => game.HistoryMachineGroup)) !==
          JSON.stringify(games?.filter((game) => !game.HasBeenRemoved))
            ? side === 'left'
              ? 'divide-red-300'
              : isAnswered
              ? 'divide-emerald-300'
              : 'divide-amber-300'
            : 'divide-gray-300'
        }`}
      >
        {games?.length > 0 &&
          games
            ?.filter((game) =>
              side === 'left' ? game.HistoryMachineGroup : !game.HasBeenRemoved
            )
            .map((game, index) => (
              <tr
                className="w-full print:divide-x print:divide-dashed print:divide-red-50"
                key={index}
              >
                {/* Campo 'Joc': */}
                <td className="w-1/12 text-xs print:w-9">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-1/2 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {side === 'left'
                        ? game?.HistoryMachineGroup.Game
                        : game?.Game}
                    </div>
                  </div>
                </td>

                {/* Campo 'Matèria': */}
                <td className="w-6/12 print:w-4/12 text-xs py-1 print:py-0.5">
                  <div className="flex items-center justify-center text-xs print:h-100">
                    <div className="w-full appearance-none py-0.5 px-2 border-gray-300 outline-none text-grey-darker leading-tight print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 whitespace-normal">
                      {side === 'left'
                        ? game?.HistoryMachineGroup.ThreadMatter
                        : game?.ThreadMatter}
                    </div>
                  </div>
                </td>

                {/* Campo 'Caps': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-11/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {side === 'left'
                        ? game?.HistoryMachineGroup.Heads
                        : game?.Heads}
                    </div>
                  </div>
                </td>

                {/* Campo 'Cons': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-11/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {side === 'left'
                        ? game?.HistoryMachineGroup.Cones
                        : game?.Cones}
                    </div>
                  </div>
                </td>

                {/* Campo 'Tipus': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-11/12 print:w-full py-1 px-1 border border-gray-300 text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {(() => {
                        const foundValue = sizeOptions?.find(
                          (option) =>
                            option.value ===
                            (side === 'left'
                              ? game?.HistoryMachineGroup.Size
                              : game?.Size)
                        )
                        return foundValue ? foundValue.label : ''
                      })()}
                    </div>
                  </div>
                </td>

                {/* Campo 'Consum': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-11/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {side === 'left'
                        ? game?.HistoryMachineGroup.Spending
                        : game?.Spending}
                    </div>
                  </div>
                </td>

                {/* Campo 'Tol.': */}
                <td className="w-1/12 text-xs">
                  <div className="flex flex-row items-center justify-center print:h-100">
                    <span>&plusmn;</span>
                    <div className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {side === 'left'
                        ? game?.HistoryMachineGroup.TolSpending
                        : game?.TolSpending}
                    </div>
                  </div>
                </td>

                {/* Campo 'Tensió': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {side === 'left'
                        ? game?.HistoryMachineGroup.Pressure
                        : game?.Pressure}
                    </div>
                  </div>
                </td>

                {/* Campo 'NonC': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {side === 'left'
                        ? game?.HistoryMachineGroup.NoniusC
                        : game?.NoniusC}
                    </div>
                  </div>
                </td>

                {/* Campo 'NonP': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 leading-none">
                      {side === 'left'
                        ? game?.HistoryMachineGroup.NoniusP
                        : game?.NoniusP}
                    </div>
                  </div>
                </td>

                {/* Campo 'Kg/H': */}
                <td className="w-1/12 text-xs">
                  <div className="flex items-center justify-center print:h-100">
                    <div className="rounded w-10/12 print:w-full appearance-none py-1 px-1 border border-gray-300 outline-none text-grey-darker print:border-0 print:rounded-none print-background print:py-0 print:px-0.5 truncate leading-none">
                      {side === 'left'
                        ? game?.HistoryMachineGroup.KgH
                        : game?.KgH}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  )
}

GameTable.propTypes = {
  games: PropTypes.any,
  side: PropTypes.string,
  isAnswered: PropTypes.bool
}
