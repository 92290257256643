import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import ControlVisionForm from 'forms/Control/ControlVision/ControlVisionForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'wouter'

export default function ControlVisionDetail({ params }) {
  const equipmentOptions = []
  const [equipmentList, equipmentListAction] = useFetch()
  const { equipment } = useContext(UserContext)

  const userOptions = []
  const [userList, userListAction] = useFetch()
  const { user } = useContext(UserContext)

  const { handleModal } = useContext(ModalContext)
  const controlvisionOptions = []
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const [client, saveClient] = useFetch()
  const isNew = params.id === 'nou'
  const [controlvisionList, controlvisionListAction] = useFetch()

  const [fields, setFields] = useState({
    Id: 0,
    Date: '',
    UserId: '',
    UserName: '',
    OF: '',
    EquipmentId: '',
    EquipmentName: '',
    Verification1: '',
    Verification2: '',
    Verification3: '',
    Verification4: '',
    Description: '',
    Style: ''
  })

  const titleEdit = {
    name: `Modificar registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/visioncontrol`
      }
    ],
    deleteControlVisionBtn: true,
    controlvisionId: params.id,
    button: 'print'
  }

  const titleCreate = {
    name: `Crear registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/visioncontrol`
      }
    ]
  }

  useEffect(() => {
    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar Users del ECAP'
    }
    userListAction(userListAPIParams)
  }, [])

  useEffect(() => {
    if (userList.data) {
      userList.data.forEach((user) => {
        userOptions.push({
          value: user.Id,
          // label: user.Id + '-' + user.Name
          label: user.Name
        })
      })
    }
  }, [userList])

  useEffect(() => {
    const equipmentListAPIParams = {
      url: `inventorymachine/isTool`,
      method: 'GET',
      messageKo: 'Error al recuperar dades'
    }
    equipmentListAction(equipmentListAPIParams)
  }, [])

  useEffect(() => {
    if (equipmentList.data) {
      equipmentList.data.forEach((equipment) => {
        equipmentOptions.push({
          value: equipment.Id,
          // label: user.Id + '-' + user.Name
          label: equipment.Description
        })
      })
    }
  }, [equipmentList])

  // -----------------------
  useEffect(() => {
    if (controlvisionList.data) {
      // console.log('mtList.data: ',mtList.data)
      controlvisionList.data.forEach((controlvision) => {
        controlvisionOptions.push({
          value: controlvision.Id,
          label: `${controlvision.Description}`
        })
      })
    }
  }, [controlvisionList])
  // -----------------------

  useEffect(() => {
    const controlvisionListAPIParams = {
      url: `visioncontrol`,
      method: 'GET',
      messageOK: 'Error al recuperar dades'
    }

    controlvisionListAction(controlvisionListAPIParams)

    if (params.id !== 'nou') {
      setTitle(titleEdit)
      const findClientById = {
        url: `visioncontrol/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades'
      }

      saveClient(findClientById)
    } else setTitle(titleCreate)
  }, [])

  useEffect(() => {
    // Set initialValues
    console.log('Datos CV: ', client.data)
    if (client.data) {
      setFields({
        Id: client.data.Id,
        Date: client.data.Date,
        UserId: client.data.UserId,
        UserName: client.data.UserName,
        OF: client.data.OF,
        EquipmentId: client.data.EquipmentId,
        EquipmentName: client.data.EquipmentName,
        Verification1: client.data.Verification1,
        Verification2: client.data.Verification2,
        Verification3: client.data.Verification3,
        Verification4: client.data.Verification4,
        Description: client.data.Description,
        Style: client.data.Style
      })
    }
  }, [client.data])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFields((prevFields) => ({ ...prevFields, [name]: value }))
  }

  const handleSubmit = async (values) => {
    let url = `visioncontrol`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    }

    // Send PUT //
    const clientApiParams = {
      url,
      method,
      body: values,
      messageKo: 'Error al recuperar dades dels Controls Visió',
      messageOk: 'Control Visió actualitzat!'
    }
    await saveClient(clientApiParams)
    if (!client.error) setLocation('/visioncontrol')
  }

  return (
    <div className="w-full p-4 bg-white">
      <ControlVisionForm
        fields={fields}
        controlvisionList={controlvisionOptions}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isNew={isNew}
        users={userOptions}
        equipments={equipmentOptions}
      />
    </div>
  )
}

ControlVisionDetail.propTypes = {
  params: PropTypes.any
}
