import { ErrorMessage, Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { BsBoxArrowInRight } from 'react-icons/bs'
import { CgSpinner } from 'react-icons/cg'
import { MdInfo } from 'react-icons/md'
import Select from 'react-select'
import { useLocation } from 'wouter'
import * as Yup from 'yup'

const ForecastTrainingForm = ({
  handleSubmit,
  activateMonitoring,
  fields,
  isNew
}) => {
  const [, setLocation] = useLocation()
  const hiddenFileInput = useRef(null)
  const [localPriorities, setLocalPriorities] = useState(null)

  // El campo 'Priority' está hardcodeado, por lo tanto aún no tenemos una tabla en la base de datos para 'Priority':
  const priorities = [
    { Id: 1, Name: 'Alta' },
    { Id: 2, Name: 'Mitja' },
    { Id: 3, Name: 'Baixa' }
  ]

  // Este useEffect() se hizo para recoger la información, que iria en los filtros del formulario:
  useEffect(async () => {
    setLocalPriorities(priorities)
  }, [])

  useEffect(() => {
    console.log(fields)
  }, [fields])

  const validationSchema = Yup.object().shape({
    Entity: Yup.string().required('(*)').min(2, '(*)').max(100, '(*)'),
    DetailCourse: Yup.string().required('(*)').min(2, '(*)').max(100, '(*)'),
    Hours: Yup.number().required('(*)').positive('(*)').integer('(*)'),
    Price: Yup.number().required('(*)').integer('(*)'),
    PrevisionDate: Yup.string().required('(*)'),
    Priority: Yup.number().required('(*)').positive('(*)')
  })

  return localPriorities !== undefined &&
    localPriorities !== null &&
    ((!isNew && fields.Id !== 0) || (isNew && fields.Id === 0)) ? (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={{ ...fields }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <Form className="flex flex-col bg-blue-100 border-2 border-blue-200 rounded shadow-md">
            <h1 className="col-span-4 py-2 text-2xl text-center bg-blue-200 print:hidden font-ms-bold">
              {!isNew ? 'PLA DE FORMACIÓ #' + values.Id : 'NOU PLA DE FORMACIÓ'}
            </h1>

            {values.CourseState === 2 && (
              <div className="text-center w-100 pt-7">
                <div
                  className="flex items-center p-2 leading-none text-red-100 bg-red-700 border-2 border-red-800 lg:rounded-full lg:inline-flex"
                  role="alert"
                >
                  <span className="flex justify-center items-center rounded-full bg-red-500 border-2 border-red-600 uppercase px-2 py-1.5 text-xs font-bold mr-3">
                    <MdInfo size={30} />
                  </span>
                  <span className="flex-auto mr-2 font-medium text-left">
                    No podrà modificar si està tancat el Seguiment!
                  </span>
                </div>
              </div>
            )}

            {/* Contenedor con todos los inputs: */}
            <div
              className={`grid grid-cols-4 gap-x-5 px-5 py-5 print:m-1 print:p-3
              ${values.CourseState ? 'm-2' : 'mx-2'}`}
            >
              <div className="container relative grid grid-cols-1 col-span-2 gap-x-12 gap-y-4">
                {/* Campo 'Formador' ('Entitat'): */}
                <div
                  className={`w-full items-center block px-2 py-2 print:bg-white`}
                >
                  <div className="flex flex-row">
                    <label
                      className="block mb-2 mr-3 text-base leading-4 text-grey-darker font-ms-semi col-start-full"
                      htmlFor="Dependence"
                    >
                      Formador
                    </label>

                    <ErrorMessage
                      name="Entity"
                      render={(message) => (
                        <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 outline-none 
                      text-grey print:shadow-none print:border-none print:h-auto print:py-3.5
                      ${
                        touched.Entity && errors.Entity
                          ? 'border-red-200 bg-red-50'
                          : 'border-blue-200'
                      }
                      ${values.CourseState === 2 && 'cursor-not-allowed'}`}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    name="Entity"
                    placeholder="Introdueix..."
                    type="text"
                    disabled={values.CourseState === 2}
                  />
                </div>

                {/* Campos 'Hores', 'Preu': */}
                <div className="grid w-full grid-cols-4 gap-x-4">
                  {/* Campo 'Hores': */}
                  <div
                    className={`w-full items-center block px-2 py-2 print:bg-white col-span-1`}
                  >
                    <div className="flex flex-row">
                      <label
                        className="block mb-2 mr-3 text-base leading-4 text-grey-darker font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Hores
                      </label>

                      <ErrorMessage
                        name="Hours"
                        render={(message) => (
                          <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <div className="flex flex-row items-center justify-center">
                      <Field
                        className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 outline-none 
                          text-grey print:shadow-none print:border-none print:h-auto print:py-3.5
                          ${
                            touched.Hours && errors.Hours
                              ? 'border-red-200 bg-red-50'
                              : 'border-blue-200'
                          }
                          ${values.CourseState === 2 && 'cursor-not-allowed'}`}
                        onChange={(e) => {
                          handleChange(e)
                        }}
                        name="Hours"
                        placeholder="Introdueix..."
                        type="number"
                        disabled={values.CourseState === 2}
                        style={{
                          backgroundColor:
                            values.CourseState === 2 ? '#f2f2f2' : '#ffffff',
                          color: values.CourseState === 2 && '#999999'
                        }}
                      />
                      <label className="block pl-1 leading-4 text-grey-darker text-md font-ms-semi col-start-full">
                        h
                      </label>
                    </div>
                  </div>

                  {/* Campo 'Preu': */}
                  <div
                    className={`w-full items-center block px-2 py-2 print:bg-white col-span-1`}
                  >
                    <div className="flex flex-row">
                      <label
                        className="block mb-2 mr-3 text-base leading-4 text-grey-darker font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Preu
                      </label>

                      <ErrorMessage
                        name="Price"
                        render={(message) => (
                          <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                            {message}
                          </span>
                        )}
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Field
                        className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 outline-none 
                          text-grey print:shadow-none print:border-none print:h-auto print:py-3.5
                          ${
                            touched.Price && errors.Price
                              ? 'border-red-200 bg-red-50'
                              : 'border-blue-200'
                          }
                          ${values.CourseState === 2 && 'cursor-not-allowed'}`}
                        onChange={(e) => {
                          handleChange(e)
                        }}
                        name="Price"
                        placeholder="Introdueix..."
                        type="number"
                        disabled={values.CourseState === 2}
                        style={{
                          backgroundColor:
                            values.CourseState === 2 ? '#f2f2f2' : '#ffffff',
                          color: values.CourseState === 2 && '#999999'
                        }}
                      />
                      <label className="block pl-1 leading-4 text-grey-darker text-md font-ms-semi col-start-full">
                        €
                      </label>
                    </div>
                  </div>

                  {/* Campo 'Previsió': */}
                  <div
                    className={`w-full items-center block px-2 py-2 print:bg-white col-span-2`}
                  >
                    <div className="flex flex-row">
                      <label
                        className="block mb-2 mr-3 text-base leading-4 text-grey-darker font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Previsió
                      </label>

                      <ErrorMessage
                        name="PrevisionDate"
                        render={(message) => (
                          <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <Field
                      className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 outline-none 
                        text-grey print:shadow-none print:border-none print:h-auto print:py-3.5
                        ${
                          touched.PrevisionDate && errors.PrevisionDate
                            ? 'border-red-200 bg-red-50'
                            : 'border-blue-200'
                        }
                        ${values.CourseState === 2 && 'cursor-not-allowed'}`}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                      name="PrevisionDate"
                      type="date"
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        backgroundColor:
                          values.CourseState === 2 ? '#f2f2f2' : '#ffffff',
                        color: values.CourseState === 2 && '#999999'
                      }}
                      disabled={values.CourseState === 2}
                    />
                  </div>
                </div>
              </div>

              <div className="container relative grid grid-cols-1 col-span-2 gap-x-12 gap-y-4">
                {/* Campo 'Curs': */}
                <div
                  className={`w-full items-center block px-2 py-2 print:bg-white`}
                >
                  <div className="flex flex-row">
                    <label
                      className="block mb-2 mr-3 text-base leading-4 text-grey-darker font-ms-semi col-start-full"
                      htmlFor="Dependence"
                    >
                      Curs
                    </label>

                    <ErrorMessage
                      name="DetailCourse"
                      render={(message) => (
                        <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 outline-none 
                      text-grey print:shadow-none print:border-none print:h-auto print:py-3.5
                      ${
                        touched.DetailCourse && errors.DetailCourse
                          ? 'border-red-200 bg-red-50'
                          : 'border-blue-200'
                      }
                      ${values.CourseState === 2 && 'cursor-not-allowed'}`}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    name="DetailCourse"
                    placeholder="Introdueix..."
                    type="text"
                    disabled={values.CourseState === 2}
                  />
                </div>

                {/* Campos 'Prioritat', 'Estadi', 'Seguiment': */}
                <div className="grid w-full grid-cols-4 gap-x-4">
                  {/* Campo 'Prioritat': */}
                  <div
                    className={`w-full items-center block px-2 py-2 print:bg-white col-span-1`}
                  >
                    <div className="flex flex-row">
                      <label
                        className="block mb-2 mr-3 text-base leading-4 text-grey-darker font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Prioritat
                      </label>

                      <ErrorMessage
                        name="Priority"
                        render={(message) => (
                          <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <Select
                      className={`rounded text-grey-darker`}
                      onChange={(e) => {
                        console.log(e.Id)
                        setFieldValue('Priority', e.Id)
                      }}
                      name="Priority"
                      placeholder="Selecciona..."
                      options={localPriorities}
                      value={localPriorities.find(
                        (option) => option.Id === values.Priority
                      )}
                      getOptionLabel={(o) => o.Name}
                      getOptionValue={(o) => o.Id}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          border:
                            touched.Priority && errors.Priority
                              ? '2px solid #fecaca'
                              : '2px solid #bfdbfe',
                          backgroundColor:
                            touched.Priority && errors.Priority
                              ? '#fef2f2'
                              : values.CourseState === 2
                              ? '#f2f2f2'
                              : '#ffffff',
                          cursor:
                            values.CourseState === 2 && 'cursor-not-allowed'
                        })
                      }}
                      isDisabled={values.CourseState === 2}
                    />
                  </div>

                  {/* Campo 'Estadi': */}
                  <div
                    className={`w-full items-center block px-2 py-2 print:bg-white col-span-1`}
                  >
                    <div className="flex flex-row">
                      <label
                        className="block mb-2 mr-3 text-base leading-4 text-grey-darker font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Estadi
                      </label>
                    </div>

                    <div
                      className={`w-full pl-2 rounded appearance-none py-1.5 px-3 p-2 border-2 outline-none text-grey print:shadow-none 
                        print:border-none print:h-auto print:py-3.5 text-white text-center
                        ${
                          values.CourseState === 1 &&
                          'bg-green-500 border-green-600 col-span-3'
                        }
                        ${
                          values.CourseState === 2 &&
                          'bg-red-500 border-red-600 col-span-3'
                        }
                        ${
                          values.CourseState === 3 &&
                          'bg-amber-500 border-amber-600 col-span-3'
                        }`}
                    >
                      {values.CourseState === 1 && 'Obert'}
                      {values.CourseState === 2 && 'Tancat'}
                      {values.CourseState === 3 && 'Pendent'}
                    </div>
                  </div>

                  {/* Campo 'Seguiment': */}
                  <div
                    className={`w-full items-center block px-2 py-2 print:bg-white col-span-2`}
                  >
                    <label
                      className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                      htmlFor="Dependence"
                    >
                      Seguiment
                    </label>
                    <div className="grid w-full grid-cols-4 gap-x-3">
                      <div
                        className={`w-full pl-2 rounded appearance-none py-1.5 px-3 p-2 border-2 outline-none text-grey print:shadow-none 
                        print:border-none print:h-auto print:py-3.5 text-white text-center
                        ${
                          values.MonitoringActivated
                            ? 'bg-green-500 border-green-600 col-span-3'
                            : 'bg-amber-500 border-amber-600 col-span-4'
                        }`}
                      >
                        {values.MonitoringActivated ? 'Activat' : 'Pendent'}
                      </div>

                      <button
                        className={`w-full col-span-1 pl-2 rounded appearance-none py-1.5 px-3 p-2 border-2 outline-none text-grey print:shadow-none 
                          print:border-none print:h-auto print:py-3.5 text-white text-center flex justify-center 
                          border-green-600 hover:border-blue-200 bg-green-500
                          cursor-pointer
                          hover:bg-blue-200 hover:text-green-600
                          print:hidden ${
                            values.MonitoringActivated ? '' : 'hidden'
                          }`}
                        type="button"
                        onClick={(e) => {
                          setLocation(
                            '/seguiment-formacio/' + values.TrainingPlan_Id
                          )
                        }}
                      >
                        <BsBoxArrowInRight size={25} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="items-center block w-full h-full col-span-4 mt-2 print:bg-white">
                {/* Campo 'Implicats': */}
                <div
                  className={`h-full col-span-4 w-full items-center block px-2 mt-4 print:bg-white`}
                >
                  <label
                    className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                    htmlFor="Dependence"
                  >
                    Implicats
                  </label>
                  <Field
                    className={`h-40 pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                      text-grey print:shadow-none print:border-none print:h-auto print:py-3.5
                      ${values.CourseState === 2 && 'cursor-not-allowed'}`}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    name="Involved"
                    placeholder="Introdueix..."
                    type="text"
                    component="textarea"
                    disabled={values.CourseState === 2}
                    style={{
                      backgroundColor:
                        values.CourseState === 2 ? '#f2f2f2' : '#ffffff',
                      color: values.CourseState === 2 && '#999999'
                    }}
                  />
                </div>
              </div>

              <div className="items-center block w-full h-full col-span-4 mt-2 print:bg-white">
                {/* Campo 'Descripció': */}
                <div
                  className={`h-full col-span-4 w-full items-center block px-2 mt-4 print:bg-white`}
                >
                  <label
                    className="block mb-2 mr-3 text-base leading-4 text-grey-darker font-ms-semi col-start-full"
                    htmlFor="Dependence"
                  >
                    Observacions
                  </label>

                  <Field
                    className={`h-40 pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                      text-grey print:shadow-none print:border-none print:h-auto print:py-3.5
                      ${values.CourseState === 2 && 'cursor-not-allowed'}`}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    name="Description"
                    placeholder="Introdueix..."
                    type="text"
                    component="textarea"
                    disabled={values.CourseState === 2}
                    style={{
                      backgroundColor:
                        values.CourseState === 2 ? '#f2f2f2' : '#ffffff',
                      color: values.CourseState === 2 && '#999999'
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Botones de 'Guardar' y 'Activar': */}
            {values.CourseState !== 2 && (
              <div className="flex flex-row justify-center col-span-1">
                <div>
                  <button
                    className={`block transition duration-300 
                      border-primary bg-primary text-white 
                      font-ms-semi mb-4 py-1 px-4 ml-auto
                      border rounded h-10 w-28
                      hover:border-blue-200 hover:bg-blue-200 hover:text-primary
                      print:hidden ${values.MonitoringActivated ? '' : 'mr-2'}`}
                    type="submit"
                  >
                    GUARDAR
                  </button>
                </div>

                <div>
                  <button
                    className={`block transition duration-300 
                      border-green-500 hover:border-blue-200 bg-green-500 text-white 
                      font-ms-semi mb-4 py-1 px-4 ml-auto
                      border rounded h-10 w-28
                      hover:bg-blue-200 hover:text-green-600
                      print:hidden ${
                        isNew || values.MonitoringActivated ? 'hidden' : ''
                      }`}
                    type="button"
                    onClick={(e) => {
                      activateMonitoring(values)
                    }}
                  >
                    ACTIVAR
                  </button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  ) : (
    <div className="inline-flex items-center justify-start w-full">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

ForecastTrainingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  activateMonitoring: PropTypes.func,
  fields: PropTypes.object,
  params: PropTypes.object,
  isNew: PropTypes.bool
}

export default ForecastTrainingForm
