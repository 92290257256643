import alcades from 'assets/icons/alcades.png'
import PropTypes from 'prop-types'
import GamesTable from './gamesTable'

export default function PointGraduationForm({ fields }) {
  return (
    <div className="grid w-full grid-cols-12 gap-2 p-2 xl:grid-cols-11">
      {/* Columna 1: */}
      {/* Campo 'Alçada cilindre (seccions)': */}
      <div className="grid items-center grid-cols-4 col-span-4 xl:col-span-3">
        <label className="block col-span-4 mb-1 text-sm text-grey-darker font-ms-semi col-start-full">
          Alçada cilindre (seccions):
        </label>

        <div className="relative col-span-3 rounded">
          <img src={alcades} alt="Pinyons Plegador" width={150} />

          <div className="absolute left-0 px-2 py-1 leading-tight border border-gray-300 rounded appearance-none bottom-1 sm:w-12 lg:w-16 focus:outline-none focus:shadow-outline">
            {fields?.CylinderHeight1}
          </div>

          <div className="absolute px-2 py-1 leading-tight border border-gray-300 rounded appearance-none bottom-1 sm:left-16 sm:w-12 lg:left-20 lg:w-16 focus:outline-none focus:shadow-outline">
            {fields?.CylinderHeight2}
          </div>
        </div>
      </div>

      {/* Columna 2: */}
      {/* Campo 'Posició Aro Platines': */}
      <div className="items-center block col-span-4">
        <label
          className="block mb-1 text-sm text-grey-darker font-ms-semi col-start-full"
          htmlFor="PositionTurntables"
        >
          Posició Aro Platines:
        </label>

        <div className="w-full px-2 py-1 align-middle border-2 border-gray-300 rounded outline-none appearance-none h-28 text-grey-darker">
          {fields?.PositionTurntables}
        </div>
      </div>

      {/* Columna 3: */}
      {/* Campo 'Posició Aro Guiafils': */}
      <div className="items-center block col-span-4">
        <label
          className="block mb-1 text-sm text-grey-darker font-ms-semi col-start-full"
          htmlFor="PositionTurntables"
        >
          Posició Aro Guiafils:
        </label>

        <div className="w-full px-2 py-1 align-middle border-2 border-gray-300 rounded outline-none appearance-none h-28 text-grey-darker">
          {fields?.PositionGuiafils}
        </div>
      </div>

      {/* Tabla 'Jocs': */}
      <div className="w-full col-span-12 xl:col-span-11">
        <GamesTable games={fields.TSWeavingMachineGroups} />
      </div>

      {/* Campo 'Tensiòmetre': */}
      <div className="flex items-center col-span-12 xl:col-span-11">
        <label
          className="block pr-1 text-sm text-grey-darker font-ms-semi col-start-full"
          htmlFor="PassTensiometer"
        >
          Tensiòmetre
        </label>

        <input
          type="checkbox"
          className="w-6 h-6 ml-1"
          checked={fields?.PassTensiometer}
          disabled
        />
      </div>
    </div>
  )
}

PointGraduationForm.propTypes = {
  fields: PropTypes.object
}
