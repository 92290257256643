import DeleteModal from 'components/DeleteModal'
import { ModalContext } from 'contexts/ModalContext'
import { UserContext } from 'contexts/UserContext'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'
import {
  MdInfo,
  MdOutlineAdd,
  MdOutlineUploadFile,
  MdPersonAdd,
  MdRemove
} from 'react-icons/md'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { useLocation } from 'wouter'

const TrainingPlanForm = ({
  handleSubmit,
  fields,
  uploadDocument,
  uploadUser,
  updateUser,
  deleteDocument,
  deleteUser,
  addDocumentsToTable,
  addUsersToTable,
  addTreballadorsToSelectList,
  params
}) => {
  const [, setLocation] = useLocation()
  const { user } = useContext(UserContext)
  const hiddenFileInput = useRef(null)
  const { handleModal } = useContext(ModalContext)
  const [localCourseState, setLocalCourseStates] = useState()
  const [changedCourseState, setChangedCourseState] = useState(null)
  const [localValuationState, setLocalValuationStates] = useState()
  const [addTreballadorsOpenState, setAddTreballadorsOpenState] =
    useState(false)
  const [getSelectedTreballador, setSelectedTreballador] = useState(null)
  const [getSelectedTreballadorId, setSelectedTreballadorId] = useState(null)

  const currentdate = new Date()
  const datetime =
    String(currentdate.getFullYear()) +
    '-' +
    String(currentdate.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(currentdate.getDate()).padStart(2, '0')

  // useFetch() para las llamadas a la API:
  // const [apiTreballadors, setApiTreballadors] = useFetch()

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const clearSelectTreballadors = () => {
    setSelectedTreballador(null)
  }

  // El campo 'CourseState' está hardcodeado, por lo tanto aún no tenemos una tabla en la base de datos para 'CourseState':
  const courseStates = [
    { Id: 1, Name: 'Obert' },
    { Id: 2, Name: 'Tancat' }
  ]

  // El campo 'ValuationState' está hardcodeado, por lo tanto aún no tenemos una tabla en la base de datos para 'ValuationState':
  const valuationState = [
    { Id: 1, Name: 'Correcte' },
    { Id: 2, Name: 'Incorrecte' },
    { Id: 3, Name: 'Pendent' }
  ]

  useEffect(() => {
    setLocalCourseStates(courseStates)
    setLocalValuationStates(valuationState)
  }, [])

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  return localCourseState !== undefined &&
    localCourseState !== null &&
    localValuationState !== undefined &&
    localValuationState !== null &&
    fields.Id !== 0 ? (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <Form
            onKeyDown={onKeyDown}
            className="flex flex-col bg-blue-100 border-2 border-blue-200 rounded shadow-md"
            id="formTreballadorEdit"
          >
            <h1 className="col-span-4 py-2 text-2xl text-center bg-blue-200 print:hidden font-ms-bold">
              {'SEGUIMENT DE FORMACIÓ #' + values.Id}
            </h1>

            {/* Dirty: Devuelte 'true' si los 'values' no son exactamente iguales que los 'initialValues', en caso contrario devuelve 'false'. 
              Es una propiedad del 'Formik' de solo 'readonly' y no se debe modificar manualment. */}
            {dirty && (
              <div className="text-center w-100 pt-7">
                <div
                  className="flex items-center p-2 leading-none text-red-100 bg-red-700 border-2 border-red-800 lg:rounded-full lg:inline-flex"
                  role="alert"
                >
                  <span className="flex justify-center items-center rounded-full bg-red-500 border-2 border-red-600 uppercase px-2 py-1.5 text-xs font-bold mr-3">
                    <MdInfo size={30} />
                  </span>
                  <span className="flex-auto mr-2 font-medium text-left">
                    Ha modificat camps, no oblidi guardar-los!
                  </span>
                </div>
              </div>
            )}

            {/* Contenedor con todos los inputs: */}
            <div className="grid grid-cols-4 px-5 py-5 mx-2 gap-x-5 print:m-1 print:p-3">
              {/* Fila 1: */}
              <div className="container relative grid grid-cols-1 col-span-2 gap-x-12 gap-y-4">
                {/* Campo 'Formador' ('Entitat'): */}
                <div
                  className={`w-full items-center block px-2 py-2 print:bg-white`}
                >
                  <label
                    className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                    htmlFor="Dependence"
                  >
                    Formador
                  </label>
                  <Field
                    className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                    text-grey print:shadow-none print:border-none print:h-auto print:py-3.5 cursor-not-allowed`}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    name="Entity"
                    placeholder="Introdueix..."
                    type="text"
                    disabled
                  />
                </div>

                {/* Campos 'Hores', 'Preu': */}
                <div className="grid w-full grid-cols-2 gap-x-4">
                  {/* Campo 'Hores': */}
                  <div
                    className={`w-full items-center block px-2 py-2 print:bg-white col-span-1`}
                  >
                    <label
                      className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                      htmlFor="Dependence"
                    >
                      Hores
                    </label>
                    <div className="flex flex-row items-center justify-center">
                      <Field
                        className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                        text-grey print:shadow-none print:border-none print:h-auto print:py-3.5 cursor-not-allowed`}
                        onChange={(e) => {
                          handleChange(e)
                        }}
                        name="Hours"
                        placeholder="Introdueix..."
                        type="text"
                        disabled
                      />
                      <label className="block pl-1 leading-4 text-grey-darker text-md font-ms-semi col-start-full">
                        h
                      </label>
                    </div>
                  </div>

                  {/* Campo 'Preu': */}
                  <div
                    className={`w-full items-center block px-2 py-2 print:bg-white col-span-1`}
                  >
                    <label
                      className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                      htmlFor="Dependence"
                    >
                      Preu
                    </label>
                    <div className="flex flex-row items-center justify-center">
                      <Field
                        className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                        text-grey print:shadow-none print:border-none print:h-auto print:py-3.5 cursor-not-allowed`}
                        onChange={(e) => {
                          handleChange(e)
                        }}
                        name="Price"
                        placeholder="Introdueix..."
                        type="text"
                        disabled
                      />
                      <label className="block pl-1 leading-4 text-grey-darker text-md font-ms-semi col-start-full">
                        €
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Fila 2: */}
              <div className="container relative grid grid-cols-1 col-span-2 gap-x-12 gap-y-4">
                {/* Campo 'Curs': */}
                <div
                  className={`w-full items-center block px-2 py-2 print:bg-white`}
                >
                  <label
                    className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                    htmlFor="Dependence"
                  >
                    Curs
                  </label>
                  <Field
                    className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                    text-grey print:shadow-none print:border-none print:h-auto print:py-3.5 cursor-not-allowed`}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    name="DetailCourse"
                    placeholder="Introdueix..."
                    type="text"
                    disabled
                  />
                </div>

                {/* Campo 'Data Inici', 'Data Final': */}
                <div className="grid w-full grid-cols-2 gap-x-4">
                  {/* Campo 'Data Inici': */}
                  <div
                    className={`w-full items-center block px-2 py-2 print:bg-white col-span-1`}
                  >
                    <label
                      className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                      htmlFor="Dependence"
                    >
                      Inici
                    </label>
                    <Field
                      className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                      text-grey print:shadow-none print:border-none print:h-auto print:py-3.5 transition-all duration-200 ease-linear`}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                      name="StartDate"
                      type="date"
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        background: values.CourseState !== 1 && '#F2F2F2',
                        color: values.CourseState !== 1 && '#999999',
                        cursor:
                          values.CourseState !== 1 ? 'not-allowed' : 'pointer'
                      }}
                      disabled={values.CourseState !== 1}
                    />
                  </div>

                  {/* Campo 'Data Final': */}
                  <div
                    className={`w-full items-center block px-2 py-2 print:bg-white col-span-1`}
                  >
                    <label
                      className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                      htmlFor="Dependence"
                    >
                      Final
                    </label>
                    <Field
                      className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                      text-grey print:shadow-none print:border-none print:h-auto print:py-3.5 transition-all duration-200 ease-linear`}
                      onChange={(e) => {
                        handleChange(e)

                        !e.target.value && (values.CourseState = 1)
                        !e.target.value && (values.VerificationDate = false)
                      }}
                      name="EndDate"
                      type="date"
                      style={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        background: values.CourseState !== 1 && '#F2F2F2',
                        color: values.CourseState !== 1 && '#999999',
                        cursor:
                          values.CourseState !== 1 ? 'not-allowed' : 'pointer'
                      }}
                      disabled={values.CourseState !== 1}
                    />
                  </div>
                </div>
              </div>

              {/* Fila 3 (Cuadro de verificación | ¡NO SIEMPRE ESTARÁ VISIBILE, LÉAN LA DESCRIPCIÓN!): */}
              {/* Para que se muestre el cuadro de verificación de la formación hemos puesto las condiciones de que debería existir 
              una fecha que indique el final de la formación y que la fecha final sea anterior a la fecha actual, porque no se deberia 
              poder verificar una formación que no ha finalizado ni se deberia poder verificar una formación sin fecha final.
              Las condiciones las hemos añadido en los 'className' de los divs:  */}
              <div
                className={`w-full py-8 px-2 col-span-2 col-start-2 ${
                  !values.EndDate || values.EndDate > datetime ? 'hidden' : ''
                }`}
              >
                <div
                  className={`w-full h-full rounded-md grid items-start relative border-2 border-blue-200 transition-all duration-200 ease-linear
                ${values.CourseState === 2 ? 'bg-red-200' : 'bg-green-200'}`}
                  style={
                    values.CourseState === 2
                      ? { border: '2px solid #be0043' }
                      : { border: '2px solid green' }
                  }
                >
                  <div
                    className={`w-full h-full p-5 grid grid-cols-2 divide-x divide-dashed transition-all duration-200 ease-linear 
                  ${
                    values.CourseState === 2
                      ? 'divide-red-600'
                      : 'divide-green-600'
                  }`}
                  >
                    {/* Campo 'Valoració': */}
                    <div
                      className={`w-full items-center block pl-2 pr-6 py-2 print:bg-white`}
                    >
                      <label
                        className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Estadi
                      </label>
                      <Select
                        className="rounded text-grey-darker"
                        onChange={(e) => {
                          setFieldValue('CourseState', e.Id)

                          setChangedCourseState(e.Id)

                          // Si la opción que se elige es 'Correcte' o 'Incorrecte' pondremos la fecha de la verificación,
                          // en cambio, si se elige 'Pendent' no guardaremos ninguna fecha:
                          // e.Id === 1 || e.Id === 2 ? setFieldValue('CourseModifiedStateDate', datetime) : setFieldValue('CourseModifiedStateDate', '')
                          setFieldValue('CourseModifiedStateDate', datetime)
                        }}
                        name="CourseState"
                        placeholder="Selecciona..."
                        options={localCourseState}
                        getOptionLabel={(o) => o.Name}
                        getOptionValue={(o) => o.Id}
                        value={localCourseState.find(
                          (option) => option.Id === values.CourseState
                        )}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            border:
                              values.CourseState === 2
                                ? '2px solid #be0043'
                                : '2px solid green',
                            cursor: 'pointer'
                          })
                        }}
                      />
                    </div>

                    {/* Campo 'Data Verificació': */}
                    <div
                      className={`w-full items-center block pl-6 pr-2 py-2 print:bg-white`}
                    >
                      <label
                        className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                        htmlFor="Dependence"
                      >
                        Última modificació
                      </label>
                      <Field
                        className={`pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                        text-grey print:shadow-none print:border-none print:h-auto print:py-3.5 cursor-not-allowed`}
                        onChange={(e) => {
                          handleChange(e)
                        }}
                        name="CourseModifiedStateDate"
                        type="date"
                        style={
                          values.CourseState === 2
                            ? {
                                border: '2px solid #be0043',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                background: '#F2F2F2',
                                color: '#999999'
                              }
                            : {
                                border: '2px solid green',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                background: '#F2F2F2',
                                color: '#999999'
                              }
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Fila 4: */}
              <div
                className={`h-full col-span-4 w-full items-center block print:bg-white 
              ${!values.EndDate || values.EndDate > datetime ? 'mt-6' : ''}`}
              >
                {/* Campo 'Descripció': */}
                <div
                  className={`h-full col-span-4 w-full items-center block px-2 print:bg-white`}
                >
                  <label
                    className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                    htmlFor="Dependence"
                  >
                    Observacions
                  </label>
                  <Field
                    className={`h-40 pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-blue-200 outline-none 
                    text-grey print:shadow-none print:border-none print:h-auto print:py-3.5 transition-all duration-200 ease-linear`}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    name="Description"
                    placeholder="Introdueix..."
                    type="text"
                    component="textarea"
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      background: values.CourseState !== 1 && '#F2F2F2',
                      color: values.CourseState !== 1 && '#999999',
                      cursor:
                        values.CourseState !== 1 ? 'not-allowed' : 'pointer'
                    }}
                    disabled={values.CourseState !== 1}
                  />
                </div>
              </div>
            </div>

            {/* Botones de 'Guardar' y 'Activar': */}
            <div
              id="preven-guardar"
              className="flex flex-row justify-center col-span-1"
            >
              <div>
                <button
                  className={`block transition duration-300 
                  border-primary hover:border-blue-200 bg-primary text-white 
                  font-ms-semi mb-4 py-1 px-4 mr-2 ml-auto
                  border rounded h-10 w-28
                  hover:bg-blue-200 hover:text-primary
                  print:hidden ${values.MonitoringActivated ? '' : 'mr-2'}`}
                  type="submit"
                >
                  GUARDAR
                </button>
              </div>

              <div>
                <button
                  className={`block transition duration-300 
                  border-green-500 hover:border-blue-200 bg-green-500 text-white 
                  font-ms-semi mb-4 py-1 px-4 ml-auto
                  border rounded h-10
                  hover:bg-blue-200 hover:text-green-600
                  print:hidden ${values.MonitoringActivated ? 'hidden' : ''}`}
                  type="button"
                  onClick={(e) => {
                    setLocation('/pla-formacio/' + values.ForecastTraining_Id)
                  }}
                >
                  PLA FORMACIÓ
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {/* -------------------------------------------------------------------------------------------------------------------------------------- 
        INICIO TABLA TRABAJADORES APUNTADOS:
      -------------------------------------------------------------------------------------------------------------------------------------- */}
      <table className="w-full mt-4 mr-10 bg-white border-0 border-collapse rounded-lg shadow-md print:hidden border-spacing-0">
        <thead className="rounded-t-lg">
          <tr className="rounded-t-lg">
            <th
              className={`print:hidden h-14 w-100vw relative text-left rounded-t-lg
              ${
                addTreballadorsOpenState
                  ? 'bg-primary-dark duration-1000 ease-out'
                  : 'bg-primary duration-1000 ease-out'
              }`}
              colSpan={3}
            >
              <div
                className={`flex flex-row jusitfy-center items-center rounded-t-lg ${
                  addTreballadorsOpenState
                    ? 'divide-x divide-dashed divide-white'
                    : ''
                }`}
              >
                <div className="px-5 py-3 rounded-tl-lg bg-primary-dark">
                  <button
                    className={`p-2 font-ms-semi rounded-full transition-colors duration-300 focus:shadow-outline
                      text-primary bg-white hover:bg-blue-200 hover:text-primary`}
                    onClick={(e) => {
                      setAddTreballadorsOpenState(!addTreballadorsOpenState)
                    }}
                  >
                    <MdOutlineAdd
                      className={`${addTreballadorsOpenState ? 'hidden' : ''}`}
                      size={25}
                    />
                    <MdRemove
                      className={`${addTreballadorsOpenState ? '' : 'hidden'}`}
                      size={25}
                    />
                  </button>
                </div>

                <div
                  className={`bg-transparent h-full w-auto flex items-center ml-4 font-ms-bold text-sm text-white ${
                    addTreballadorsOpenState ? 'hidden' : ''
                  }`}
                >
                  <a className="text-lg">LLISTA DE TREBALLADORS APUNTATS</a>
                </div>

                <div
                  className={`bg-transparent h-full w-full px-20 ${
                    addTreballadorsOpenState ? 'pl-16' : 'hidden'
                  }
                grid grid-cols-12 font-normal`}
                >
                  <div className="col-span-11">
                    <Select
                      className="font-normal rounded"
                      onChange={(e) => {
                        setSelectedTreballador(e)
                        setSelectedTreballadorId(e.Id)
                      }}
                      placeholder="Selecciona..."
                      options={addTreballadorsToSelectList}
                      getOptionLabel={(o) => o.Name}
                      getOptionValue={(o) => o.Id}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          border: '2px solid #a1023a'
                        })
                      }}
                      value={getSelectedTreballador}
                    />
                  </div>

                  <div className="flex items-center justify-end w-auto col-span-1">
                    <button
                      className={`p-1.5 mt-auto mb-auto font-ms-semi rounded-full transition-colors duration-300 focus:shadow-outline
                      text-primary bg-white hover:bg-blue-200 hover:text-primary`}
                      // onClick={() => {
                      //   (getSelectedTreballador !== null && getSelectedTreballador !== undefined) && (uploadUser(getSelectedTreballador, fields.Id))

                      //   const getApiTreballadors = {
                      //     url: `treballador/trainingPlanUsers/${fields.Id}`,
                      //     method: 'GET',
                      //     messageKo: 'Error al recuperar els Treballadors!'
                      //   }
                      //   setApiTreballadors(getApiTreballadors)
                      // }}
                      onClick={() => {
                        const message = () =>
                          toast.warn('Selecciona un treballador!', {
                            position: toast.POSITION.TOP_CENTER
                          })

                        if (getSelectedTreballadorId) {
                          uploadUser(getSelectedTreballadorId, fields.Id)
                          clearSelectTreballadors()
                          setSelectedTreballadorId(null)
                        } else {
                          message()
                        }
                      }}
                    >
                      <MdPersonAdd size={25} />
                    </button>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-dashed">
          {/* Cuándo llamamos a la API para recoger los datos del seguimiento, en el campo 'TrainingPlanUsers' nos llegan
          todos los trabajadores que tiene adjuntos: */}
          {addUsersToTable?.length > 0 ? (
            addUsersToTable.map((doc) => (
              <tr
                key={doc.Id}
                className="grid grid-cols-12 border-primary-dark"
              >
                <td className="flex flex-row items-center justify-start col-span-2 p-3 pl-4 m-2">
                  {doc.Treballador.Name}
                </td>

                <td
                  className={`bg-gray-200 border-gray-400 border-2 col-span-9 rounded
              transition-all duration-200 ease-linear 
              ${
                changedCourseState
                  ? changedCourseState === 1
                    ? 'hidden'
                    : 'm-4 p-2'
                  : fields.CourseState === 1
                  ? 'hidden'
                  : 'm-4 p-2'
              }`}
                >
                  <Formik
                    enableReinitialize={true}
                    initialValues={{ ...doc }}
                    onSubmit={(values) => {
                      updateUser(values)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      submitForm
                    }) => (
                      <Form
                        onKeyDown={onKeyDown}
                        className="grid items-center w-full h-auto grid-cols-12"
                      >
                        {/* Campo 'Valoració': */}
                        <div className="items-center block w-full h-full col-span-2 px-2 py-2 print:bg-white">
                          <label
                            className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                            htmlFor="Dependence"
                          >
                            Valoració
                          </label>
                          <Select
                            className="rounded text-grey-darker"
                            onChange={(e) =>
                              setFieldValue('ValuationState', e.Id)
                            }
                            name={'ValuationState'}
                            placeholder="Selecciona..."
                            options={localValuationState}
                            getOptionLabel={(o) => o.Name}
                            getOptionValue={(o) => o.Id}
                            value={localValuationState.find(
                              (option) => option.Id === values.ValuationState
                            )}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                border: '2px solid rgb(156 163 175)',
                                cursor: 'pointer'
                              })
                            }}
                          />
                        </div>

                        {/* Campo 'Comentari': */}
                        <div className="items-center block w-full h-full col-span-9 px-2 py-2 transition-all duration-200 ease-linear print:bg-white">
                          <label
                            className="block mb-2 mr-2 leading-4 text-grey-darker text-md font-ms-semi col-start-full"
                            htmlFor="Dependence"
                          >
                            Comentari
                          </label>
                          <Field
                            className="pl-2 rounded w-full appearance-none py-1.5 px-3 p-2 border-2 border-gray-400 outline-none 
                          text-grey print:shadow-none print:border-none print:h-auto print:py-3.5"
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            name="Comment"
                            placeholder="Introdueix..."
                            type="text"
                          />
                        </div>

                        {/* Botón 'Guardar': */}
                        <div className="flex items-center justify-end w-full h-full col-span-1 px-2 py-2 print:bg-white">
                          <button
                            className="items-center justify-center block w-10 h-10 mt-6 text-white transition duration-300 border rounded cursor-pointer border-primary hover:border-blue-200 bg-primary font-ms-semi hover:bg-blue-200 hover:text-primary print:hidden"
                            type="submit"
                          >
                            <FaPencilAlt size={17} />
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </td>
                <td className="flex flex-row items-center justify-end col-span-1 col-start-12">
                  <button
                    className={`p-3 m-2 mr-2 ml-auto w-auto block text-sm rounded focus:shadow-outline hover:bg-gray-300 cursor-pointer`}
                    onClick={() =>
                      handleModal(
                        <DeleteModal
                          deleteUserOrFT={deleteUser}
                          element="fitxer"
                          id={doc.Id}
                          trainingPlanId={fields.Id}
                          closeModal={() => handleModal('hide')}
                        />
                      )
                    }
                  >
                    <FaTrash size={17} />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr className="border-primary-dark">
              <td>
                <p className="p-5 text-base">
                  No s&apos;han trobat treballadors apuntats per a aquesta
                  formació.
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* -------------------------------------------------------------------------------------------------------------------------------------- 
        FIN TABLA TRABAJADORES APUNTADOS.
      -------------------------------------------------------------------------------------------------------------------------------------- */}

      {/* -------------------------------------------------------------------------------------------------------------------------------------- 
        INICIO TABLA FICHEROS ADJUNTOS: 
      -------------------------------------------------------------------------------------------------------------------------------------- */}
      <table
        className={`print:hidden mt-4 shadow-md bg-white w-full mr-10 border-spacing-0 border-collapse rounded-lg border-0 overflow-hidden`}
      >
        <thead>
          <tr>
            <th
              className="relative text-sm text-left text-white print:hidden font-ms-bold h-14 w-100vw bg-primary"
              colSpan={2}
            >
              <div className="flex flex-row items-center jusitfy-center">
                <div className="px-5 py-3 bg-primary-dark">
                  <button
                    className={`p-2 font-ms-semi rounded-full transition-colors duration-300 focus:shadow-outline
                      text-primary bg-white hover:bg-blue-200 hover:text-primary`}
                    onClick={handleClick}
                  >
                    <MdOutlineUploadFile size={25} />
                  </button>
                </div>
                <div className="flex items-center w-auto h-full ml-4">
                  <a className="text-lg">
                    FITXERS ADJUNTS (CV, Firmes, Confidencialitat, Altres....)
                  </a>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-dashed">
          {/* Cuándo llamamos a la API para recoger los datos del trabajador, en el campo 'TreballadorDocuments' nos llegan
          todos los documentos que tiene adjuntos: */}
          {addDocumentsToTable?.length > 0 ? (
            addDocumentsToTable.map((doc) => (
              <tr key={doc.Id} className="border-primary-dark">
                <td>
                  <button className="block w-auto p-3 m-2 text-base rounded cursor-pointer focus:shadow-outline hover:bg-gray-300">
                    <a
                      className="text-md"
                      href={`https://apipreprod.cetriko.com/uploads/trainingPlan/${
                        params.id
                      }/documents/${encodeURIComponent(doc.Name)}`}
                      target="_blank"
                      rel="noreferrer"
                      download={
                        'Seguiment_Formacio_' +
                        params.id +
                        '_Document_' +
                        doc.Name
                      }
                    >
                      {doc.Name}
                    </a>
                  </button>
                </td>
                <td>
                  <button
                    className={`p-3 m-2 ml-auto mr-2 w-auto block text-sm rounded focus:shadow-outline hover:bg-gray-300 cursor-pointer`}
                    onClick={() =>
                      handleModal(
                        <DeleteModal
                          deleteUserOrFT={deleteDocument}
                          element="fitxer"
                          id={doc.Id}
                          closeModal={() => handleModal('hide')}
                        />
                      )
                    }
                  >
                    <FaTrash size={17} />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr className="border-primary-dark">
              <td>
                <p className="p-5 text-base">
                  No s&apos;han trobat fitxers adjunts.
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <input
        ref={hiddenFileInput}
        accept=".pdf, .xlsx"
        type="file"
        name="file"
        multiple="multiple"
        className="h-1/6 hidden py-2 ml-2 p-2.5 text-primary font-ms-semi transition-colors duration-150  bg-white border border-primary rounded-lg focus:shadow-outline hover:bg-secondary hover:text-white cursor-pointer text-grey-darker "
        onChange={(e) => uploadDocument(e)}
      />
      {/* -------------------------------------------------------------------------------------------------------------------------------------- 
        FIN TABLA FICHEROS ADJUNTOS. 
      -------------------------------------------------------------------------------------------------------------------------------------- */}
    </>
  ) : (
    <div className="inline-flex items-center justify-start w-full">
      <CgSpinner size={40} className="text-charcoal animate-spin" />
      <p className="pl-2 text-lg font-semibold text-charcoal">Carregant...</p>
    </div>
  )
}

TrainingPlanForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fields: PropTypes.object,
  uploadDocument: PropTypes.func,
  uploadUser: PropTypes.func,
  updateUser: PropTypes.func,
  deleteDocument: PropTypes.func,
  deleteUser: PropTypes.func,
  addDocumentsToTable: PropTypes.array,
  addUsersToTable: PropTypes.array,
  addTreballadorsToSelectList: PropTypes.array,
  params: PropTypes.object
}

export default TrainingPlanForm
