import pinyons from 'assets/icons/pinyons.png'
import PropTypes from 'prop-types'

export default function SprocketsForm({ machineCode }) {
  return (
    <div className="grid items-center h-full pt-10 pb-20 print:pt-1.5 print:pb-0">
      <div className="relative w-full h-full mx-auto">
        <img
          src={pinyons}
          alt="pinyons plegador"
          className="mx-auto mt-4"
          width={220}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Primera Fila de Arriba a Abajo (Derecha) */}
        <div className="absolute top-0 inline-flex items-center space-x-1 right-6">
          <div className="w-12 h-8 py-1 text-base leading-tight text-center bg-white border border-gray-300 rounded" />
          <span>&plusmn;</span>
          <div className="w-12 h-8 py-1 text-base leading-tight text-center bg-white border border-gray-300 rounded" />
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Segunda Fila de Arriba a Abajo (Izquierda) */}
        <div className="absolute inline-flex items-center space-x-1 print:top-10 print:mb-6 top-6 left-6">
          <div className="w-12 h-8 py-1 text-base leading-tight text-center bg-white border border-gray-300 rounded" />
          <span>&plusmn;</span>
          <div className="w-12 h-8 py-1 text-base leading-tight text-center bg-white border border-gray-300 rounded" />
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Tercera Fila de Arriba a Abajo (Derecha) */}
        <div className="absolute inline-flex items-center space-x-1 print:top-16 print:mb-6 top-12 right-6">
          <div className="w-12 h-8 py-1 text-base leading-tight text-center bg-white border border-gray-300 rounded" />
          <span>&plusmn;</span>
          <div className="w-12 h-8 py-1 text-base leading-tight text-center bg-white border border-gray-300 rounded" />
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Cuarta Fila de Arriba a Abajo (Izquierda) */}
        <div className="absolute print:top-24 print:mt-0 print:mb-6 top-16 left-6 inline-flex items-center space-x-1 mt-1.5">
          <div className="w-12 h-8 py-1 text-base leading-tight text-center bg-white border border-gray-300 rounded" />
          <span>&plusmn;</span>
          <div className="w-12 h-8 py-1 text-base leading-tight text-center bg-white border border-gray-300 rounded" />
        </div>

        {/* ---------------------------------- */}

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <div
          className={`h-8 bg-white absolute top-28 print:top-36 right-14 text-base 2xl:right-28 rounded border border-gray-300 text-center py-1 w-12 leading-tight ${
            machineCode === 'JMB' ? 'hidden' : 'block'
          }`}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <div
          className={`h-8 bg-white absolute print:top-32 print:w-12 print:right-44 sm:top-20 sm:right-28 sm:w-10 lg:top-28 2xl:right-44 rounded border border-gray-300 text-center py-1 lg:w-12 leading-tight ${
            machineCode === 'JMB' ? 'hidden' : 'block'
          }`}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <div
          className={`h-8 bg-white absolute print:top-40 print:w-12 print:left-20 sm:w-12 lg:text-base sm:left-9 sm:top-24 lg:top-36 2xl:left-28 rounded border border-gray-300 text-center py-1 lg:w-12 leading-tight ${
            machineCode === 'JMB' ? 'hidden' : 'block'
          }`}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <div
          className={`h-8 bg-white absolute print:top-40 print:w-12 print:right-44 sm:top-28 sm:right-28 sm:w-10 lg:top-36 2xl:right-44 rounded border border-gray-300 text-center py-1 lg:w-12 leading-tight ${
            machineCode === 'JMB' ? 'hidden' : 'block'
          }`}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <div
          className={`h-8 bg-white absolute print:top-48 print:w-12 print:left-20 sm:top-32 sm:left-9 lg:top-44 2xl:left-28 rounded border border-gray-300 text-center py-1 w-12 leading-tight ${
            machineCode === 'JMB' ? 'hidden' : 'block'
          }`}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <div
          className={`h-8 bg-white absolute print:top-48 print:w-12 print:right-44 sm:top-36 sm:w-10 lg:top-44 right-28 2xl:right-44 rounded border border-gray-300 text-center py-1 lg:w-12 leading-tight ${
            machineCode === 'JMB' ? 'hidden' : 'block'
          }`}
        />

        {/* ---------------------------------- */}

        {/* JMB -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <div
          className={`h-8 bg-white absolute print:top-40 print:mt-1 print:w-12 print:right-28 print:mr-1 sm:top-28 sm:right-28 sm:w-10 lg:top-28 2xl:right-32 rounded border border-gray-300 text-center py-1 lg:w-12 leading-tight ${
            machineCode === 'JMB' ? 'block' : 'hidden'
          }`}
        />

        {/* JMB -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <div
          className={`h-8 bg-white absolute print:top-40 print:mt-1 print:w-12 print:right-64 print:mr-2 sm:top-28 sm:right-28 sm:w-10 lg:top-28 2xl:right-56 rounded border border-gray-300 text-center py-1 lg:w-12 leading-tight ${
            machineCode === 'JMB' ? 'block' : 'hidden'
          }`}
        />

        {/* JMB -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <div
          className={`h-8 bg-white absolute print:top-48 print:mt-2 print:w-12 print:right-64 print:mr-2 sm:top-28 sm:right-28 sm:w-10 lg:top-36 2xl:right-56 rounded border border-gray-300 text-center py-1 lg:w-12 leading-tight ${
            machineCode === 'JMB' ? 'block' : 'hidden'
          }`}
        />

        {/* JMB -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <div
          className={`h-8 bg-white absolute print:top-48 print:mt-2 print:left-6 print:w-12 sm:top-28 left-4 sm:w-10 lg:top-36 rounded border border-gray-300 text-center py-1 lg:w-12 leading-tight ${
            machineCode === 'JMB' ? 'block' : 'hidden'
          }`}
        />
      </div>
    </div>
  )
}

SprocketsForm.propTypes = {
  machineCode: PropTypes.string.isRequired
}
