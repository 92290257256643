import pinyons from 'assets/icons/pinyons.png'
import PropTypes from 'prop-types'

export default function SprocketsForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      <div className="relative w-full h-full col-span-12 mx-auto">
        <img
          src={pinyons}
          alt="pinyons plegador"
          className="mx-auto my-8"
          width={220}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Primera Fila de Arriba a Abajo (Derecha) */}
        <div
          className={`${
            valuesBefore?.Piston2 !== valuesAfter?.Piston2
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } absolute top-0 w-12 p-0.5 leading-tight bg-white border border-gray-300 rounded right-20`}
        >
          {side === 'left' ? valuesBefore?.Piston2 : valuesAfter?.Piston2}
        </div>
        <div className="absolute top-0 right-6">
          &plusmn;
          <div
            className={`${
              valuesBefore?.PistonTolerance2 !== valuesAfter?.PistonTolerance2
                ? side === 'left'
                  ? 'bg-red-100 border-red-300'
                  : isAnswered
                  ? 'bg-emerald-100 border-emerald-300'
                  : 'bg-amber-100 border-amber-300'
                : 'border-gray-300 bg-gray-100'
            } w-8 p-0.5 text-base leading-tight bg-white border border-gray-300 rounded `}
          >
            {side === 'left'
              ? valuesBefore?.PistonTolerance2
              : valuesAfter?.PistonTolerance2}
          </div>
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Segunda Fila de Arriba a Abajo (Izquierda) */}
        <div
          className={`${
            valuesBefore?.Piston1 !== valuesAfter?.Piston1
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } absolute w-12 p-0.5 leading-tight bg-white border border-gray-300 rounded sm:top-5 lg:top-6 left-4`}
        >
          {side === 'left' ? valuesBefore?.Piston1 : valuesAfter?.Piston1}
        </div>
        <div className="absolute inline-flex items-center sm:top-5 sm:left-20 lg:top-6 lg:left-20">
          &plusmn;
          <div
            className={`${
              valuesBefore?.PistonTolerance1 !== valuesAfter?.PistonTolerance1
                ? side === 'left'
                  ? 'bg-red-100 border-red-300'
                  : isAnswered
                  ? 'bg-emerald-100 border-emerald-300'
                  : 'bg-amber-100 border-amber-300'
                : 'border-gray-300 bg-gray-100'
            } w-8 p-0.5 leading-tight bg-white border border-gray-300 rounded`}
          >
            {side === 'left'
              ? valuesBefore?.PistonTolerance1
              : valuesAfter?.PistonTolerance1}
          </div>
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Tercera Fila de Arriba a Abajo (Derecha) */}
        <div
          className={`${
            valuesBefore?.Piston4 !== valuesAfter?.Piston4
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } absolute w-12 p-0.5 leading-tight bg-white border border-gray-300 rounded lg:top-12 right-20 sm:top-12 lg:text-base`}
        >
          {side === 'left' ? valuesBefore?.Piston4 : valuesAfter?.Piston4}
        </div>
        <div className="absolute inline-flex items-center top-12 right-6">
          &plusmn;
          <div
            className={`${
              valuesBefore?.PistonTolerance4 !== valuesAfter?.PistonTolerance4
                ? side === 'left'
                  ? 'bg-red-100 border-red-300'
                  : isAnswered
                  ? 'bg-emerald-100 border-emerald-300'
                  : 'bg-amber-100 border-amber-300'
                : 'border-gray-300 bg-gray-100'
            } w-8 p-0.5 text-base leading-tight bg-white border border-gray-300 rounded`}
          >
            {side === 'left'
              ? valuesBefore?.PistonTolerance4
              : valuesAfter?.PistonTolerance4}
          </div>
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Cuarta Fila de Arriba a Abajo (Izquierda) */}
        <div
          className={`${
            valuesBefore?.Piston3 !== valuesAfter?.Piston3
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } absolute p-0.5 leading-tight bg-white border border-gray-300 rounded sm:bottom-20 lg:bottom-16 sm:w-12 left-4 lg:w-12`}
        >
          {side === 'left' ? valuesBefore?.Piston3 : valuesAfter?.Piston3}
        </div>
        <div className="absolute inline-flex items-center sm:left-20 bottom-16 lg:left-20">
          &plusmn;
          <div
            className={`${
              valuesBefore?.PistonTolerance3 !== valuesAfter?.PistonTolerance3
                ? side === 'left'
                  ? 'bg-red-100 border-red-300'
                  : isAnswered
                  ? 'bg-emerald-100 border-emerald-300'
                  : 'bg-amber-100 border-amber-300'
                : 'border-gray-300 bg-gray-100'
            } p-0.5 leading-tight bg-white border border-gray-300 rounded sm:w-8 lg:w-8`}
          >
            {side === 'left'
              ? valuesBefore?.PistonTolerance3
              : valuesAfter?.PistonTolerance3}
          </div>
        </div>

        {/* ---------------------------------- */}

        {((side === 'left' && valuesBefore?.machineCode !== 'JMB') ||
          (side === 'right' && valuesAfter?.machineCode !== 'JMB')) && (
          <>
            {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
            <div
              className={`${
                valuesBefore?.Piston7 !== valuesAfter?.Piston7
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              } absolute bg-white sm:top-20 sm:right-28 sm:w-10 lg:top-28 2xl:right-44 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
            >
              {side === 'left' ? valuesBefore?.Piston7 : valuesAfter?.Piston7}
            </div>

            {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
            <div
              className={`${
                valuesBefore?.Piston6 !== valuesAfter?.Piston6
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              } absolute bg-white top-28 right-14 text-base 2xl:right-28 rounded border border-gray-300 p-0.5 w-12 leading-tight`}
            >
              {side === 'left' ? valuesBefore?.Piston6 : valuesAfter?.Piston6}
            </div>

            {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
            <div
              className={`${
                valuesBefore?.Piston5 !== valuesAfter?.Piston5
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              } absolute bg-white sm:w-12 lg:text-base sm:left-9 sm:top-24 lg:top-36 2xl:left-28 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
            >
              {side === 'left' ? valuesBefore?.Piston5 : valuesAfter?.Piston5}
            </div>

            {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
            <div
              className={`${
                valuesBefore?.Piston8 !== valuesAfter?.Piston8
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              } absolute bg-white sm:top-28 sm:right-28 sm:w-10 lg:top-36 2xl:right-44 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
            >
              {side === 'left' ? valuesBefore?.Piston8 : valuesAfter?.Piston8}
            </div>

            {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
            <div
              className={`absolute bg-white sm:top-32 sm:left-9 lg:top-44 2xl:left-28 rounded border border-gray-300 p-0.5 w-12 leading-tight ${
                valuesBefore?.Piston9 !== valuesAfter?.Piston9
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              }`}
            >
              {side === 'left' ? valuesBefore?.Piston9 : valuesAfter?.Piston9}
            </div>

            {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
            <div
              className={`${
                valuesBefore?.Piston10 !== valuesAfter?.Piston10
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              } bg-white absolute sm:top-36 sm:w-10 lg:top-44 right-28 2xl:right-44 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
            >
              {side === 'left' ? valuesBefore?.Piston10 : valuesAfter?.Piston10}
            </div>
          </>
        )}

        {/* ---------------------------------- */}

        {/* JMB -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        {((side === 'left' && valuesBefore?.machineCode === 'JMB') ||
          (side === 'right' && valuesAfter?.machineCode === 'JMB')) && (
          <>
            {/* JMB -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
            <div
              className={`${
                valuesBefore?.JUM1 !== valuesAfter?.JUM1
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              } absolute bg-white sm:top-28 sm:right-28 sm:w-10 lg:top-28 2xl:right-32 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
            >
              {side === 'left' ? valuesBefore?.JUM1 : valuesAfter?.JUM1}
            </div>

            {/* JMB -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
            <div
              className={`${
                valuesBefore?.JUM2 !== valuesAfter?.JUM2
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              } absolute bg-white sm:top-28 sm:right-28 sm:w-10 lg:top-28 2xl:right-56 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
            >
              {side === 'left' ? valuesBefore?.JUM2 : valuesAfter?.JUM2}
            </div>

            {/* JMB -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
            <div
              className={`${
                valuesBefore?.JUM3 !== valuesAfter?.JUM3
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              } absolute bg-white sm:top-28 sm:right-28 sm:w-10 lg:top-36 2xl:right-56 rounded border border-gray-300 text-center p-0.5 lg:w-12 leading-tight`}
            >
              {side === 'left' ? valuesBefore?.JUM3 : valuesAfter?.JUM3}
            </div>

            {/* JMB -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
            <div
              className={`${
                valuesBefore?.JUM4 !== valuesAfter?.JUM4
                  ? side === 'left'
                    ? 'bg-red-100 border-red-300'
                    : isAnswered
                    ? 'bg-emerald-100 border-emerald-300'
                    : 'bg-amber-100 border-amber-300'
                  : 'border-gray-300 bg-gray-100'
              } absolute bg-white sm:top-28 left-4 sm:w-10 lg:top-36 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
            >
              {side === 'left' ? valuesBefore?.JUM4 : valuesAfter?.JUM4}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

SprocketsForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
