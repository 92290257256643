import corroEnrotllador from 'assets/icons/corro-enrotllador.png'
import pinyonsPlegador from 'assets/icons/pinyons-plegador.png'
import tensioPlegador from 'assets/icons/tensio-plegador.png'
import PropTypes from 'prop-types'

export default function FolderForm({ machineCode }) {
  const inputStyles =
    'w-full py-0.5 print:py-0 px-2 bg-white border-2 rounded border-gray-300 appearance-none outline-none print:rounded-none print:border print:border-l-0 print:border-t-0 print:border-r-0'

  return (
    <div className="grid h-full grid-cols-3 divide-x divide-blue-200 divide-dashed">
      {/* Campos si 'machineCode' es 'PAL': A. Calandra */}
      {/* Campos si 'machineCode' es 'MCH': A. Calandra, Posició */}
      {/* Campos si 'machineCode' es 'SNT': A. Calandra, Pinyons Plegador */}
      {/* Campos si 'machineCode' es 'MYR': A. Calandra, Pinyons Plegador, Tensió */}
      {/* Campos si 'machineCode' es 'TRT': A. Calandra, Posició, Torque */}
      {/* Campos si 'machineCode' es 'JMB': A. Calandra, ?, Pinyons Plegador, Tensió Plegador */}
      {/* Campos si 'machineCode' es 'PLT': A. Calandra, Delta, Pinyons Plegador, Tensió del Plegador, Corró enrotllador */}

      {/* Columna 1: */}
      <div className="flex flex-col col-span-1 p-2 space-y-2">
        {/* Campo 'A. Calandra': */}
        {/* Se muestra en todos los tipos de 'machineCode', en 'JMB', 'PLT', 'PAL', 'TRT', 'MCH', 'MYR' y 'SNT': */}
        <div className="flex flex-row w-full">
          <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi print:w-7/12">
            A. Calandra:
          </p>

          <div className="flex flex-row items-center justify-center flex-grow">
            <input className={inputStyles} disabled />
          </div>
        </div>

        {/* ------------------------------ */}

        {/* Campo 'Delta': */}
        {/* Se muestra si 'machineCode' es 'PLT': */}
        <div
          className={`w-full flex flex-row ${
            machineCode === 'PLT' ? 'block' : 'hidden'
          }`}
        >
          <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Delta:
          </p>

          <div className="flex flex-row items-center justify-center flex-grow">
            <input className={inputStyles} disabled />
          </div>
        </div>

        {/* ------------------------------ */}

        {/* Campos 'Piston JUM y Pinyons Plegador (3, 4)': */}
        {/* Se muestra si 'machineCode' es 'JMB': */}
        <div
          className={`w-full flex flex-col space-y-2 ${
            machineCode === 'JMB' ? 'block' : 'hidden'
          }`}
        >
          <div className="relative w-full">
            <img
              className="print:w-40"
              src={pinyonsPlegador}
              alt="pinyons plegador"
              width={150}
            />

            <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:top-1 print:left-10 print:w-16 sm:top-0 sm:left-8 sm:w-10 lg:top-3 lg:left-14 lg:w-20" />
            <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:top-10 print:w-16 print:left-10 sm:top-6 sm:left-8 sm:w-10 lg:top-14 lg:left-14 lg:w-20" />
          </div>

          <div className="flex flex-col w-full">
            <p className="flex items-center w-full py-1 text-sm text-grey-darker font-ms-semi print:py-0">
              Pinyons Plegador (3, 4):
            </p>

            <div className="relative">
              <img
                className="print:w-40"
                src={pinyonsPlegador}
                alt="pinyons plegador"
                width={150}
              />

              <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:top-1 print:left-10 print:w-16 sm:top-0 sm:left-8 sm:w-10 lg:top-3 lg:left-14 lg:w-20" />
              <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:top-10 print:w-16 print:left-10 sm:top-6 sm:left-8 sm:w-10 lg:top-14 lg:left-14 lg:w-20" />
            </div>
          </div>
        </div>

        {/* Campo 'Pinyons Plegador (1, 2)': */}
        {/* Se muestra si 'machineCode' es 'MYR', 'SNT', 'PLT', 'JMB': */}
        <div
          className={`w-full ${
            machineCode === 'TRT' ||
            machineCode === 'PAL' ||
            machineCode === 'MCH' ||
            machineCode === 'JMB'
              ? 'hidden'
              : 'flex flex-col'
          }`}
        >
          <p className="flex items-center w-full py-1 text-sm text-grey-darker font-ms-semi print:py-0">
            Pinyons Plegador (1, 2):
          </p>

          <div className="relative">
            <img
              className="print:w-40"
              src={pinyonsPlegador}
              alt="pinyons plegador"
              width={150}
            />

            <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:top-1 print:left-10 print:w-16 sm:top-0 sm:left-8 sm:w-10 lg:top-3 lg:left-14 lg:w-20" />

            <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:top-10 print:w-16 print:left-10 sm:top-6 sm:left-8 sm:w-10 lg:top-14 lg:left-14 lg:w-20" />
          </div>
        </div>
      </div>

      {/* Columna 2: */}
      <div className="flex flex-col col-span-1 p-2">
        {/* Campo 'Tensió Plegador (1, 2)': */}
        {/* Se muestra si 'machineCode' es 'PLT': */}
        <div
          className={`w-full flex flex-col ${
            machineCode === 'PLT' ? 'block' : 'hidden'
          }`}
        >
          <p className="flex items-center w-full pb-3 text-sm text-grey-darker font-ms-semi print:pb-2">
            Tensió Plegador (1, 2):
          </p>

          <div className="grid w-full grid-cols-2 gap-x-2">
            <div className="flex flex-col justify-center col-span-1 space-y-2">
              <div className="flex flex-row w-full">
                <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P1:
                </p>

                <div className="flex flex-row items-center justify-center flex-grow">
                  <input className={inputStyles} disabled />
                </div>
              </div>

              <div className="flex flex-row w-full">
                <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P2:
                </p>

                <div className="flex flex-row items-center justify-center flex-grow">
                  <input className={inputStyles} disabled />
                </div>
              </div>

              <div className="flex flex-row w-full">
                <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
                  P3:
                </p>

                <div className="flex flex-row items-center justify-center flex-grow">
                  <input className={inputStyles} disabled />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center col-span-1">
              <div className="relative">
                <img
                  src={tensioPlegador}
                  className="print:w-40"
                  alt="pinyons plegador"
                  width={100}
                />

                <div className="absolute top-0 h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded lg:left-0 print:left-6 print:w-4/6 sm:left-8 sm:w-10 lg:w-16" />
                <div className="absolute bottom-0 h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded appearance-none sm:w-10 print:left-6 print:w-4/6 sm:left-8 lg:left-0 lg:w-16" />
              </div>
            </div>
          </div>
        </div>

        {/* Campo 'Tensió Plegador (3, 4)': */}
        {/* Se muestra si 'machineCode' es 'JMB': */}
        <div
          className={`w-full flex flex-col ${
            machineCode === 'JMB' ? 'block' : 'hidden'
          }`}
        >
          <p className="flex items-center w-full pb-1 text-sm text-grey-darker font-ms-semi print:py-0">
            Tensó Plegador (3, 4):
          </p>

          <div className="relative w-full">
            <img
              className="print:w-40"
              src={pinyonsPlegador}
              alt="pinyons plegador"
              width={150}
            />

            <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:top-1 print:left-10 print:w-16 sm:top-0 sm:left-8 sm:w-10 lg:top-3 lg:left-14 lg:w-20" />
            <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:top-10 print:w-16 print:left-10 sm:top-6 sm:left-8 sm:w-10 lg:top-14 lg:left-14 lg:w-20" />
          </div>
        </div>

        {/* Campo 'Posició': */}
        {/* Se muestra si 'machineCode' es 'MCH': */}
        <div
          className={`w-full flex flex-row ${
            machineCode === 'MCH' ? 'block' : 'hidden'
          }`}
        >
          <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Posició:
          </p>

          <div className="flex flex-row items-center justify-center flex-grow">
            <input className={inputStyles} disabled />
          </div>
        </div>

        {/* Campos 'Posició y Torque': */}
        {/* Se muestra si 'machineCode' es 'TRT': */}
        <div
          className={`w-full flex flex-row ${
            machineCode === 'TRT' ? 'block' : 'hidden'
          }`}
        >
          <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Posició:
          </p>

          <div className="flex flex-row items-center justify-center flex-grow">
            <input className={inputStyles} disabled />
          </div>
        </div>
        <div
          className={`w-full flex flex-row pt-2 ${
            machineCode === 'TRT' ? 'block' : 'hidden'
          }`}
        >
          <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Torque:
          </p>

          <div className="flex flex-row items-center justify-center flex-grow">
            <input className={inputStyles} disabled />
          </div>
        </div>

        {/* Campo 'Tensió': */}
        {/* Se muestra si 'machineCode' es 'MYR': */}
        <div
          className={`w-full flex flex-row ${
            machineCode === 'MYR' ? 'block' : 'hidden'
          }`}
        >
          <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
            Tensió:
          </p>

          <div className="flex flex-row items-center justify-center flex-grow">
            <input className={inputStyles} disabled />
          </div>
        </div>
      </div>

      {/* Columna 3: */}
      <div className="flex flex-col col-span-1 p-2">
        {/* Campo 'Corró enrotllador': */}
        {/* Se muestra si 'machineCode' es 'PLT': */}
        <div
          className={`w-full flex flex-col ${
            machineCode === 'PLT' ? 'block' : 'hidden'
          }`}
        >
          <p className="flex items-center w-full pb-3 text-sm text-grey-darker font-ms-semi print:pb-2">
            Corró enrotllador:
          </p>

          <div className="relative w-full">
            <img
              src={corroEnrotllador}
              alt="pinyons plegador"
              width={120}
              className="mx-auto print:w-28 sm:w-20 lg:w-28"
            />

            <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:w-10 print:top-0 sm:text-xs lg:text-base sm:top-0 sm:left-0 top-6 sm:w-10 lg:left-10 lg:w-16" />
            <div className="absolute top-0 h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:w-10 print:right-4 sm:w-10 sm:text-xs lg:text-base sm:right-0 lg:right-11 lg:w-16" />
            <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:w-10 sm:w-10 print:-bottom-2 sm:text-xs lg:text-base sm:left-0 bottom-6 lg:left-10 lg:w-16" />
            <div className="absolute bottom-0 h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:w-10 sm:w-10 print:right-4 sm:text-xs lg:text-base sm:right-0 lg:right-10 lg:w-16" />
          </div>
        </div>
      </div>
    </div>
  )
}

FolderForm.propTypes = {
  machineCode: PropTypes.string.isRequired
}
