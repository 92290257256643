import esquemaTeixiduria from 'assets/img/esquema-teixiduria.png'
import ToolTipIcon from 'components/icons/ToolTipIcon'
import { ModalContext } from 'contexts/ModalContext'
import { Field } from 'formik'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import Select from 'react-select'
import ThreadTable from './threadTableCreate'

export default function DescriptionForm({
  setFieldValue,
  values,
  threads,
  setRequestFieldsState,
  fullValues
}) {
  // Context
  const { handleModal } = useContext(ModalContext)

  // Fetch
  const [getApiCallGetSemiFinished, setApiCallSemiFinished] = useFetch()
  const [getApiCallGetMachines, setApiCallMachines] = useFetch()
  const [getApiCallGetThreads, setApiCallThreads] = useFetch()

  // Vars
  const optionsYesNo = [
    { value: true, label: 'Si' },
    { value: false, label: 'No' }
  ]

  if (
    values?.SecurityStepDescription === undefined ||
    !values?.SecurityStepDescription
  )
    values.SecurityStepDescription = 'Segons cartell'

  useEffect(async () => {
    // CRUD: GET (Semifinished):
    const apiCallGetSemiFinished = {
      url: `semifinished`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    // CRUD: GET (Machines):
    const apiCallGetMachines = {
      url: `tsweaving/machines`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    // CRUD: GET (Threads):
    const apiCallGetThreads = {
      url: `thread`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    await Promise.all([
      setApiCallSemiFinished(apiCallGetSemiFinished),
      setApiCallMachines(apiCallGetMachines),
      setApiCallThreads(apiCallGetThreads)
    ]).catch((e) => {
      console.log(e)
    })
  }, [])

  // -------------------------------

  const update4 = () => {
    // #################################################################################################
    // Inicio cálculos de 'Vueltas'
    // #################################################################################################

    const oldKgPart = fullValues?.RequestArticle?.HistoryArticle?.KgPart

    // Recogemos todas las máquinas relacionadas con el 'id' especificado y sumamos los 'Kg' por 'Hora'
    // de todas las máquinas que se devuelvan, juntas:
    let sumKgH100 = 0
    if (
      fullValues?.RequestMachineGroups &&
      fullValues?.RequestMachineGroups?.length > 0
    ) {
      fullValues?.RequestMachineGroups.forEach((machine, index) => {
        const updatedMachineValue = update4B(machine, index)

        // Redondeamos a 10 para que todos los cálculos se hagan con 10 decimales:
        const roundedMachineGroupKgH = parseFloat(
          updatedMachineValue.KgH.toFixed(10)
        )
        sumKgH100 = parseFloat((sumKgH100 + roundedMachineGroupKgH).toFixed(10))
      })

      if (sumKgH100 > 0) {
        // Recogemos 'RPM (Revoluciones Por Minuto) y 'KgPart (Kg por pieza)':
        let Rpm = 0
        const RpmSubstitution = fullValues?.RequestArticle?.Rpm?.toString()
        Rpm = parseFloat(RpmSubstitution).toFixed(1)
        setFieldValue(
          'RequestArticle.Rpm',
          Number(Rpm?.toString().replace(/,/g, '.') ?? 0).toFixed(
            Rpm % 1 === 0 ? 0 : 1
          )
        )

        let partKg = 0
        if (fullValues?.RequestArticle?.KgPart == null) {
          setFieldValue('RequestArticle.KgPart', '0')
        } else {
          const partKgSubstitution =
            fullValues?.RequestArticle?.KgPart.toString()
          const partKgReplaced = partKgSubstitution.replace('.', ',')
          partKg = parseFloat(partKgReplaced.replace(',', '.')).toFixed(1)

          setFieldValue(
            'RequestArticle.KgPart',
            Number(partKg?.toString().replace(/,/g, '.') ?? 0).toFixed(
              partKg % 1 === 0 ? 0 : 1
            )
          )
        }

        // Convertimos la suma de 'Kg' por 'Hora' de las máquinas que se nos devuelve de la Base de Datos
        // a un número con 2 decimales y otro con 1 decimal:
        const roundedSumKgH10Decimals = parseFloat(sumKgH100.toFixed(10))

        if (Rpm !== 0) {
          // Total 'Vueltas' por 'Kg' y redondeamos a 1 decimales:
          const turnsPerKg = parseFloat(
            ((Rpm * 60) / roundedSumKgH10Decimals).toFixed(10)
          )

          // Total 'Vueltas' por 'Kg' y redondeamos a 0 decimales:
          const turnsPerPart = parseFloat(
            (((Rpm * 60) / roundedSumKgH10Decimals) * partKg).toFixed(10)
          )
          setFieldValue(
            'RequestArticle.TurnsPerKg',
            Math.round(Number(turnsPerKg?.toString().replace(/,/g, '.') ?? 0))
          )
          setFieldValue(
            'RequestArticle.TurnsPerPart',
            Math.round(Number(turnsPerPart?.toString().replace(/,/g, '.') ?? 0))
          )

          if (
            oldKgPart !== fullValues?.RequestArticle?.RequestArticle?.KgPart
          ) {
            setFieldValue(
              'RequestArticle.TurnsPerPartByOperator',
              Math.round(
                Number(turnsPerPart?.toString().replace(/,/g, '.') ?? 0)
              )
            )
          }
        } else {
          setFieldValue('RequestArticle.TurnsPerKg', '0')
          setFieldValue('RequestArticle.TurnsPerPart', '0')
        }

        // #################################################################################################
        // Fin cálculos de 'Vueltas'
        // #################################################################################################

        // #################################################################################################
        // Inicio cálculos de 'KgH_100', 'KgD_100', 'KgH_80' y 'KgD_80'
        // #################################################################################################

        // Total 'Kg' por 'Hora' cuando la máquina está al 100% y redondeamos a 10 decimales:
        const totalKgH100 = parseFloat(roundedSumKgH10Decimals.toFixed(2))

        // Total 'Kg' por 'Hora' cuando la máquina está al 80% y redondeamos a 10 decimales:
        const totalKgH80 = parseFloat(
          (roundedSumKgH10Decimals * 0.8).toFixed(2)
        )

        // Total 'Kg' por 'Dia' cuando la máquina está al 100% y redondeamos a 10 decimales:
        const totalKgD100 = parseFloat(
          (roundedSumKgH10Decimals * 24).toFixed(2)
        )

        // Total 'Kg' por 'Dia' cuando la máquina está al 80% y redondeamos a 10 decimales:
        const totalKgD80 = parseFloat(
          (roundedSumKgH10Decimals * 24 * 0.8).toFixed(2)
        )

        setFieldValue('RequestArticle.KgH_100', totalKgH100.toString())
        setFieldValue('RequestArticle.KgH_80', totalKgH80.toString())
        setFieldValue('RequestArticle.KgD_100', totalKgD100.toString())
        setFieldValue('RequestArticle.KgD_80', totalKgD80.toString())

        // #################################################################################################
        // Fin cálculos de 'KgH_100', 'KgD_100', 'KgH_80' y 'KgD_80'
        // #################################################################################################
      } else {
        setFieldValue('RequestArticle.TurnsPerKg', '0')
        setFieldValue('RequestArticle.TurnsPerPart', '0')
        setFieldValue('RequestArticle.TurnsPerPartByOperator', '0')
        setFieldValue('RequestArticle.KgH_100', '0')
        setFieldValue('RequestArticle.KgH_80', '0')
        setFieldValue('RequestArticle.KgD_100', '0')
        setFieldValue('RequestArticle.KgD_80', '0')
      }
    } else {
      setFieldValue('RequestArticle.TurnsPerKg', '0')
      setFieldValue('RequestArticle.TurnsPerPart', '0')
      setFieldValue('RequestArticle.TurnsPerPartByOperator', '0')
      setFieldValue('RequestArticle.KgH_100', '0')
      setFieldValue('RequestArticle.KgH_80', '0')
      setFieldValue('RequestArticle.KgD_100', '0')
      setFieldValue('RequestArticle.KgD_80', '0')
    }
  }

  const update4B = (currentMachineGroup, index) => {
    // Recogemos el número de 'Agujas':
    if (fullValues?.RequestArticle?.Needle == null)
      setFieldValue('RequestArticle.Needle', '0')

    const needles = getNumber(fullValues?.RequestArticle?.Needle)

    // Recogemos las 'RPM (Revoluciones Por Minuto)':
    let Rpm = 0
    const RpmSubstitution = fullValues?.RequestArticle?.Rpm.toString()
    Rpm = parseFloat(RpmSubstitution).toFixed(1)
    setFieldValue(
      'RequestArticle.Rpm',
      Number(Rpm?.toString().replace(/,/g, '.') ?? 0).toFixed(
        Rpm % 1 === 0 ? 0 : 1
      )
    )

    // Calculation LFA
    let LFA = 0
    if (currentMachineGroup.Spending !== 0 && needles !== 0) {
      LFA = parseFloat(
        ((currentMachineGroup.Spending / needles) * 100).toFixed(2)
      )
    }

    // Recogemos los campos 'Title' y 'Heads'
    const numCabos = currentMachineGroup.Heads
    let numTitle = 0
    if (currentMachineGroup.Title !== null) {
      numTitle = getNumber(currentMachineGroup.Title)
    }

    // Comprobamos la unidad de medida de la máquina (TEX, DTEX, DNS, NM, NE, NA o NB):
    const measureUnitTitle = currentMachineGroup.Title
      ? currentMachineGroup.Title.split(' ').pop().toUpperCase()
      : ''

    // Fórmulas según Excel: Cálculo producciones (Tickets relacionados: #29473, #33208, #89001).
    // - Más información sobre las fórmulas y las conversiones en el Jedi: https://jedi.gestinet.com/books/cetriko/page/formules-de-produccio-fitxes-tecniques-teixiduria
    let convertToTex = 0

    // JavaScript uses triple equals for comparison and toUpperCase() to handle case insensitivity.
    if (measureUnitTitle.toUpperCase() === 'TEX') {
      convertToTex = Number((numTitle * numCabos).toFixed(10))
    } else if (measureUnitTitle.toUpperCase() === 'DTEX') {
      convertToTex = Number(((numTitle * numCabos) / 10).toFixed(10))
    } else if (measureUnitTitle.toUpperCase() === 'DNS') {
      convertToTex = Number(((numTitle * numCabos) / 9).toFixed(10))
    } else if (measureUnitTitle.toUpperCase() === 'NM') {
      convertToTex = Number((1000 / (numTitle / numCabos)).toFixed(10))
    } else if (measureUnitTitle.toUpperCase() === 'NE') {
      convertToTex = Number(
        ((453.6 / 768) * (1000 / (numTitle / numCabos))).toFixed(10)
      )
    } else if (measureUnitTitle.toUpperCase() === 'NA') {
      convertToTex = Number(
        ((440 / 777.5) * (1000 / (numTitle / numCabos))).toFixed(10)
      )
    } else if (measureUnitTitle.toUpperCase() === 'NB') {
      convertToTex = Number(
        ((8.33 / 777.5) * (1000 * (numTitle / numCabos))).toFixed(10)
      )
    } else {
      convertToTex = 0
    }

    // Ticket relacionat: #33208
    // - Calcular Kg/h:
    let updateKgH = 0

    // Función nueva para calcular Kg/h y redondeamos a 10 decimales:
    if (Rpm !== 0) {
      updateKgH = parseFloat(
        (
          (convertToTex *
            currentMachineGroup.Spending *
            Rpm *
            60 *
            currentMachineGroup.Cones) /
          100000000
        ).toFixed(10)
      )
      currentMachineGroup.KgH = updateKgH
    } else {
      currentMachineGroup.KgH = 0
    }

    currentMachineGroup.LFA = LFA

    fullValues.RequestMachineGroups[index] = currentMachineGroup
    setFieldValue(
      'RequestArticle.RequestMachineGroups',
      fullValues.RequestMachineGroups
    )

    return currentMachineGroup
  }

  const getNumber = (text) => {
    let b = '' // Initialize an empty string to collect digits
    let val = 0 // Initialize the return value to 0

    // Loop through each character in the input text
    for (let i = 0; i < text.length; i++) {
      // Check if the character is a digit
      if (/\d/.test(text[i])) {
        b += text[i] // Append digit to string `b`
      }
    }

    // If `b` contains any digits, parse it to an integer
    if (b.length > 0) {
      val = parseInt(b, 10)
    }

    return val // Return the parsed number
  }

  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Semielaborat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Semielaborat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.SemiFinished"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* Tipus lligat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Tipus lligat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {
            getApiCallGetSemiFinished?.data?.find(
              (option) => option.semi === values.SemiFinished
            )?.ligado
          }
        </div>
      </div>

      {/* Tabla: Ideal | Tol.Màx. */}
      <div className="grid grid-cols-12 col-span-12">
        <div className="grid grid-cols-12 col-span-12">
          <div className="grid grid-cols-12 col-span-9 col-start-4 col-end-13 gap-x-2">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 text-sm border-b border-r border-black font-ms-semi">
                Ideal
              </div>

              <div className="flex flex-row items-center justify-center w-full col-span-6 col-end-13 text-sm border-b border-black font-ms-semi">
                Tol. Màx.
              </div>
            </div>
          </div>
        </div>

        {/* Campo 'Passades': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Passades:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  name="RequestArticle.Repetitions"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.RepetitionsTolerance"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  name="RequestArticle.RepetitionsV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.RepetitionsToleranceV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>u/cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Columnes': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Columnes:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  name="RequestArticle.Columns"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.ColumnsTolerance"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  name="RequestArticle.ColumnsV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.ColumnsToleranceV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>u/cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Gramatge': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Gramatge:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  name="RequestArticle.Weight"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.WeightTolerance"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  name="RequestArticle.WeightV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.WeightToleranceV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>g/m2</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Ample': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Ample:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  name="RequestArticle.Width"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.WidthTolerance"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  name="RequestArticle.WidthV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.WidthToleranceV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Gruix': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Gruix:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  name="RequestArticle.Thickness"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.ThicknessTolerance"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  name="RequestArticle.ThicknessV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.ThicknessToleranceV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>mm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Rdt Nominal': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Rdt Nominal:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <Field
                  type="text"
                  name="RequestArticle.RDTNominal"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.RDTNominalTolerance"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <Field
                  type="text"
                  name="RequestArticle.RDTNominalV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                &nbsp;&plusmn;&nbsp;
                <Field
                  type="text"
                  name="RequestArticle.RDTNominalToleranceV2"
                  onInput={(e) => {
                    const { value } = e.target
                    e.target.value = value.replace(/[^0-9.]/g, '')
                  }}
                  className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>m/kg</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Comentari': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="block col-span-12 my-1 text-sm text-grey-darker font-ms-semi">
            Comentari:
          </div>

          <Field
            as="textarea"
            name="RequestArticle.LaboratoryComment"
            className="col-span-12 rounded-sm w-full appearance-none h-24 px-1 py-0.5 border border-gray-300 outline-none 
              text-grey-darker align-middle text-sm hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
          />
        </div>
      </div>

      {/* Lligat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Lligat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.TiedDescription"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* Diagrama: */}
      <div className="flex col-span-12 rounded">
        <p className="mr-1 text-right">
          1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />
          10
          <br />
          11
          <br />
          12
          <br />
        </p>

        <Field
          as="textarea"
          name="RequestArticle.EncryptedCode"
          className="w-full px-2 py-1 pt-0 overflow-hidden leading-tight border border-gray-300 rounded-sm appearance-none resize-none bg-blue-50 focus:outline-none focus:shadow-outline faden hover:border-gray-400 focus:border-gray-400"
          style={{
            lineHeight: '1.5rem'
          }}
        />

        <div
          onClick={() =>
            handleModal(<img src={esquemaTeixiduria} alt="llegenda" />)
          }
          className="cursor-pointer"
          style={{ height: 'fit-content' }}
        >
          <ToolTipIcon width={20} height={20} className="ml-2 mr-1" />
        </div>
      </div>

      {/* Màquina */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Màquina:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <Select
          className="flex-grow w-4/12 leading-tight text-grey-darker"
          onChange={(e) => {
            setFieldValue('RequestArticle.Machine', e.desc_sec)
            setFieldValue('RequestArticle.MachineCode', e.cod_maq)
            setFieldValue('RequestArticle.FoldType', e.plegador)
          }}
          name="RequestArticle.MachineCode"
          placeholder="Seleccionar..."
          options={getApiCallGetMachines?.data}
          getOptionLabel={(o) => o.cod_maq}
          getOptionValue={(o) => o.cod_maq}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderRadius: '0.125rem',
              minHeight: '2rem',
              cursor: 'pointer',
              boxShadow: 'none'
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              height: '100%',
              padding: '0.25rem 0.5rem',
              position: 'static',
              cursor: 'pointer',
              rowGap: '0.25rem'
            }),
            multiValue: (baseStyles) => ({
              ...baseStyles,
              height: '100%',
              margin: '0 0.25rem 0 0',
              alignItems: 'center',
              cursor: 'pointer'
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              margin: '0',
              paddingTop: '0',
              paddingBottom: '0',
              cursor: 'pointer'
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              marginLeft: '0',
              marginRight: '0'
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              cursor: 'pointer'
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              padding: '0px 8px'
            })
          }}
          value={getApiCallGetMachines?.data?.find(
            (option) => option.cod_maq === values.MachineCode
          )}
        />

        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Machine}
        </div>
      </div>

      {/* Plegador */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Plegador:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.FoldType"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* GG - ø */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        GG - ø:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.GG"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
        <Field
          type="text"
          name="RequestArticle.O"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* Nº Jocs */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Nº Jocs:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.NGame"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* Agulles */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Agulles:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.Needle"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* Rpm */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Rpm:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.Rpm"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
          onBlur={() => update4()}
        />
      </div>

      {/* Rpm Mín. */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Rpm Mín.:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.RpmMin"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
          onBlur={() => update4()}
        />
      </div>

      {/* V. / Kg */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Kg:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.TurnsPerKg}
        </div>
        <span>&plusmn;</span>
        <Field
          type="text"
          name="RequestArticle.TurnsPerKgTolerance"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
          onBlur={() => update4()}
        />
      </div>

      {/* V. / Peça (Reals) */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Peça (Reals):
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.TurnsPerPart}
        </div>
      </div>

      {/* V. / Peça (Fitxa) */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Peça (Fitxa):
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.TurnsPerPartByOperator"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
          onBlur={() => update4()}
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          name="RequestArticle.TurnsPerPartByOperatorTolerance"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
          onBlur={() => update4()}
        />
      </div>

      {/* Kg peça */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Kg peça:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.KgPart"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
          onBlur={() => update4()}
        />
        <span>&plusmn;</span>
        <Field
          type="text"
          name="RequestArticle.KgPartTolerance"
          onInput={(e) => {
            const { value } = e.target
            e.target.value = value.replace(/[^0-9]/g, '')
          }}
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
          onBlur={() => update4()}
        />
      </div>

      {/* Pass. Seguretat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Pass. Seguretat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <Select
          className={`flex-grow ${
            values?.SecurityStep === 'true' || values?.SecurityStep
              ? 'w-4/12'
              : 'w-full'
          } leading-tight text-grey-darker`}
          onChange={(e) => {
            setFieldValue('RequestArticle.SecurityStep', e.value)
          }}
          name="RequestArticle.SecurityStep"
          placeholder="Seleccionar..."
          options={optionsYesNo}
          getOptionLabel={(o) => o.label}
          getOptionValue={(o) => o.value}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderRadius: '0.125rem',
              minHeight: '2rem',
              cursor: 'pointer',
              boxShadow: 'none'
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              height: '100%',
              padding: '0.25rem 0.5rem',
              position: 'static',
              cursor: 'pointer',
              rowGap: '0.25rem'
            }),
            multiValue: (baseStyles) => ({
              ...baseStyles,
              height: '100%',
              margin: '0 0.25rem 0 0',
              alignItems: 'center',
              cursor: 'pointer'
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              margin: '0',
              paddingTop: '0',
              paddingBottom: '0',
              cursor: 'pointer'
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              marginLeft: '0',
              marginRight: '0'
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              cursor: 'pointer'
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              padding: '0px 8px'
            })
          }}
          value={optionsYesNo?.find(
            (option) => option.value === values.SecurityStep
          )}
        />

        {(values?.SecurityStep === 'true' || values?.SecurityStep) && (
          <Field
            type="text"
            name="RequestArticle.SecurityStepDescription"
            className={`w-8/12 h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
          />
        )}
      </div>

      {/* Tabla: Hilos */}
      <div className="w-full col-span-12 border-t border-dashed">
        <ThreadTable
          threads={threads}
          numberGames={values?.NGame ? Number(values?.NGame) : 0}
          values={values}
          threadOptions={getApiCallGetThreads?.data}
          setFieldValue={setFieldValue}
          setRequestFieldsState={setRequestFieldsState}
        />
      </div>
    </div>
  )
}

DescriptionForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  threads: PropTypes.any,
  setRequestFieldsState: PropTypes.func,
  fullValues: PropTypes.object
}
