import alcades from 'assets/icons/alcades.png'
import GamesTable from './gamesTable'

export default function PointGraduationForm() {
  const inputStyles =
    'w-full h-28 resize-none py-0.5 print:py-0 px-2 bg-white border-2 rounded border-gray-300 appearance-none outline-none print:rounded-none print:border print:border-l-0 print:border-t-0 print:border-r-0'

  return (
    <div className="grid w-full grid-cols-12 gap-2 p-2 xl:grid-cols-11 print:grid-cols-11">
      {/* Columna 1: */}
      {/* Campo 'Alçada cilindre (seccions)': */}
      <div className="grid items-center grid-cols-4 col-span-4 xl:col-span-3 print:col-span-3">
        <label className="block col-span-4 mb-1 text-sm text-grey-darker font-ms-semi col-start-full">
          Alçada cilindre (seccions):
        </label>

        <div className="relative col-span-3 rounded">
          <img
            className="print:w-40"
            src={alcades}
            alt="Pinyons Plegador"
            width={150}
          />

          <div className="absolute left-0 h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:w-10 bottom-1 sm:w-12 lg:w-16 focus:outline-none focus:shadow-outline" />
          <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded print:w-10 print:left-24 bottom-1 sm:left-16 sm:w-12 lg:left-20 lg:w-16 focus:outline-none focus:shadow-outline" />
        </div>
      </div>

      {/* Columna 2: */}
      {/* Campo 'Posició Aro Platines': */}
      <div className="items-center block col-span-4 print:col-span-4 print:bg-white">
        <p className="block mb-1 text-sm text-grey-darker font-ms-semi col-start-full">
          Posició Aro Platines:
        </p>

        <input className={inputStyles} disabled />
      </div>

      {/* Columna 3: */}
      {/* Campo 'Posició Aro Guiafils': */}
      <div className="items-center block col-span-4 print:col-span-4 print:bg-white">
        <p className="block mb-1 text-sm text-grey-darker font-ms-semi col-start-full">
          Posició Aro Guiafils:
        </p>

        <input className={inputStyles} disabled />
      </div>

      {/* Tabla 'Jocs': */}
      <div className="w-full col-span-12 xl:col-span-11 print:col-span-11 print:text-sm">
        <GamesTable />
      </div>

      {/* Campo 'Tensiòmetre': */}
      <div className="flex items-center col-span-12 xl:col-span-11 print:col-span-11">
        <label
          className="block pr-1 text-sm text-grey-darker font-ms-semi col-start-full"
          htmlFor="PassTensiometer"
        >
          Tensiòmetre
        </label>

        <input
          type="checkbox"
          className="w-6 h-6 ml-1 cursor-not-allowed"
          name="PassTensiometer"
        />
      </div>
    </div>
  )
}

PointGraduationForm.propTypes = {}
