import alcades from 'assets/icons/alcades.png'

export default function HeightForm() {
  const inputStyles =
    'w-full py-0.5 print:py-0 px-2 bg-white border-2 rounded border-gray-300 appearance-none outline-none print:rounded-none print:border print:border-l-0 print:border-t-0 print:border-r-0'

  return (
    <div className="grid items-center grid-cols-1 p-2 gap-y-2">
      {/* Campo 'Sincronit.': */}
      <div className="flex flex-row w-full">
        <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
          Sincronit.:
        </p>

        <div className="flex flex-row items-center justify-center flex-grow">
          <input className={inputStyles} disabled />
        </div>
      </div>

      {/* Campo 'Formació': */}
      <div className="flex flex-row w-full">
        <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
          Formació:
        </p>

        <div className="flex flex-row items-center justify-center flex-grow">
          <input className={inputStyles} disabled />
        </div>
      </div>

      {/* Campo 'Alçada plat': */}
      <div className="grid w-full grid-cols-4">
        <p className="flex items-center col-span-4 py-1 text-sm text-grey-darker font-ms-semi">
          Alçada plat:
        </p>

        <div className="relative col-span-3">
          <img src={alcades} alt="pinyons plegador" className="w-36" />
          <div className="absolute left-0 h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 print:w-2/5 sm:w-2/5 lg:w-16" />
          <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 print:ml-4 print:w-2/5 left-20 sm:w-2/5 lg:w-16" />
        </div>
      </div>

      {/* Campo 'Desplaçament plat': */}
      <div className="grid w-full grid-cols-4">
        <p className="flex items-center col-span-4 pb-1 text-sm text-grey-darker font-ms-semi">
          Desplaçament plat:
        </p>

        <div className="relative col-span-3">
          <img src={alcades} alt="Pinyons Plegador" className="w-36" />
          <div className="absolute left-0 h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 print:w-2/5 sm:w-2/5 lg:w-16" />
          <div className="absolute h-8 px-2 py-1 leading-tight bg-white border border-gray-300 rounded bottom-1 left-20 print:ml-4 print:w-2/5 sm:w-2/5 lg:w-16" />
        </div>
      </div>
    </div>
  )
}

HeightForm.propTypes = {}
