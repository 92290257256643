import ThreadTable from './threadTable'

export default function DescriptionForm() {
  const inputStyles =
    'py-0.5 print:py-0 px-2 bg-white border-2 rounded border-gray-300 appearance-none outline-none print:rounded-none print:border print:border-l-0 print:border-t-0 print:border-r-0'
  const checkStyles = 'hidden w-6 h-6 px-1 border border-black print:inline'

  return (
    <>
      <div className="grid grid-cols-3 px-2 border-b border-blue-100 border-dashed divide-x divide-blue-100 grow divide-dashed">
        {/* Columna 1: */}
        <div className="grid grid-cols-4 py-2 pr-2 place-content-start gap-y-2">
          {/* Campo 'Semielaborat': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Semielaborat:
            </p>

            <div className="flex flex-row items-center justify-center flex-grow">
              <input className={`${inputStyles} w-full`} disabled />
            </div>
          </div>

          {/* Campo 'Tipus lligat': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Tipus lligat:
            </p>

            <div className="flex flex-row items-center justify-center flex-grow">
              <input className={`${inputStyles} w-full`} disabled />
            </div>
          </div>

          <hr className="col-span-4 my-2 print:my-0.5 border-2 border-blue-100" />

          {/* Campo 'Passades': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-5/12 text-sm text-grey-darker font-ms-semi">
              Passades:
            </p>

            <div className="grid flex-grow grid-cols-12">
              <div className="flex flex-row items-center justify-center col-span-9">
                <input className={`${inputStyles} w-full`} disabled />
                &nbsp;&plusmn;&nbsp;
                <input className={`${inputStyles} w-full`} disabled />
              </div>

              <div className="flex flex-row items-center justify-start col-span-2">
                <span className="pl-1">u/cm</span>
              </div>

              <div className="flex flex-row items-center justify-end col-span-1">
                <span className={checkStyles} />
              </div>
            </div>
          </div>

          {/* Campo 'Columnes': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-5/12 text-sm text-grey-darker font-ms-semi">
              Columnes:
            </p>

            <div className="grid flex-grow grid-cols-12">
              <div className="flex flex-row items-center justify-center col-span-9">
                <input className={`${inputStyles} w-full`} disabled />
                &nbsp;&plusmn;&nbsp;
                <input className={`${inputStyles} w-full`} disabled />
              </div>

              <div className="flex flex-row items-center justify-start col-span-2">
                <span className="pl-1">u/cm</span>
              </div>

              <div className="flex flex-row items-center justify-end col-span-1">
                <span className={checkStyles} />
              </div>
            </div>
          </div>

          {/* Campo 'Gramatge': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-5/12 text-sm text-grey-darker font-ms-semi">
              Gramatge:
            </p>

            <div className="grid flex-grow grid-cols-12">
              <div className="flex flex-row items-center justify-center col-span-9">
                <input className={`${inputStyles} w-full`} disabled />
                &nbsp;&plusmn;&nbsp;
                <input className={`${inputStyles} w-full`} disabled />
              </div>

              <div className="flex flex-row items-center justify-start col-span-2">
                <span className="pl-1">g/m2</span>
              </div>

              <div className="flex flex-row items-center justify-end col-span-1">
                <span className={checkStyles} />
              </div>
            </div>
          </div>

          {/* Campo 'Ample': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-5/12 text-sm text-grey-darker font-ms-semi">
              Ample:
            </p>

            <div className="grid flex-grow grid-cols-12">
              <div className="flex flex-row items-center justify-center col-span-9">
                <input className={`${inputStyles} w-full`} disabled />
                &nbsp;&plusmn;&nbsp;
                <input className={`${inputStyles} w-full`} disabled />
              </div>

              <div className="flex flex-row items-center justify-start col-span-2">
                <span className="pl-1">cm</span>
              </div>

              <div className="flex flex-row items-center justify-end col-span-1">
                <span className={checkStyles} />
              </div>
            </div>
          </div>

          {/* Campo 'Gruix': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-5/12 text-sm text-grey-darker font-ms-semi">
              Gruix:
            </p>

            <div className="grid flex-grow grid-cols-12">
              <div className="flex flex-row items-center justify-center col-span-9">
                <input className={`${inputStyles} w-full`} disabled />
                &nbsp;&plusmn;&nbsp;
                <input className={`${inputStyles} w-full`} disabled />
              </div>

              <div className="flex flex-row items-center justify-start col-span-2">
                <span className="pl-1">mm</span>
              </div>

              <div className="flex flex-row items-center justify-end col-span-1">
                <span className={checkStyles} />
              </div>
            </div>
          </div>

          {/* Campo 'Rdt Nominal': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-5/12 text-sm text-grey-darker font-ms-semi">
              Rdt Nominal:
            </p>

            <div className="grid flex-grow grid-cols-12">
              <div className="flex flex-row items-center justify-center col-span-9">
                <input className={`${inputStyles} w-full`} disabled />
                &nbsp;&plusmn;&nbsp;
                <input className={`${inputStyles} w-full`} disabled />
              </div>

              <div className="flex flex-row items-center justify-start col-span-2">
                <span className="pl-1">m/kg</span>
              </div>

              <div className="flex flex-row items-center justify-end col-span-1">
                <span className={checkStyles} />
              </div>
            </div>
          </div>
        </div>

        {/* Columna 2: */}
        <div className="grid grid-cols-4 px-4 py-2 place-content-start gap-y-2">
          {/* Campo 'Lligat': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Lligat:
            </p>

            <div className="flex flex-row items-center justify-center flex-grow">
              <input className={`${inputStyles} w-full`} disabled />
            </div>
          </div>

          {/* Diagrama: */}
          <div className="flex flex-row w-full col-span-4">
            <p className="mr-1 text-right">
              1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />
              10
              <br />
              11
              <br />
              12
              <br />
            </p>

            <div className="flex items-center justify-center w-full px-2 py-1 pt-0 overflow-hidden leading-tight border-2 border-gray-300 rounded appearance-none resize-none bg-blue-50">
              <span className="text-2xl font-bold text-center align-middle opacity-40 lg:text-5xl xl:text-6xl 2xl:text-7xl font-ms-regular print:text-6xl">
                PROTO
              </span>
            </div>
          </div>
        </div>

        {/* Columna 3: */}
        <div className="grid grid-cols-3 py-2 pl-2 place-content-start gap-y-2">
          {/* Campo 'Màquina': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Màquina:
            </p>

            <div className="flex flex-row items-center justify-center w-9/12 space-x-2">
              <input className={`${inputStyles} w-4/12`} disabled />
              <input className={`${inputStyles} flex-grow`} disabled />
            </div>
          </div>

          {/* Campo 'Plegador': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Plegador:
            </p>

            <div className="flex flex-row items-center justify-center flex-grow">
              <input className={`${inputStyles} w-full`} disabled />
            </div>
          </div>

          <hr className="col-span-4 my-2 print:my-0.5 border-2 border-blue-100" />

          {/* Campo 'GG - ø': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              GG - ø:
            </p>

            <div className="flex flex-row items-center justify-center w-9/12 space-x-2">
              <input className={`${inputStyles} w-full`} disabled />
              <input className={`${inputStyles} w-full`} disabled />
            </div>
          </div>

          {/* Campo 'Nº Jocs': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Nº Jocs:
            </p>

            <div className="flex flex-row items-center justify-center flex-grow">
              <input className={`${inputStyles} w-full`} disabled />
            </div>
          </div>

          {/* Campo 'Agulles': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Agulles:
            </p>

            <div className="flex flex-row items-center justify-center flex-grow">
              <input className={`${inputStyles} w-full`} disabled />
            </div>
          </div>

          {/* Campos 'Rpm' y 'Rpm (Min.): */}
          <div className="flex flex-row w-full col-span-4 space-x-6 2xl:space-x-4">
            {/* Campo 'Rpm': */}
            <div className="flex flex-row flex-grow">
              <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi 2xl:w-2/12">
                Rpm:
              </p>

              <div className="flex flex-row items-center justify-center w-9/12 2xl:w-10/12">
                <input className={`${inputStyles} w-full`} disabled />
              </div>
            </div>

            {/* Campo 'Mín.': */}
            <div className="flex flex-row flex-grow">
              <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi 2xl:w-2/12">
                Mín.:
              </p>

              <div className="flex flex-row items-center justify-center w-9/12 2xl:w-10/12">
                <input className={`${inputStyles} w-full`} disabled />
              </div>
            </div>

            <div className="flex-row items-center justify-end hidden print:flex">
              <span className={checkStyles} />
            </div>
          </div>

          {/* Campo 'V. / Kg': */}
          {/* <div className="flex flex-row w-full col-span-4 print:hidden">
            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              V. / Kg:
            </p>

            <div className="grid w-9/12 grid-cols-12">
              <div className="flex flex-row items-center justify-center col-span-12 print:col-span-10">
                <input className={`${inputStyles} w-full`} disabled />
                &nbsp;&plusmn;&nbsp;
                <input className={`${inputStyles} w-full`} disabled />
              </div>

              <div className="flex flex-row items-center justify-end col-span-2">
                <span className={checkStyles} />
              </div>
            </div>
          </div> */}

          {/* Campo 'V. / Peça (Reals)': */}
          {/* <div className="flex flex-row w-full col-span-4 print:hidden">
            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              V. / Peça (Reals):
            </p>

            <div className="flex flex-row items-center justify-center flex-grow">
              <input className={`${inputStyles} w-full`} disabled />
            </div>
          </div> */}

          {/* Campo 'V. / Peça (Fitxa)': */}
          <div className="flex flex-row w-full col-span-4">
            {/* <p className="flex items-center w-3/12 text-sm print:hidden text-grey-darker font-ms-semi">
              V. / Peça (Fitxa):
            </p> */}

            {/* <p className="items-center hidden w-3/12 text-sm print:flex text-grey-darker font-ms-semi">
              V. / Peça:
            </p> */}

            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              V. / Peça:
            </p>

            <div className="grid w-9/12 grid-cols-12">
              <div className="flex flex-row items-center justify-center col-span-12 print:col-span-10">
                <input className={`${inputStyles} w-full`} disabled />
                &nbsp;&plusmn;&nbsp;
                <input className={`${inputStyles} w-full`} disabled />
              </div>

              <div className="flex flex-row items-center justify-end col-span-2">
                <span className={checkStyles} />
              </div>
            </div>
          </div>

          {/* Campo 'Kg peça': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Kg peça:
            </p>

            <div className="grid w-9/12 grid-cols-12">
              <div className="flex flex-row items-center justify-center col-span-12 print:col-span-12">
                <input className={`${inputStyles} w-full`} disabled />
                &nbsp;&plusmn;&nbsp;
                <input className={`${inputStyles} w-full`} disabled />
              </div>
            </div>
          </div>

          {/* Campo 'Pass. Seguretat': */}
          <div className="flex flex-row w-full col-span-4">
            <p className="flex items-center w-3/12 text-sm text-grey-darker font-ms-semi">
              Pass. Seguretat:
            </p>

            <div className="flex flex-row items-center justify-center w-9/12 space-x-2">
              <input className={`${inputStyles} w-4/12`} disabled />
              <input className={`${inputStyles} flex-grow`} disabled />
            </div>
          </div>
        </div>
      </div>

      <ThreadTable />
    </>
  )
}

DescriptionForm.propTypes = {}
