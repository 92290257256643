import pinyons from 'assets/icons/pinyons.png'
import PropTypes from 'prop-types'

export default function SprocketsForm({ values, machineCode }) {
  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      <div className="relative w-full h-full col-span-12 mx-auto">
        <img
          src={pinyons}
          alt="pinyons plegador"
          className="mx-auto my-8"
          width={220}
        />

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Primera Fila de Arriba a Abajo (Derecha) */}
        <div className="absolute top-0 w-12 p-0.5 leading-tight bg-white border border-gray-300 rounded right-20">
          {values?.Piston2}
        </div>
        <div className="absolute top-0 right-6">
          &plusmn;
          <div className="w-8 p-0.5 text-base leading-tight bg-white border border-gray-300 rounded">
            {values?.PistonTolerance2}
          </div>
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Segunda Fila de Arriba a Abajo (Izquierda) */}
        <div className="absolute w-12 p-0.5 leading-tight bg-white border border-gray-300 rounded sm:top-5 lg:top-6 left-4">
          {values?.Piston1}
        </div>
        <div className="absolute inline-flex items-center sm:top-5 sm:left-20 lg:top-6 lg:left-20">
          &plusmn;
          <div className="w-8 p-0.5 leading-tight bg-white border border-gray-300 rounded">
            {values?.PistonTolerance1}
          </div>
        </div>

        {/* NEW VERSION OF TOP ELEMENT */}
        {/* <div className="absolute inline-flex space-x-1  sm:top-5 lg:top-6 left-4">
          <div className="w-12 p-0.5 leading-tight bg-white border border-gray-300 rounded">
            {values?.Piston1}
          </div>
          <span>&plusmn;</span>
          <div className="w-8 p-0.5 leading-tight bg-white border border-gray-300 rounded">
            {values?.PistonTolerance1}
          </div>
        </div> */}

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Tercera Fila de Arriba a Abajo (Derecha) */}
        <div className="absolute w-12 p-0.5 leading-tight bg-white border border-gray-300 rounded lg:top-12 right-20 sm:top-12 lg:text-base">
          {values?.Piston4}
        </div>
        <div className="absolute inline-flex items-center top-12 right-6">
          &plusmn;
          <div className="w-8 p-0.5 text-base leading-tight bg-white border border-gray-300 rounded">
            {values?.PistonTolerance4}
          </div>
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT, JMB -> Cuarta Fila de Arriba a Abajo (Izquierda) */}
        <div className="absolute p-0.5 leading-tight bg-white border border-gray-300 rounded sm:bottom-20 lg:bottom-16 sm:w-12 left-4 lg:w-12">
          {values?.Piston3}
        </div>
        <div className="absolute inline-flex items-center sm:left-20 bottom-16 lg:left-20">
          &plusmn;
          <div className="p-0.5 leading-tight bg-white border border-gray-300 rounded sm:w-8 lg:w-8">
            {values?.PistonTolerance3}
          </div>
        </div>

        {/* NEW VERSION OF TOP ELEMENT */}
        {/* <div className="absolute inline-flex space-x-1 sm:bottom-20 lg:bottom-16 left-4">
          <div className="w-12 p-0.5 leading-tight bg-white border border-gray-300 rounded">
            {values?.Piston3}
          </div>
          <span>&plusmn;</span>
          <div className="w-8 p-0.5 leading-tight bg-white border border-gray-300 rounded">
            {values?.PistonTolerance3}
          </div>
        </div> */}

        {/* ---------------------------------- */}

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <div
          className={`${
            machineCode === 'JMB' ? 'hidden' : 'block'
          } absolute bg-white sm:top-20 sm:right-28 sm:w-10 lg:top-28 2xl:right-44 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
        >
          {values?.Piston7}
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Tercera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <div
          className={` ${
            machineCode === 'JMB' ? 'hidden' : 'block'
          } absolute bg-white top-28 right-14 text-base 2xl:right-28 rounded border border-gray-300 p-0.5 w-12 leading-tight`}
        >
          {values?.Piston6}
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <div
          className={`${
            machineCode === 'JMB' ? 'hidden' : 'block'
          } absolute bg-white sm:w-12 lg:text-base sm:left-9 sm:top-24 lg:top-36 2xl:left-28 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
        >
          {values?.Piston5}
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <div
          className={` ${
            machineCode === 'JMB' ? 'hidden' : 'block'
          } absolute bg-white sm:top-28 sm:right-28 sm:w-10 lg:top-36 2xl:right-44 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
        >
          {values?.Piston8}
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <div
          className={`absolute bg-white sm:top-32 sm:left-9 lg:top-44 2xl:left-28 rounded border border-gray-300 p-0.5 w-12 leading-tight ${
            machineCode === 'JMB' ? 'hidden' : 'block'
          }`}
        >
          {values?.Piston9}
        </div>

        {/* PLT, PAL, MCH, SNT, MYR, TRT -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <div
          className={`${
            machineCode === 'JMB'
              ? 'hidden'
              : 'block bg-white absolute sm:top-36 sm:w-10 lg:top-44 right-28 2xl:right-44 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight'
          }`}
        >
          {values?.Piston10}
        </div>

        {/* ---------------------------------- */}

        {/* JMB -> Segunda Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <div
          className={`${
            machineCode === 'JMB' ? 'block' : 'hidden'
          } absolute bg-white sm:top-28 sm:right-28 sm:w-10 lg:top-28 2xl:right-32 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
        >
          {values?.JUM1}
        </div>

        {/* JMB -> Segunda Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <div
          className={`${
            machineCode === 'JMB' ? 'block' : 'hidden'
          } absolute bg-white sm:top-28 sm:right-28 sm:w-10 lg:top-28 2xl:right-56 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
        >
          {values?.JUM2}
        </div>

        {/* JMB -> Primera Fila de Abajo a Arriba, Segundo de Izquierda a Derecha */}
        <div
          className={` ${
            machineCode === 'JMB' ? 'block' : 'hidden'
          } absolute bg-white sm:top-28 sm:right-28 sm:w-10 lg:top-36 2xl:right-56 rounded border border-gray-300 text-center p-0.5 lg:w-12 leading-tight`}
        >
          {values?.JUM3}
        </div>

        {/* JMB -> Primera Fila de Abajo a Arriba, Primero de Izquierda a Derecha */}
        <div
          className={`${
            machineCode === 'JMB' ? 'block' : 'hidden'
          } absolute bg-white sm:top-28 left-4 sm:w-10 lg:top-36 rounded border border-gray-300 p-0.5 lg:w-12 leading-tight`}
        >
          {values?.JUM4}
        </div>
      </div>
    </div>
  )
}

SprocketsForm.propTypes = {
  values: PropTypes.object,
  machineCode: PropTypes.string
}
