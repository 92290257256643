import PropTypes from 'prop-types'
import { useContext, useRef, useState, useEffect } from 'react'

import useFetch from 'hooks/useFetch'

import { UserContext } from 'contexts/UserContext'
import { Formik, Form, Field } from 'formik'
import Select from 'react-select'

import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { FaTrash } from 'react-icons/fa'
import { MdOutlineUploadFile } from 'react-icons/md'

import esquemaControlVisio from 'assets/img/ControlVisio.png'

// const ControlVisionForm = ({  
export default function ControlVisionForm({
  handleSubmit,
  handleChange, 

  fields,
  
  users,
  equipments,

  isNew, 
  
// }) => {
}) {
  const { user } = useContext(UserContext)
  const hiddenFileInput = useRef(null)
  
  const [userOptions, setUserOptions] = useState([])
  const [userOption, setUserOption] = useState({})

  const [equipmentOptions, setEquipmentOptions] = useState([])
  const [equipmentOption, setEquipmentOption] = useState({})

   useEffect(() => {    
    if (users) {
      setUserOption(userOptions.find((option) => option.value === fields.UserId))
    }
    if (equipments) {
      setEquipmentOption(equipmentOptions.find((option) => option.value === fields.EquipmentId))
    }
  }, [])

  useEffect(() => {
    setUserOptions(users)
    if (fields?.UserId) {
      setUserOption(userOptions.find((option) => option.value === fields.UserId))
    }    
  }, [users])

  useEffect(() => {
    setEquipmentOptions(equipments)
    if (fields?.EquipmentId) {
      setEquipmentOption(equipmentOptions.find((option) => option.value === fields.EquipmentId))
    }    
  }, [equipments])

  useEffect(() => {        
  }, [fields])

  const handleClick = () => {
    hiddenFileInput.current.click()
  }    
  
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values, 
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <div id='idTabGeneral' className="grid grid-cols-1 gap-x-4 pt-4 pb-4">
            <div id='idForm' className="shadow-2xl grid grid-cols-1">
              <Form className="flex flex-col  bg-blue-100 print:bg-white">
                <h1 className="col-span-4 text-center mt-2 mb-2 text-2xl font-ms-bold">
                  CONTROL VISIÓ
                </h1>

                <h3 className="col-span-4 bg-blue-300 h-1 text-center mb- text-2xl font-ms-bold print:bg-white"></h3>  

                <div className="container relative grid grid-cols-6 print:bg-white">
                  <div 
                    className={`col-span-3 bg-blue-100 items-center block p-2 print:bg-white`}>                    
                    <div
                      className={`w-full col-span-3 bg-blue-100 items-center block p-2 print:bg-white`}
                    >
                      <p className="border-b-4 font-ms-semi print:border-b-0 ">Operari</p>
                      <Select
                        className="form-select block"
                        onChange={(e) => {
                          setFieldValue('UserId', e.value)
                          setFieldValue('UserName', e.label)
                        }}
                        name="UserId"
                        placeholder="Selecciona usuari"                        
                        options={userOptions}
                        // defaultValue={userOption ?? ''}    
                        value={userOptions.find(
                          (option) => option.value === values.UserId
                        )}                        
                      />                                        
                    </div>
                    
                    <div
                      className={`w-full col-span-3 bg-blue-100 items-center block p-2 print:bg-white`}
                    >
                      <p className="border-b-4 font-ms-semi print:border-b-0">OF</p>
                      <Field
                        className={`pl-2 shadow  rounded  w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker p-2`}
                        onChange={(e) => {
                          handleChange(e)
                        }}                  
                        name="OF"
                        placeholder="escriu..."
                        type="text"                      
                      />
                    </div>

                    <div
                      className={`w-full col-span-3 bg-blue-100 items-center block p-2 print:bg-white`}
                    >
                      <p className="border-b-4 font-ms-semi print:border-b-0">Estil</p>
                      <Field
                        className={`pl-2  shadow  rounded  w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker p-2`}
                        onChange={(e) => {
                          handleChange(e)
                        }}                  
                        name="Style"
                        placeholder="escriu..."
                        type="text"                      
                      />
                    </div>
                    
                    <div
                      className={`w-full col-span-3 bg-blue-100 items-center block p-2 print:bg-white`}
                    >
                      <p className="border-b-4 font-ms-semi print:border-b-0">RAM</p>
                      <Select
                        className="form-select block"
                        onChange={(e) => {
                          setFieldValue('EquipmentId', e.value)
                          setFieldValue('EquipmentName', e.label)
                        }}
                        name="EquipmentId"
                        placeholder="Selecciona RAM"                        
                        options={equipmentOptions}
                        // defaultValue={equipmentOption ?? ''}    
                        value={equipmentOptions.find(
                          (option) => option.value === values.EquipmentId
                        )}                        
                      />                                        
                    </div>
                    <div className={`container relative grid grid-cols-2 p-3`}>                    
                        <div>
                          <label
                            className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                            htmlFor="Verification1"
                          >
                            La visió capta les 2 taques en moviment
                          </label>
                        </div>
                        <div>
                          <Field
                          // disabled={readOnly || !canEditTeixi}
                            as="select"
                            name="Verification1"
                            onChange={(e) => {
                              handleChange(e)
                              // setTimeout(submitForm, 0)
                            }}
                            className="form-select block h-8 border rounded ml-16 2xl:ml-0 border-gray-300 mr-1"
                          >
                            <option
                              selected={values.Verification1 === false && 'selected'}
                              value={false}
                            >
                              No
                            </option>
                            <option
                              selected={values.Verification1 === true && 'selected'}
                              value={true}
                            >
                              Si
                            </option>
                          </Field>                          
                        </div>
                    </div>

                    <div className={`container relative grid grid-cols-2 p-3`}>
                        <div>
                          <label
                            className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                            htmlFor="Verification2"
                          >
                            La visió capta 1 línia amb permanent
                          </label>
                        </div>
                        <div>
                          <Field
                          // disabled={readOnly || !canEditTeixi}                            
                            as="select"
                            name="Verification2"
                            onChange={(e) => {
                              handleChange(e)
                              // setTimeout(submitForm, 0)
                            }}
                            className="form-select block border h-8 rounded ml-16 2xl:ml-0 border-gray-300 mr-1"
                          >
                            <option
                              selected={values.Verification2 === false && 'selected'}
                              value={false}
                            >
                              No
                            </option>
                            <option
                              selected={values.Verification2 === true && 'selected'}
                              value={true}
                            >
                              Si
                            </option>
                          </Field>                          
                        </div>
                    </div>     

                    <div className={`container relative grid grid-cols-2 p-3`}>
                        <div>
                          <label
                            className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                            htmlFor="Verification3"
                          >
                            La visió capta el forat
                          </label>
                        </div>
                        <div>
                          <Field
                          // disabled={readOnly || !canEditTeixi}
                            as="select"
                            name="Verification3"
                            onChange={(e) => {
                              handleChange(e)
                              // setTimeout(submitForm, 0)
                            }}
                            className="form-select block h-8 border rounded ml-16 2xl:ml-0 border-gray-300 mr-1"
                          >
                            <option
                              selected={values.Verification3 === false && 'selected'}
                              value={false}
                            >
                              No
                            </option>
                            <option
                              selected={values.Verification3 === true && 'selected'}
                              value={true}
                            >
                              Si
                            </option>
                          </Field>                          
                        </div>
                    </div>   

                    <div 
                      className={`col-span-3 bg-blue-100 items-center block p-2 print:bg-white`}                  
                    >                       
                      <p className="border-b-4 font-ms-semi print:border-b-0">Descripció</p>
                      <Field
                        className={`pl-2 h-40 shadow  rounded  w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker p-2`}
                        onChange={(e) => {
                          handleChange(e)
                        }}                  
                        name="Description"
                        placeholder="escriu..."
                        type="text"        
                        component="textarea"
                      />
                    </div>  
                  </div>

                  <div className={`col-span-3 bg-blue-100 items-center block p-2 print:bg-white`}>     
                    <div 
                      className={`col-span-3 bg-blue-100 items-center block p-2 print:bg-white`}                  
                    >
                      <p className="border-b-4 font-ms-semi print:border-b-0">Data</p>
                        <Field
                          className={`col-span-2 shadow rounded w-64 appearance-none border border-gray-300 py-2 pl-2 text-grey-darker`}
                          name="Date"                        
                          type="date"
                          // disabled                        
                          onChange={(e) => {                          
                            handleChange(e)
                          }}
                        />                                  
                    </div>

                    <div className="items-center block p-2 h-4/5 w-4/5">
                      <img src={esquemaControlVisio} alt="control visio"/>      
                    </div>  

                    <div id="preven-guardar" className="col-span-1 flex flex-row justify-center">
                      <div>
                        <button
                          className="2xl:mr-5 block transition duration-300 
                          border-primary hover:border-blue-200 bg-primary text-white 
                          font-ms-semi 
                          mt-3 mb-4 mr-2 py-1 px-4 ml-auto
                          border rounded h-10 w-28
                          hover:bg-blue-200 hover:text-primary
                          print:hidden"
                          type="submit" 
                        >
                          GUARDAR
                        </button>  
                      </div>          
                    </div>                                       
                  </div>
                </div>

              </Form>

            </div>
            {/* fin idGeneral */}
          </div>  
                        
        )}        
      </Formik>                 
    </>
  )
}

ControlVisionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isUpdate: PropTypes.any,
  
  users: PropTypes.array,  
  equipments: PropTypes.array,  

  isNew: PropTypes.bool,
}

// export default ControlVisionForm