import alcades from 'assets/icons/alcades.png'
import { Field } from 'formik'

export default function HeightForm() {
  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Sincronit. */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Sincronit.:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.Synchronization"
          className="rounded w-full appearance-none py-0.5 px-2 border-2 border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
        />
      </div>

      {/* Formació */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Formació:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <Field
          type="text"
          name="RequestArticle.Formation"
          className="rounded w-full appearance-none py-0.5 px-2 border-2 border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
        />
      </div>

      {/* Campo 'Alçada plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label
          className="flex items-center col-span-4 py-1 text-sm text-grey-darker font-ms-semi"
          htmlFor="DishHeight1"
        >
          Alçada plat:
        </label>

        <div className="relative col-span-3">
          <img src={alcades} alt="pinyons plegador" className="w-36" />

          <Field
            type="text"
            name="RequestArticle.DishHeight1"
            className="absolute left-0 px-2 py-1 leading-tight border border-gray-300 rounded appearance-none bottom-1 sm:w-2/5 lg:w-16 focus:outline-none focus:shadow-outline hover:border-gray-400 focus:border-gray-400"
          />

          <Field
            type="text"
            name="RequestArticle.DishHeight2"
            className="absolute px-2 py-1 leading-tight border border-gray-300 rounded appearance-none bottom-1 left-20 sm:w-2/5 lg:w-16 focus:outline-none focus:shadow-outline hover:border-gray-400 focus:border-gray-400"
          />
        </div>
      </div>

      {/* Campo 'Desplaçament plat': */}
      <div className="grid w-full grid-cols-4 col-span-12">
        <label
          className="flex items-center col-span-4 pb-1 text-sm text-grey-darker font-ms-semi"
          htmlFor="DishDisplacement1"
        >
          Desplaçament plat:
        </label>

        <div className="relative col-span-3">
          <img src={alcades} alt="Pinyons Plegador" className="w-36" />

          <Field
            type="text"
            name="RequestArticle.DishDisplacement1"
            className="absolute left-0 px-2 py-1 leading-tight border border-gray-300 rounded appearance-none bottom-1 sm:w-2/5 lg:w-16 focus:outline-none focus:shadow-outline hover:border-gray-400 focus:border-gray-400"
          />

          <Field
            type="text"
            name="RequestArticle.DishDisplacement2"
            className="absolute px-2 py-1 leading-tight border border-gray-300 rounded appearance-none bottom-1 left-20 sm:w-2/5 lg:w-16 focus:outline-none focus:shadow-outline hover:border-gray-400 focus:border-gray-400"
          />
        </div>
      </div>
    </div>
  )
}

HeightForm.propTypes = {}
