import { Field } from 'formik'
import PropTypes from 'prop-types'
import { BiPlusMedical } from 'react-icons/bi'
import { CgSpinner } from 'react-icons/cg'
import { FaTrash } from 'react-icons/fa'
import { ImCheckmark, ImCross } from 'react-icons/im'
import Select, { components } from 'react-select'

export default function ThreadTable({
  threads,
  numberGames,
  values,
  threadOptions,
  setFieldValue,
  setRequestFieldsState
}) {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CgSpinner size={20} className="animate-spin" />
      </components.DropdownIndicator>
    )
  }

  const handleAddThread = () => {
    const newThread = {
      RequestThreadId: 0,
      Thread: null,
      ThreadNumber:
        threads.length > 0
          ? String(Number(threads[threads.length - 1].ThreadNumber) + 1)
          : '1',
      ThreadMatter: null,
      NCones: null,
      Percentage: '0',
      TSWeavingID: values?.ArticleId,
      ThreadTitle: null,
      GRS: false,
      TextGRS: '',
      HasBeenRemoved: 0,
      HistoryThread: null
    }

    threads.push(newThread)

    setRequestFieldsState((prevState) => ({
      ...prevState,
      RequestThreads: threads
    }))
  }

  const handleThreadDelete = (e, index) => {
    threads[index].HasBeenRemoved = 1

    setRequestFieldsState((prevState) => ({
      ...prevState,
      RequestThreads: threads
    }))
  }

  return (
    <table className="relative w-full px-4 border table-fixed border-primary">
      <thead className="w-full text-white bg-primary-dark">
        <tr className="w-full divide-x divide-red-200 divide-dashed">
          <th className="w-1/12 text-sm"></th>
          <th className="w-6/12 text-sm print:w-7/12">Matèria</th>
          <th className="w-1/12 text-sm">GRS</th>
          <th className="w-2/12 text-sm">Nº Cons</th>
          <th className="w-2/12 text-sm">Percentatge</th>
          <th className={`w-1/12 print:hidden`}>
            <div className="items-center w-full flex-justify-center tooltip">
              <button
                onClick={() => handleAddThread()}
                className={`appearance-none text-sm rounded-lg font-ms-semi transition-colors duration-150 p-2 py-1 cursor-pointer hover:bg-white hover:text-primary focus:shadow-outline`}
              >
                <BiPlusMedical size={25} />
              </button>
            </div>
          </th>
        </tr>
      </thead>

      <tbody className="w-full divide-y divide-red-200 divide-dashed">
        {threads?.length > 0 &&
          threads
            .filter((thread) => !thread.HasBeenRemoved)
            .map((thread, index) => (
              <tr
                className="w-full print:divide-x print:divide-dashed print:divide-red-200"
                key={index}
              >
                {/* Campo 'Fil x': */}
                <td className="w-1/12 text-center">Fil {index}</td>

                {/* Campo 'Matèria': */}
                <td className="w-6/12 print:w-7/12 py-1.5">
                  {threadOptions ? (
                    <Select
                      className="w-full leading-tight print:hidden text-grey-darker"
                      onChange={(e) => {
                        console.log('Thread: ', e)
                        setFieldValue(
                          `RequestThreads[${index}].Thread`,
                          e.cod_art
                        )
                        setFieldValue(
                          `RequestThreads[${index}].ThreadMatter`,
                          (e.cod_art + ' - ' + e.desc_art_sec).slice(17)
                        )
                        setFieldValue(
                          `RequestThreads[${index}].ThreadTitle`,
                          e.titulo
                        )
                        setFieldValue(
                          `RequestThreads[${index}].PrintThread`,
                          (e.cod_art + ' - ' + e.desc_art_sec).slice(17)
                        )
                        setFieldValue(`RequestThreads[${index}].GRS`, e.GRS)
                        setFieldValue(
                          `RequestThreads[${index}].TextGRS`,
                          e.txt_GRS
                        )
                      }}
                      placeholder="Seleccionar..."
                      options={threadOptions}
                      getOptionLabel={(o) => o.desc_art_sec}
                      getOptionValue={(o) => o.cod_art}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: '2px solid #d1d5db',
                          boxShadow: state.isFocused ? 'none' : 'none',
                          '&:hover': {
                            border: '2px solid #9ca3af'
                          },
                          margin: '0px',
                          minHeight: 'fit-content',
                          cursor: 'pointer'
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          color: '#333333',
                          backgroundColor: state.isFocused && '#d1d5db',
                          '&:hover': {
                            backgroundColor: '#f3f4f6'
                          }
                        }),
                        valueContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '0px 8px',
                          margin: '0px'
                        }),
                        dropdownIndicator: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '2px 8px'
                        })
                      }}
                      defaultValue={{
                        cod_art: thread.Thread,
                        desc_art_sec: thread.ThreadMatter,
                        titulo: thread.ThreadTitle
                      }}
                    />
                  ) : (
                    <Select
                      components={{ DropdownIndicator }}
                      className="w-full leading-tight print:hidden text-grey-darker"
                      placeholder=""
                      options={threadOptions}
                      getOptionLabel={(o) => o.desc_art_sec}
                      getOptionValue={(o) => o.cod_art}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: '2px solid #d1d5db',
                          boxShadow: state.isFocused ? 'none' : 'none',
                          '&:hover': {
                            border: '2px solid #9ca3af'
                          },
                          margin: '0px',
                          minHeight: 'fit-content'
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          color: '#333333',
                          backgroundColor: state.isFocused && '#d1d5db',
                          '&:hover': {
                            backgroundColor: '#f3f4f6'
                          }
                        }),
                        valueContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '0px 8px',
                          margin: '0px'
                        }),
                        dropdownIndicator: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '2px 8px',
                          color: '#333333'
                        })
                      }}
                      defaultValue={{
                        cod_art: thread.Thread,
                        desc_art_sec: thread.ThreadMatter,
                        titulo: thread.ThreadTitle
                      }}
                      isDisabled
                    />
                  )}
                </td>

                {/* Campo 'GRS': */}
                <td className="w-2/12 text-center">
                  {values.GRS ? (
                    <div className="flex items-center justify-center">
                      <p className="flex items-center justify-center w-6 h-6 text-lg font-bold bg-green-200 border-2 border-green-300 rounded-full print:hidden">
                        <ImCheckmark size={12} />
                      </p>
                      <p className="items-center justify-center hidden w-6 h-6 text-lg font-bold rounded-full print:flex">
                        <ImCheckmark size={8} />
                      </p>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <p className="flex items-center justify-center w-6 h-6 text-lg font-bold bg-red-200 border-2 border-red-300 rounded-full print:hidden">
                        <ImCross size={12} />
                      </p>
                      <p className="items-center justify-center hidden w-6 h-6 text-lg font-bold rounded-full print:flex">
                        <ImCross size={8} />
                      </p>
                    </div>
                  )}
                </td>

                {/* Campo 'Nº Cons': */}
                <td className="w-2/12 text-center">
                  <Field
                    type="text"
                    name={`RequestThreads[${index}].NCones`}
                    className="rounded w-full appearance-none py-0.5 px-2 border-2 border-gray-300 outline-none text-grey-darker hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                  />
                </td>

                {/* Campo 'Percentatge': */}
                <td className="w-2/12 text-center">
                  <div className="w-full appearance-none py-0.5 px-2 outline-none text-grey-darker text-center">
                    {thread?.NCones !== 0 &&
                    thread?.NCones &&
                    numberGames !== 0 &&
                    numberGames
                      ? ((thread?.NCones / numberGames) * 100)
                          .toFixed()
                          .toString() + ' %'
                      : 0 + ' %'}
                  </div>
                </td>

                {/* Botón 'Eliminar': */}
                <td className="w-1/12 print:hidden">
                  <div className="flex items-center justify-center">
                    <button
                      type="button"
                      onClick={(e) => handleThreadDelete(e, index)}
                      className={`p-2 font-medium rounded-lg print:hidden m-auto text-black bg-white  focus:shadow-outline cursor-pointer hover:bg-gray-300`}
                    >
                      <FaTrash size={17} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  )
}

ThreadTable.propTypes = {
  threads: PropTypes.any,
  numberGames: PropTypes.number,
  values: PropTypes.object,
  threadOptions: PropTypes.any,
  setFieldValue: PropTypes.func,
  setRequestFieldsState: PropTypes.func
}
