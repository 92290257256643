export default function GameTable() {
  const innerTdStyle = 'flex items-center justify-center h-100'
  const fieldStyle = 'h-6 w-full flex items-center justify-center'

  return (
    <>
      <table className="relative w-full px-4 border-2 table-fixed border-primary print:border">
        <thead className="w-full text-white bg-primary-dark">
          <tr className="w-full divide-x divide-red-200 divide-dashed">
            <th className="w-1/12 py-0.5 overflow-hidden text-sm truncate print:py-0 print:w-9 print:text-xs">
              Joc
            </th>

            <th className="w-6/12 py-0.5 overflow-hidden text-sm truncate print:py-0 print:w-4/12 print:text-xs">
              Matèria
            </th>

            <th className="w-1/12 py-0.5 overflow-hidden text-sm truncate print:py-0 print:text-xs">
              Caps
            </th>

            <th className="w-1/12 py-0.5 overflow-hidden text-sm truncate print:py-0 print:text-xs">
              Cons
            </th>

            <th className="w-1/12 py-0.5 overflow-hidden text-sm truncate print:py-0 print:text-xs">
              Tipus
            </th>

            <th className="w-1/12 py-0.5 overflow-hidden text-sm truncate print:py-0 print:text-xs">
              Consum
            </th>

            <th className="w-1/12 py-0.5 overflow-hidden text-sm truncate print:py-0 print:text-xs">
              Tol.
            </th>

            <th className="w-1/12 py-0.5 overflow-hidden text-sm truncate print:py-0 print:text-xs">
              Tensió
            </th>

            <th className="w-1/12 py-0.5 overflow-hidden text-sm truncate print:py-0 print:text-xs">
              NonC
            </th>

            <th className="w-1/12 py-0.5 overflow-hidden text-sm truncate print:py-0 print:text-xs">
              NonP
            </th>

            <th className="w-1/12 py-0.5 overflow-hidden text-sm truncate print:py-0 print:text-xs">
              Kg/h
            </th>
          </tr>
        </thead>

        <tbody className="w-full divide-y divide-red-200 divide-dashed">
          {[...Array(4)].map((_, index) => (
            <tr
              className="w-full divide-x divide-red-200 divide-dashed"
              key={index}
            >
              {/* Campo 'Joc': */}
              <td className="w-1/12 text-xs print:w-9">
                <div className={innerTdStyle}></div>
              </td>

              {/* Campo 'Matèria': */}
              <td className="w-6/12 print:w-4/12 text-xs py-1 print:py-0.5">
                <div className={`${innerTdStyle} space-x-1`}>
                  <div className={fieldStyle} />
                  <div className={fieldStyle} />
                </div>
              </td>

              {/* Campo 'Caps': */}
              <td className="w-1/12 text-xs">
                <div className={innerTdStyle}>
                  <div className={fieldStyle} />
                </div>
              </td>

              {/* Campo 'Cons': */}
              <td className="w-1/12 text-xs">
                <div className={innerTdStyle}>
                  <div className={fieldStyle} />
                </div>
              </td>

              {/* Campo 'Tipus': */}
              <td className="w-1/12 text-xs">
                <div className={innerTdStyle}>
                  <div className={fieldStyle} />
                </div>
              </td>

              {/* Campo 'Consum': */}
              <td className="w-1/12 text-xs">
                <div className={innerTdStyle}>
                  <div className={fieldStyle} />
                </div>
              </td>

              {/* Campo 'Tol.': */}
              <td className="w-1/12 text-xs">
                <div className={`${innerTdStyle} flex-row`}>
                  <span>&plusmn;</span>
                  <div className={fieldStyle} />
                </div>
              </td>

              {/* Campo 'Tensió': */}
              <td className="w-1/12 text-xs">
                <div className={innerTdStyle}>
                  <div className={fieldStyle} />
                </div>
              </td>

              {/* Campo 'NonC': */}
              <td className="w-1/12 text-xs">
                <div className={innerTdStyle}>
                  <div className={fieldStyle} />
                </div>
              </td>

              {/* Campo 'NonP': */}
              <td className="w-1/12 text-xs">
                <div className={innerTdStyle}>
                  <div className={fieldStyle} />
                </div>
              </td>

              {/* Campo 'Kg/H': */}
              <td className="w-1/12 text-xs">
                <div className={innerTdStyle}>
                  <div className={fieldStyle} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

GameTable.propTypes = {}
