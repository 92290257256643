import PropTypes from 'prop-types'

export default function ObservationsForm({
  side,
  isAnswered,
  valuesBefore,
  valuesAfter
}) {
  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Observació */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Observació:
      </div>
      <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 lg:col-span-8 xl:col-span-9">
        <div
          className={`${
            valuesBefore?.Obseravtion !== valuesAfter?.Obseravtion
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.Obseravtion
            : valuesAfter?.Obseravtion}
        </div>
      </div>

      {/* NOTA IMPORTANT */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        NOTA IMPORTANT:
      </div>
      <div className="flex items-center col-span-12 space-x-2 cursor-not-allowed min-h-8 lg:col-span-8 xl:col-span-9">
        <div
          className={`${
            valuesBefore?.DestacableText !== valuesAfter?.DestacableText
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight border-b border-gray-300 overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.DestacableText
            : valuesAfter?.DestacableText}
        </div>
      </div>

      {/* Edició */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Edició:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`${
            valuesBefore?.Edition !== valuesAfter?.Edition
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis`}
        >
          {side === 'left' ? valuesBefore?.Edition : valuesAfter?.Edition}
        </div>
      </div>

      {/* Data edició */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Data edició:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`${
            valuesBefore?.EditionDate !== valuesAfter?.EditionDate
              ? side === 'left'
                ? 'bg-red-100 border-red-300'
                : isAnswered
                ? 'bg-emerald-100 border-emerald-300'
                : 'bg-amber-100 border-amber-300'
              : 'border-gray-300 bg-gray-100'
          } flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis`}
        >
          {side === 'left'
            ? valuesBefore?.EditionDate
            : valuesAfter?.EditionDate}
        </div>
      </div>
    </div>
  )
}

ObservationsForm.propTypes = {
  side: PropTypes.string,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object
}
