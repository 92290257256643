import { useState, useEffect } from 'react'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

export default function ListTable({ items, handleClick }) {
  const [tableData, setTableData] = useState([])

  // Nombre de las columnas de las tablas, el 'accessor' es el nombre por el cual se enlazará la columna
  // con el campo de los registros que nos llegan. Es como la propiedad 'name' en un formulario:
  const tableColumns = [
    {
      Header: 'Semielaborat',
      accessor: 'FTTeixiduria_SemiFinished',
      Filter: ColumnFilter,
      Cell: (cell) => (
        <div>
          <span className="">{cell.row.original.FTTeixiduria_SemiFinished}</span>
          <span>{cell.row.original.FTTeixiduria_SemiFinishedLong}</span>
        </div>
      )
    },
    // {
    //   Header: 'Matèria',
    //   accessor: 'Matter',
    //   Filter: ColumnFilter
    // },
    {
      Header: 'Màquina',
      accessor: 'FTTeixiduria_Machine',
      Filter: ColumnFilter
    },
    {
      Header: 'GG',
      accessor: 'FTTeixiduria_GG',
      Filter: ColumnFilter
    },
    {
      Header: 'Consum',
      accessor: 'FTTeixiduria_Consum',
      // Filter: ''
      Filter: ColumnFilter
    },
    {
      Header: 'Pas.',
      accessor: 'FTTeixiduria_Repetitions',
      Filter: ColumnFilter
    },
    {
      Header: 'Col.',
      accessor: 'FTTeixiduria_Columns',
      Filter: ColumnFilter
    },
    {
      Header: 'G/m2',
      accessor: 'FTTeixiduria_Weight',
      Filter: ColumnFilter
    },
    {
      Header: 'Ample',
      accessor: 'FTTeixiduria_Width',
      Filter: ColumnFilter
    },
    {
      Header: 'Gruix',
      accessor: 'FTTeixiduria_Thickness',
      Filter: ColumnFilter
    },
    {
      Header: 'Kg (Peça)',
      accessor: 'FTTeixiduria_KgPart',
      Filter: ColumnFilter
    },
    {
      Header: 'KgD_100',
      accessor: 'FTTeixiduria_KgD_100',
      Filter: ColumnFilter
    },
    {
      Header: 'KgD_80',
      accessor: 'FTTeixiduria_KgD_80',
      Filter: ColumnFilter
    },
    {
      Header: 'Fecha Mod',
      accessor: 'FTTeixiduria_EditionDate',
      Filter: ColumnFilter
    }
  ]

  // useEffect() para crear un nuevo objeto, a partir de cada registro que se quiera mostrar en la tabla, para que los nombres
  // de los campos se enlacen con los 'accessor' de las columnas de la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({          
          Id:                            item.Id,
          FTTeixiduria_SemiFinished:     item.SemiFinished ? item.SemiFinished : null,
          FTTeixiduria_SemiFinishedLong: item.SemiFinishedLong !== null ? '-' + item.SemiFinishedLong : null,
          // FTTeixiduria_Matter:           item.TSWeavingMachineGroups ? item.TSWeavingMachineGroups[0].ThreadMatter.slice(0, 15) : null,
          FTTeixiduria_GG:               item.GG ? item.GG : null,
          FTTeixiduria_Repetitions:      item.Repetitions ? item.Repetitions : null,
          FTTeixiduria_Columns:          item.Columns ? item.Columns : null,
          FTTeixiduria_Weight:           item.Weight ? item.Weight : null,
          FTTeixiduria_Width:            item.Width ? item.Width : null,
          FTTeixiduria_Thickness:        item.Thickness ? item.Thickness : null,
          FTTeixiduria_KgPart:           item.KgPart ? item.KgPart : null,
          FTTeixiduria_KgD_100:          item.KgD_100 ? item.KgD_100 : null,
          FTTeixiduria_KgD_80:           item.KgD_80 ? item.KgD_80 : null,
          FTTeixiduria_EditionDate:      item.EditionDate ? item.EditionDate : null,
          FTTeixiduria_Machine:          `${item.MachineCode !== null ? `${item.MachineCode} - ` : ''} 
                                            ${item?.Machine !== undefined ? item.Machine : ''}`,
          FTTeixiduria_Consum:           `${
                                            item.TSWeavingMachineGroups[0]?.Spending !== undefined
                                              ? item.TSWeavingMachineGroups[0].Spending
                                              : ''
                                          }`
        })
      })

      setTableData(itemsData)
    }
  }, [items])

  return tableData.length > 0 && (
    <Table
      tableData={tableData}
      tableColumns={tableColumns}
      handleClick={handleClick}
    />
  )
}

ListTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired
}
