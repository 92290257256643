import { ErrorMessage, Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { CgSpinner } from 'react-icons/cg'
import { MdEdit } from 'react-icons/md'
import Select from 'react-select'
import * as Yup from 'yup'

export default function CheckListForm({
  users,
  equipments,
  fields,
  handleSubmit
}) {
  const validationSchema = Yup.object().shape({
    UserId: Yup.number()
      .required('* Especificar un operari')
      .positive('* Especificar un operari')
      .integer('* Especificar un operari'),
    EquipmentId: Yup.number()
      .required('* Especificar un RAM')
      .positive('* Especificar un RAM')
      .integer('* Especificar un RAM'),
    Date: Yup.string().nullable().required('* Especificar una data')
  })

  const shedOptions = [
    { label: 'Cetriko', value: 'Cetriko' },
    { label: '4KF', value: '4KF' }
  ]

  const turnOptions = [
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' }
  ]

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...fields }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        submitForm,
        isSubmitting
      }) => (
        <Form className="flex flex-col bg-blue-100 border-2 border-blue-300 rounded-sm print:bg-white print:border-0">
          {/* NORMAL VERSION */}
          <div className="flex justify-start w-full p-2 text-sm bg-blue-200 print:border-b print:border-blue-300 print:justify-center print:bg-transparent font-ms-bold print:text-2xl">
            CHECK LIST RAM
          </div>

          <div className="flex flex-col p-4 space-y-12 print:px-0 print:pb-0 print:pt-12">
            <div className="grid grid-cols-12 gap-4">
              {/* OPERARI */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Operari:
                </div>

                {/* NORMAL VERSION */}
                <Select
                  className="w-full text-base print:hidden text-grey-darker"
                  onChange={(e) => {
                    setFieldValue('UserId', e.Id)
                    setFieldValue('UserName', e.Name)
                  }}
                  name="UserId"
                  placeholder="Selecciona..."
                  options={users}
                  getOptionLabel={(o) => o.Name}
                  getOptionValue={(o) => o.Id}
                  value={users.find((option) => option.Id === values?.UserId)}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: '1px solid #bfdbfe',
                      borderRadius: '0.125rem',
                      boxShadow: state.isFocused ? 'none' : 'none',
                      '&:hover': {
                        border: '1px solid #9ca3af'
                      },
                      minHeight: '2rem',
                      cursor: 'pointer'
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      padding: '0.25rem 0.5rem',
                      position: 'static',
                      cursor: 'pointer',
                      rowGap: '0.25rem'
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      width: '75%'
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0',
                      paddingTop: '0',
                      paddingBottom: '0',
                      cursor: 'pointer'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: '0',
                      marginRight: '0'
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      cursor: 'pointer'
                    })
                  }}
                />
                <ErrorMessage
                  name="UserId"
                  render={(message) => (
                    <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                      {message}
                    </span>
                  )}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full py-0.5 text-base leading-tight border-b border-gray-300 min-h-8">
                  {users.find((option) => option.Id === values?.UserId)?.Name}
                </div>
              </div>

              {/* RAM */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  RAM:
                </div>

                {/* NORMAL VERSION */}
                <Select
                  className="w-full text-base print:hidden text-grey-darker"
                  onChange={(e) => {
                    setFieldValue('EquipmentName', e.Description)
                    setFieldValue('EquipmentId', e.Id)
                  }}
                  name="EquipmentId"
                  placeholder="Selecciona..."
                  options={equipments}
                  getOptionLabel={(o) => o.Description}
                  getOptionValue={(o) => o.Id}
                  value={equipments.find(
                    (option) => option.Id === values?.EquipmentId
                  )}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: '1px solid #bfdbfe',
                      borderRadius: '0.125rem',
                      boxShadow: state.isFocused ? 'none' : 'none',
                      '&:hover': {
                        border: '1px solid #9ca3af'
                      },
                      minHeight: '2rem',
                      cursor: 'pointer'
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      padding: '0.25rem 0.5rem',
                      position: 'static',
                      cursor: 'pointer',
                      rowGap: '0.25rem'
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      width: '75%'
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0',
                      paddingTop: '0',
                      paddingBottom: '0',
                      cursor: 'pointer'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: '0',
                      marginRight: '0'
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      cursor: 'pointer'
                    })
                  }}
                />
                <ErrorMessage
                  name="EquipmentId"
                  render={(message) => (
                    <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                      {message}
                    </span>
                  )}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full py-0.5 text-base leading-tight border-b border-gray-300 min-h-8">
                  {
                    equipments.find(
                      (option) => option.Id === values?.EquipmentId
                    )?.Description
                  }
                </div>
              </div>

              {/* DATA */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Data:
                </div>

                {/* NORMAL VERSION */}
                <Field
                  type="Date"
                  name="Date"
                  className="block w-full p-2 text-base leading-none border border-blue-200 rounded-sm appearance-none cursor-pointer print:hidden hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage
                  name="Date"
                  render={(message) => (
                    <span className="mb-2 text-sm font-semibold leading-4 text-red-600">
                      {message}
                    </span>
                  )}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full py-0.5 text-base leading-tight border-b border-gray-300 min-h-8">
                  {values?.Date}
                </div>
              </div>

              {/* NAU */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Nau:
                </div>

                {/* NORMAL VERSION */}
                <Select
                  className="w-full text-base print:hidden text-grey-darker"
                  onChange={(e) => setFieldValue('Shed', e.value)}
                  name="Shed"
                  placeholder="Selecciona..."
                  options={shedOptions}
                  getOptionLabel={(o) => o.label}
                  getOptionValue={(o) => o.value}
                  value={shedOptions.find(
                    (option) => option.value === values?.Shed
                  )}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: '1px solid #bfdbfe',
                      borderRadius: '0.125rem',
                      boxShadow: state.isFocused ? 'none' : 'none',
                      '&:hover': {
                        border: '1px solid #9ca3af'
                      },
                      minHeight: '2rem',
                      cursor: 'pointer'
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      padding: '0.25rem 0.5rem',
                      position: 'static',
                      cursor: 'pointer',
                      rowGap: '0.25rem'
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      width: '75%'
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0',
                      paddingTop: '0',
                      paddingBottom: '0',
                      cursor: 'pointer'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: '0',
                      marginRight: '0'
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      cursor: 'pointer'
                    })
                  }}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full py-0.5 text-base leading-tight border-b border-gray-300 min-h-8">
                  {
                    shedOptions.find((option) => option.value === values?.Shed)
                      ?.label
                  }
                </div>
              </div>

              {/* TORN */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Torn:
                </div>

                {/* NORMAL VERSION */}
                <Select
                  className="w-full text-base print:hidden text-grey-darker"
                  onChange={(e) => setFieldValue('Turn', e.value)}
                  name="Turn"
                  placeholder="Selecciona..."
                  options={turnOptions}
                  getOptionLabel={(o) => o.label}
                  getOptionValue={(o) => o.value}
                  value={turnOptions.find(
                    (option) => option.value === values?.Turn
                  )}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: '1px solid #bfdbfe',
                      borderRadius: '0.125rem',
                      boxShadow: state.isFocused ? 'none' : 'none',
                      '&:hover': {
                        border: '1px solid #9ca3af'
                      },
                      minHeight: '2rem',
                      cursor: 'pointer'
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      padding: '0.25rem 0.5rem',
                      position: 'static',
                      cursor: 'pointer',
                      rowGap: '0.25rem'
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      width: '75%'
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0',
                      paddingTop: '0',
                      paddingBottom: '0',
                      cursor: 'pointer'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: '0',
                      marginRight: '0'
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      cursor: 'pointer'
                    })
                  }}
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full py-0.5 text-base leading-tight border-b border-gray-300 min-h-8">
                  {
                    turnOptions.find((option) => option.value === values?.Turn)
                      ?.label
                  }
                </div>
              </div>

              {/* OF */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  OF:
                </div>

                {/* NORMAL VERSION */}
                <Field
                  type="text"
                  name="OF"
                  autoComplete="off"
                  className="block w-full p-2 text-base leading-tight border border-blue-200 rounded-sm appearance-none print:hidden hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline"
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full py-0.5 text-base leading-tight border-b border-gray-300 min-h-8">
                  {values?.OF}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
              {/* NETEJA FILTRES */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  NETEJA FILTRES
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification1"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    S&apos;elimina Restos-Borra
                  </div>
                </div>
              </div>

              {/* MÀQUINA DE COSIR */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  MÀQUINA DE COSIR
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification2"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    En bon estat
                  </div>
                </div>
              </div>

              {/* VERIFICACIÓ PRIMERS METRES */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  VERIFICACIÓ PRIMERS METRES
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification15"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Estat agulles de la cadena OK
                  </div>
                </div>
              </div>

              {/* NETEJA FOULAR */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  NETEJA FOULAR
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification6"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Es buida i es neteja
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification7"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Hi ha producte (Neteja final prod.)
                  </div>
                </div>
              </div>

              {/* VISIÓ ARTIFICIAL */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  VISIÓ ARTIFICIAL
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification8"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Està en bon estat
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification9"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Es bufa ordinador, leds, càmeres, ...
                  </div>
                </div>
              </div>

              {/* COMPROBAR ESTAT DRAP */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  COMPROBAR ESTAT DRAP
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification11"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Està en bones condicions
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification10"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Es canvia (dilluns matí / dijous tarda)
                  </div>
                </div>
              </div>

              {/* NETEJA CORRONS */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  NETEJA CORRONS
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification3"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    S&apos;elimina Restos-Borra
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification4"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Les gomes estan en bon estat
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification5"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Les gomes s&apos;han de canviar
                  </div>
                </div>
              </div>

              {/* COMPROBAR ENCOLADOR */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  COMPROBAR ENCOLADOR
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification12"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Es canvien
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification13"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Es netejen
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification14"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Estan en bones condicions
                  </div>
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="Verification16"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Estan ajustats a les vores
                  </div>
                </div>
              </div>

              {/* VERIFICACIÓ USUARI */}
              <div className="items-center block col-span-4 text-base print:bg-white">
                <div className="block pb-0.5 border-b-2 print:border-b border-blue-300 text-sm text-grey-darker font-ms-semi col-start-full">
                  VERIFICACIÓ USUARI
                </div>

                <div className="flex items-center justify-start pt-3 space-x-2">
                  <div className="flex items-center">
                    <Field
                      className="w-5 h-5 text-gray-600 cursor-pointer"
                      type="checkbox"
                      name="IsOK"
                    />
                  </div>

                  <div className="items-center block text-sm text-grey-darker font-ms-semi">
                    Verificació usuari
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-4">
              {/* DESCRIPCIÓ */}
              <div className="items-center block col-span-8 text-base print:col-span-12 print:bg-white">
                <div className="block mb-0.5 text-sm text-grey-darker font-ms-semi col-start-full">
                  Descripció:
                </div>

                {/* NORMAL VERSION */}
                <Field
                  className="print:hidden leading-tight print:resize-none print:h-auto print:rounded-none print:border-t-0 print:border-l-0 print:border-r-0 focus:outline-none focus:shadow-outline block h-48 pl-2 rounded-sm text-base w-full appearance-none py-1.5 px-3 p-2 border outline-none text-grey border-blue-200 bg-white hover:border-gray-400 focus:border-gray-400"
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  name="Description"
                  placeholder="Introdueix..."
                  type="text"
                  component="textarea"
                />

                {/* PRINT VERSION */}
                <div className="hidden print:flex items-center w-full py-0.5 text-base leading-tight border-b border-gray-300 min-h-8">
                  {values?.Description}
                </div>
              </div>

              {/* GUARDAR */}
              <div className="flex items-end justify-end col-span-4 print:hidden">
                <button
                  className={`inline-flex items-center h-10 px-4 py-1 text-white transition duration-300 bg-teal-500 border border-teal-500 rounded-sm font-ms-semi ${
                    !isSubmitting &&
                    'hover:border-teal-500 hover:bg-white hover:text-teal-500'
                  }`}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CgSpinner size={22} className="animate-spin xl:mr-2" />
                  ) : (
                    <MdEdit className="xl:mr-2" size={22} />
                  )}

                  <span className="hidden xl:inline-flex">GUARDAR</span>
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

CheckListForm.propTypes = {
  users: PropTypes.array,
  equipments: PropTypes.array,
  fields: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired
}
