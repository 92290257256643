import PropTypes from 'prop-types'
import { useContext, useRef, useState, useEffect } from 'react'

import useFetch from 'hooks/useFetch'

import { UserContext } from 'contexts/UserContext'
import { Formik, Form, Field } from 'formik'
import Select from 'react-select'

import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { FaTrash } from 'react-icons/fa'
import { MdOutlineUploadFile } from 'react-icons/md'

// const ControlVisionForm = ({  
export default function CheckListMaqForm({
  handleSubmit,
  handleChange, 

  fields,
  
  users,
  equipments,

  isNew, 
  
// }) => {
}) {
  const { user } = useContext(UserContext)
  const hiddenFileInput = useRef(null)
  
  const [userOptions, setUserOptions] = useState([])
  const [userOption, setUserOption] = useState({})

  const [equipmentOptions, setEquipmentOptions] = useState([])
  const [equipmentOption, setEquipmentOption] = useState({})

  const [defaultShed, setDefaultShed] = useState({})
  const ShedOptions = [
    { label: 'Cetriko', value: 'Cetriko' },
    { label: '4KF', value: '4KF' }
  ]

  useEffect(() => {
    setDefaultShed(ShedOptions.find((option) => option.value === fields.Shed))
  }, [])

  const [defaultTurn, setDefaultTurn] = useState({})
  const TurnOptions = [
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' }
  ]

  useEffect(() => {
    setDefaultTurn(TurnOptions.find((option) => option.value === fields.Turn))
  }, [])

  useEffect(() => {    
    if (users) {
      setUserOption(userOptions.find((option) => option.value === fields.UserId))
    }
    if (equipments) {
      setEquipmentOption(equipmentOptions.find((option) => option.value === fields.EquipmentId))
    }
  }, [])

  useEffect(() => {
    setUserOptions(users)
    if (fields?.UserId) {
      setUserOption(userOptions.find((option) => option.value === fields.UserId))
    }    
  }, [users])

  useEffect(() => {
    setEquipmentOptions(equipments)
    if (fields?.EquipmentId) {
      setEquipmentOption(equipmentOptions.find((option) => option.value === fields.EquipmentId))
    }    
  }, [equipments])

  useEffect(() => {        
  }, [fields])

  const handleClick = () => {
    hiddenFileInput.current.click()
  }    
  
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <div id='idTabGeneral' className="grid grid-cols-1 gap-x-4 pt-4 pb-4">
            <div id='idForm' className="shadow-2xl grid grid-cols-1">
              <Form className="flex flex-col  bg-blue-100 print:bg-white">
                <h1 className="col-span-4 text-center mt-2 mb-2 text-2xl font-ms-bold">
                  CHECK LIST MAQ
                </h1>

                <h3 className="col-span-4 bg-blue-300 h-1 text-center mb- text-2xl font-ms-bold print:bg-white"></h3>  

                <div className="container relative grid grid-cols-6 print:bg-white">
                  <div 
                    className={`col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}>                    
                    <div
                      className={`w-full col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}
                    >
                      <p className="border-b-4 font-ms-semi print:border-b-0">Operari</p>
                      <Select
                        className="form-select block"
                        onChange={(e) => {
                          setFieldValue('UserId', e.value)
                          setFieldValue('UserName', e.label)
                        }}
                        name="UserId"
                        placeholder="Selecciona usuari"                        
                        options={userOptions}
                        // defaultValue={userOption ?? ''}    
                        value={userOptions.find(
                          (option) => option.value === values.UserId
                        )}                        
                      />                                        
                    </div>
                    
                    <div
                      className={`w-full col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}
                    >
                      <p className="border-b-4 font-ms-semi print:border-b-0">Nau</p>
                      <Select                        
                        className="col-span-2 shadow rounded w-full appearance-none py-0 pl-0 ml-0.5 h-9 text-grey-darker"
                        onChange={(e) => setFieldValue('Shed', e.value)}
                        name="Shed"
                        placeholder="Selecciona..."
                        options={ShedOptions}
                        // defaultValue= {ShedOption}
                        value={ShedOptions.find(
                          (option) => option.value === values.Shed
                        )}
                      />
                    </div>
                  </div>

                  <div className={`col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}>                          
                    <div
                      className={`w-full col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}
                    >
                      <p className="border-b-4 font-ms-semi print:border-b-0">Equip</p>
                      <Select
                        className="form-select block"
                        onChange={(e) => {
                          setFieldValue('EquipmentId', e.value)
                          setFieldValue('EquipmentName', e.label)
                        }}
                        name="EquipmentId"
                        placeholder="Selecciona RAM"                        
                        options={equipmentOptions}
                        // defaultValue={equipmentOption ?? ''}    
                        value={equipmentOptions.find(
                          (option) => option.value === values.EquipmentId
                        )}                        
                      />                                        
                    </div>
                    
                    <div
                      className={`w-full col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}
                    >
                      <p className="border-b-4 font-ms-semi print:border-b-0">Torn</p>
                      <Select                        
                        className="col-span-2 shadow rounded w-full appearance-none py-0 pl-0 ml-0.5 h-9 text-grey-darker"
                        onChange={(e) => setFieldValue('Turn', e.value)}
                        name="Turn"
                        placeholder="Selecciona..."
                        options={TurnOptions}                        
                        value={TurnOptions.find(
                          (option) => option.value === values.Turn
                        )}
                      />
                    </div>  

                  </div>

                  <div className={`col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}>                          
                    <div 
                      className={`col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}                  
                    >
                      <p className="border-b-4 font-ms-semi print:border-b-0">Data</p>
                        <Field
                          className={`col-span-2 shadow rounded w-64 appearance-none border border-gray-300 py-2 pl-2 text-grey-darker`}
                          name="Date"                        
                          type="date"
                          // disabled                        
                          onChange={(e) => {                          
                            handleChange(e)
                          }}
                        />                                  
                    </div>
                    
                    <div
                      className={`w-full col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}
                    >
                      <p className="border-b-4 font-ms-semi print:border-b-0">OF</p>
                      <Field
                        className={`pl-2  shadow  rounded  w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker p-2`}
                        onChange={(e) => {
                          handleChange(e)
                        }}                  
                        name="OF"
                        placeholder="escriu..."
                        type="text"                      
                      />
                    </div>                    
                  </div>                  
                  
                  <div 
                    className={`col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}                  
                  > 
                    <p className="border-b-4  mb-2  border-blue-300 font-ms-semi print:border-b-2">
                      ESTAT CORRONS LLIT 
                    </p>
                    <div className={`container relative grid grid-cols-2 p-3`}>                                            
                      <div>
                        <label
                          className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="Verification6"
                        >
                          En bon estat
                        </label>
                      </div>                        
                      <div>
                        <Field
                          className={`shadow rounded w-5 h-5  border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="Verification6"
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>                              
                      <div>
                        <label
                          className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="Verification7"
                        >
                          En mal estat (Obrir parte Manteniment)
                        </label>
                      </div>
                      <div>
                        <Field
                          className={`shadow rounded w-5 h-5  border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="Verification7"
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div 
                    className={`col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}                  
                  > 
                    <p className="border-b-4  mb-2  border-blue-300 font-ms-semi print:border-b-2">
                      ESTAT DE L&#39;EQUIP TALLADOR (RESIST / DISC)
                    </p>
                    <div className={`container relative grid grid-cols-2 p-3`}>                                            
                      <div>
                        <label
                          className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="Verification8"
                        >
                          En bon estat
                        </label>
                      </div>                        
                      <div>
                        <Field
                          className={`shadow rounded w-5 h-5  border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="Verification8"
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>                              
                      <div>
                        <label
                          className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="Verification9"
                        >
                          En mal estat (Obrir parte Manteniment)
                        </label>
                      </div>
                      <div>
                        <Field
                          className={`shadow rounded w-5 h-5  border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="Verification9"
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div 
                    className={`col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}                  
                  > 
                    <p className="border-b-4  mb-2  border-blue-300 font-ms-semi print:border-b-2">
                      REPÀS DELTA
                    </p>
                    <div className={`container relative grid grid-cols-2 p-3`}>                                            
                      <div>
                        <label
                          className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="Verification1"
                        >
                          En bon estat
                        </label>
                      </div>                        
                      <div>
                        <Field
                          className={`shadow rounded w-5 h-5  border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="Verification1"
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>                              
                      <div>
                        <label
                          className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="Verification2"
                        >
                          En mal estat (Obrir parte Manteniment)
                        </label>
                      </div>
                      <div>
                        <Field
                          className={`shadow rounded w-5 h-5  border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="Verification2"
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div 
                    className={`col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}                  
                  > 
                    <p className="border-b-4  mb-2  border-blue-300 font-ms-semi print:border-b-2">
                      ESTAT DEL CARRO
                    </p>
                    <div className={`container relative grid grid-cols-2 p-3`}>                                            
                      <div>
                        <label
                          className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="Verification10"
                        >
                          En bon estat
                        </label>
                      </div>                        
                      <div>
                        <Field
                          className={`shadow rounded w-5 h-5  border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="Verification10"
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>                              
                      <div>
                        <label
                          className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="Verification11"
                        >
                          En mal estat (Obrir parte Manteniment)
                        </label>
                      </div>
                      <div>
                        <Field
                          className={`shadow rounded w-5 h-5  border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="Verification11"
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>                      
                    </div>                    
                  </div>

                  <div 
                    className={`col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}                  
                  > 
                    <p className="border-b-4  mb-2  border-blue-300 font-ms-semi print:border-b-2">
                      VERIFICACIÓ VISUAL 1ª PEÇA
                    </p>
                    <div className={`container relative grid grid-cols-2 p-3`}>                                            
                      <div>
                        <label
                          className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="Verification15"
                        >
                          OK
                        </label>
                      </div>                        
                      <div>
                        <Field
                          className={`shadow rounded w-5 h-5  border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="Verification15"
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>                              
                      <div>
                        <label
                          className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="Verification3"
                        >
                          NOK
                        </label>
                      </div>
                      <div>
                        <Field
                          className={`shadow rounded w-5 h-5  border border-gray-300 py-2 px-3 text-grey-darker`}
                          name="Verification3"
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e)
                          }}
                        />
                      </div>                      
                    </div>               
                  </div>

                  <div 
                    className={`col-span-2 bg-blue-100 items-center block p-2 print:bg-white`}                  
                  > 
                    <p className="border-b-4  mb-2  border-blue-300 font-ms-semi print:border-b-2">
                      VERIFICACIÓ USUARI
                    </p>
                    <div className={`container relative grid grid-cols-2 p-3`}>                                            
                      <div>
                        <label
                          className={`col-span-2 block mr-2 text-grey-darker text-md font-ms-semi`}
                          htmlFor="Verification12"
                        >
                          Verificació usuari
                        </label>
                      </div>                        
                      <div>
                        <Field
                          // disabled={readOnly || !canEditTeixi}
                            as="select"
                            name="IsOK"
                            onChange={(e) => {
                              handleChange(e)
                              // setTimeout(submitForm, 0)
                            }}
                            className="form-select block h-8 border rounded ml-16 2xl:ml-0 border-gray-300 mr-1"
                          >
                            <option
                              selected={values.IsOK === false && 'selected'}
                              value={false}
                            >
                              No
                            </option>
                            <option
                              selected={values.IsOK === true && 'selected'}
                              value={true}
                            >
                              Si
                            </option>
                          </Field>                          
                      </div>                              
                    </div>                    
                  </div>

                  <h3 className="col-span-6 bg-blue-300 h-1 text-center mb- text-2xl font-ms-bold"></h3>  
                  
                  <div
                    className={`w-full col-span-4 bg-blue-100 items-center block p-2 print:bg-white print:col-span-6`}
                  >
                    <p className="border-b-4 font-ms-semi print:border-b-0">Descripció</p>
                    <Field
                      className={`pl-2 h-48 shadow  rounded  w-full appearance-none border border-gray-300 py-2 px-3 text-grey-darker p-2`}
                      onChange={(e) => {
                        handleChange(e)
                      }}                  
                      name="Description"
                      placeholder="escriu..."
                      type="text"        
                      component="textarea"
                    />                    
                  </div>          
                  
                  <div id="preven-guardar" className="col-span-2 flex flex-row p-24 justify-center">                  
                    <button
                      className="2xl:mr-5 block transition duration-300 
                      border-primary hover:border-blue-200 bg-primary text-white 
                      font-ms-semi 
                      mt-3 mb-4 mr-2 py-1 px-4 ml-auto
                      border rounded h-10 w-28
                      hover:bg-blue-200 hover:text-primary
                      print:hidden"
                      type="submit" 
                    >
                      GUARDAR
                    </button>     
                </div>

                </div>                
              </Form>
            </div>
            {/* fin idGeneral */}
          </div>  
                        
        )}        
      </Formik>                 
    </>
  )
}

CheckListMaqForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isUpdate: PropTypes.any,
  
  users: PropTypes.array,  
  equipments: PropTypes.array,  

  isNew: PropTypes.bool,
}

// export default ControlVisionForm