import esquemaTeixiduria from 'assets/img/esquema-teixiduria.png'
import ToolTipIcon from 'components/icons/ToolTipIcon'
import { ModalContext } from 'contexts/ModalContext'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import ThreadTable from './threadTableView'

export default function DescriptionForm({ values, threads }) {
  // Context
  const { handleModal } = useContext(ModalContext)

  // Fetch
  const [getApiCallGetSemiFinished, setApiCallSemiFinished] = useFetch()

  useEffect(async () => {
    // CRUD: GET (Semifinished):
    const apiCallGetSemiFinished = {
      url: `semifinished`,
      method: 'GET',
      messageKo: 'Error al recuperar dades del ECAP'
    }

    await Promise.all([setApiCallSemiFinished(apiCallGetSemiFinished)]).catch(
      (e) => {
        console.log(e)
      }
    )
  }, [])

  return (
    <div className="grid items-center grid-cols-12 p-3 gap-y-3 auto-rows-min">
      {/* Semielaborat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Semielaborat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.SemiFinished}
        </div>
      </div>

      {/* Tipus lligat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Tipus lligat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {
            getApiCallGetSemiFinished?.data?.find(
              (option) => option.semi === values.SemiFinished
            )?.ligado
          }
        </div>
      </div>

      {/* Tabla: Ideal | Tol.Màx. */}
      <div className="grid grid-cols-12 col-span-12">
        <div className="grid grid-cols-12 col-span-12">
          <div className="grid grid-cols-12 col-span-9 col-start-4 col-end-13 gap-x-2">
            <div className="grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 text-sm border-b border-r border-black font-ms-semi">
                Ideal
              </div>

              <div className="flex flex-row items-center justify-center w-full col-span-6 col-end-13 text-sm border-b border-black font-ms-semi">
                Tol. Màx.
              </div>
            </div>
          </div>
        </div>

        {/* Campo 'Passades': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Passades:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="w-full grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.Repetitions}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.RepetitionsTolerance}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.RepetitionsV2}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.RepetitionsToleranceV2}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>u/cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Columnes': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Columnes:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="w-full grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.Columns}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.ColumnsTolerance}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.ColumnsV2}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.ColumnsToleranceV2}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>u/cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Gramatge': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Gramatge:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="w-full grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.Weight}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.WeightTolerance}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.WeightV2}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.WeightToleranceV2}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>g/m2</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Ample': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Ample:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="w-full grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.Width}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.WidthTolerance}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.WidthV2}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.WidthToleranceV2}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>cm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Gruix': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Gruix:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="w-full grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.Thickness}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.ThicknessTolerance}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.ThicknessV2}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.ThicknessToleranceV2}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>mm</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Rdt Nominal': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="flex items-center col-span-3 text-sm text-grey-darker font-ms-semi">
            Rdt Nominal:
          </div>

          <div className="grid grid-cols-12 col-span-9 gap-x-2 justify-items-end">
            <div className="w-full grid grid-cols-12 col-span-10">
              <div className="flex flex-row items-center justify-center col-span-6 pr-1.5 border-r border-black text-sm">
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.RDTNominal}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.RDTNominalTolerance}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center col-span-6 pl-1.5">
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.RDTNominalV2}
                </div>
                &nbsp;&plusmn;&nbsp;
                <div className="w-full appearance-none px-0.5 rounded-sm mb-1 mt-2 text-sm border border-gray-300 outline-none text-grey-darker">
                  {values?.RDTNominalToleranceV2}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between col-span-2 space-x-1 text-sm">
              <div>m/kg</div>
              <div className="hidden w-6 h-6 border border-black"></div>
            </div>
          </div>
        </div>

        {/* Campo 'Comentari': */}
        <div className="grid grid-cols-12 col-span-12">
          <div className="block col-span-12 my-1 text-sm text-grey-darker font-ms-semi">
            Comentari:
          </div>

          <div className="col-span-12 rounded-sm w-full appearance-none h-24 px-1 py-0.5 border border-gray-300 outline-none text-grey-darker align-middle text-sm">
            {values?.LaboratoryComment}
          </div>
        </div>
      </div>

      {/* Lligat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Lligat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.TiedDescription}
        </div>
      </div>

      {/* Diagrama: */}
      <div className="flex col-span-12 rounded">
        <p className="mr-1 text-right">
          1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />
          10
          <br />
          11
          <br />
          12
          <br />
        </p>

        <div
          className="w-full px-2 py-1 pt-0 overflow-hidden leading-tight  bg-blue-100 border border-gray-300 rounded-sm resize-none faden"
          style={{ whiteSpace: 'pre-line', lineHeight: '1.5rem' }}
        >
          {values?.EncryptedCode}
        </div>

        <div
          onClick={() =>
            handleModal(<img src={esquemaTeixiduria} alt="llegenda" />)
          }
          className="cursor-pointer"
          style={{ height: 'fit-content' }}
        >
          <ToolTipIcon width={20} height={20} className="ml-2 mr-1" />
        </div>
      </div>

      {/* Màquina */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Màquina:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-4/12 h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.MachineCode}
        </div>
        <div className="flex items-center w-8/12 h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Machine}
        </div>
      </div>

      {/* Plegador */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Plegador:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.FoldType}
        </div>
      </div>

      {/* GG - ø */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        GG - ø:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.GG}
        </div>
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.O}
        </div>
      </div>

      {/* Nº Jocs */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Nº Jocs:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.NGame}
        </div>
      </div>

      {/* Agulles */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Agulles:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Needle}
        </div>
      </div>

      {/* Rpm */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Rpm:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.Rpm}
        </div>
      </div>

      {/* Rpm Mín. */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Rpm Mín.:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.RpmMin}
        </div>
      </div>

      {/* V. / Kg */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Kg:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="w-full px-1.5 py-1 h-full leading-tight text-base border bg-gray-100 text-gray-500 rounded-sm border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.TurnsPerKg}
        </div>
        <span>&plusmn;</span>
        <div className="w-full px-1.5 py-1 h-full leading-tight text-base border bg-gray-100 text-gray-500 rounded-sm border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.TurnsPerKgTolerance}
        </div>
      </div>

      {/* V. / Peça (Reals) */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Peça (Reals):
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="flex items-center w-full h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
          {values?.TurnsPerPart}
        </div>
      </div>

      {/* V. / Peça (Fitxa) */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        V. / Peça (Fitxa):
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="w-full px-1.5 py-1 h-full leading-tight text-base border bg-gray-100 text-gray-500 rounded-sm border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.TurnsPerPartByOperator}
        </div>
        <span>&plusmn;</span>
        <div className="w-full px-1.5 py-1 h-full leading-tight text-base border bg-gray-100 text-gray-500 rounded-sm border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.TurnsPerPartByOperatorTolerance}
        </div>
      </div>

      {/* Kg peça */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Kg peça:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div className="w-full px-1.5 py-1 h-full leading-tight text-base border bg-gray-100 text-gray-500 rounded-sm border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.KgPart}
        </div>
        <span>&plusmn;</span>
        <div className="w-full px-1.5 py-1 h-full leading-tight text-base border bg-gray-100 text-gray-500 rounded-sm border-gray-300 overflow-ellipsis overflow-hidden flex items-center">
          {values?.KgPartTolerance}
        </div>
      </div>

      {/* Pass. Seguretat */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Pass. Seguretat:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <div
          className={`flex items-center ${
            values?.SecurityStep === 'true' || values?.SecurityStep
              ? 'w-4/12'
              : 'w-full'
          } h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis`}
        >
          {values?.SecurityStep ? 'Sí' : 'No'}
        </div>

        {(values?.SecurityStep === 'true' || values?.SecurityStep) && (
          <div className="flex items-center w-8/12 h-full px-2 py-1 overflow-hidden text-base leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded-sm overflow-ellipsis">
            {values?.SecurityStepDescription === undefined ||
            !values?.SecurityStepDescription
              ? 'Segons cartell'
              : values?.SecurityStepDescription}
          </div>
        )}
      </div>

      {/* Tabla: Hilos */}
      <div className="w-full col-span-12">
        <ThreadTable
          threads={threads}
          numberGames={values?.NGame ? Number(values?.NGame) : 0}
        />
      </div>
    </div>
  )
}

DescriptionForm.propTypes = {
  values: PropTypes.object,
  threads: PropTypes.any
}
