import DescriptionForm from 'forms/ftteixiduria/proto/description'
import FolderForm from 'forms/ftteixiduria/proto/folder'
import HeightForm from 'forms/ftteixiduria/proto/height'
import ObservationsForm from 'forms/ftteixiduria/proto/observations'
import PointGraduationForm from 'forms/ftteixiduria/proto/pointGraduation'
import SprocketsForm from 'forms/ftteixiduria/proto/sprockets'
import PropTypes from 'prop-types'

export default function BlockProto({ title, className, machineCode }) {
  return (
    <div
      className={`bg-white rounded flex flex-col border-2 border-blue-200 border-collapse shadow-md print:shadow-none ${
        className || ''
      }`}
    >
      <div
        className={`bg-blue-200 w-full flex flex-row justify-between items-center uppercase font-ms-bold text-sm`}
      >
        <span className="py-2 pl-2 print:py-1">{title}</span>
        {title !== 'Descripció' && (
          <span className="hidden print:inline mb-0.5 mr-0.5 w-6 h-6 border border-black bg-white"></span>
        )}
      </div>

      {title === 'Descripció' && <DescriptionForm />}
      {title === 'Plegador / Calandra' && <FolderForm machineCode={machineCode} />}
      {title === 'Pinyons / Politja' && <SprocketsForm machineCode={machineCode} />}
      {title === 'Graduació del punt i consum' && <PointGraduationForm />}
      {title === 'Alçada i sincronisme' && <HeightForm />}
      {title === 'Observacions / Notes' && <ObservationsForm />}
    </div>
  )
}

BlockProto.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  machineCode: PropTypes.string.isRequired
}
