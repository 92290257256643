import alcades from 'assets/icons/alcades.png'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import GamesTable from './gamesTableCreate'

export default function PointGraduationForm({
  setFieldValue,
  values,
  games,
  threads,
  setRequestFieldsState,
  fullValues
}) {
  return (
    <div
      className="grid items-center grid-cols-12 p-3 gap-y-3"
      style={{ gridTemplateRows: 'repeat(3, min-content) 1fr min-content' }}
    >
      {/* Alçada cilindre (seccions): */}
      <div className="grid items-center grid-cols-4 col-span-12">
        <label className="block col-span-4 mb-1 text-sm text-grey-darker font-ms-semi col-start-full">
          Alçada cilindre (seccions):
        </label>

        <div className="relative col-span-3 rounded">
          <img src={alcades} alt="Pinyons Plegador" width={150} />

          <Field
            type="text"
            name="RequestArticle.CylinderHeight1"
            className="absolute left-0 px-2 py-1 leading-tight border border-gray-300 rounded appearance-none bottom-1 sm:w-12 lg:w-16 focus:outline-none focus:shadow-outline hover:border-gray-400 focus:border-gray-400"
          />

          <Field
            type="text"
            name="RequestArticle.CylinderHeight2"
            className="absolute px-2 py-1 leading-tight border border-gray-300 rounded appearance-none bottom-1 sm:left-16 sm:w-12 lg:left-20 lg:w-16 focus:outline-none focus:shadow-outline hover:border-gray-400 focus:border-gray-400"
          />
        </div>
      </div>

      {/* Posició Aro Guiafils */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Posició Aro Platines:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          as="textarea"
          name="RequestArticle.PositionTurntables"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* Posició Aro Guiafils */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Posició Aro Guiafils:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 lg:col-span-8 xl:col-span-9">
        <Field
          as="textarea"
          name="RequestArticle.PositionGuiafils"
          className={`w-full h-full px-2 py-1 leading-tight border border-gray-300 rounded-sm appearance-none
              hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:shadow-outline`}
        />
      </div>

      {/* Tabla: Juegos */}
      <div className="w-full col-span-12 place-self-start">
        <GamesTable
          games={games}
          threadOptions={threads}
          setFieldValue={setFieldValue}
          setRequestFieldsState={setRequestFieldsState}
          fullValues={fullValues}
        />
      </div>

      {/* Tensiòmetre */}
      <div className="col-span-12 mt-5 text-sm lg:mt-0 lg:col-span-4 xl:col-span-3 font-ms-semi">
        Tensiòmetre:
      </div>
      <div className="flex items-center h-8 col-span-12 space-x-2 cursor-not-allowed lg:col-span-8 xl:col-span-9">
        <input
          type="checkbox"
          className="w-6 h-6 ml-1"
          onChange={(e) => {
            setFieldValue('PassTensiometer', e.target.checked)
          }}
          name="RequestArticle.PassTensiometer"
          checked={values.PassTensiometer}
        />
      </div>
    </div>
  )
}

PointGraduationForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  games: PropTypes.any,
  threads: PropTypes.any,
  setRequestFieldsState: PropTypes.func,
  fullValues: PropTypes.object
}
