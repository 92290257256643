import PropTypes from 'prop-types'
import Form1 from 'forms/ftteixiduriarequest/descriptionCompare'
import Form2 from 'forms/ftteixiduriarequest/folderCompare'
import Form3 from 'forms/ftteixiduriarequest/sprocketsCompare'
import Form4 from 'forms/ftteixiduriarequest/pointGraduationCompare'
import Form5 from 'forms/ftteixiduriarequest/heightCompare'
import Form6 from 'forms/ftteixiduriarequest/observationsCompare'

export default function Block({
  title,
  className,
  side,
  isAnswered,
  valuesBefore,
  valuesAfter,
  threads,
  games
}) {
  return (
    <div
      className={`${className && className} 
      rounded border-2 shadow-md ${
        side === 'left' ? 'border-gray-300' : 'border-blue-200'
      }`}
    >
      <div
        className={`flex items-center justify-between w-full px-2 py-2 text-sm uppercase ${
          side === 'left' ? 'bg-gray-300' : 'bg-blue-200'
        } print:py-1 font-ms-bold`}
      >
        <span>{title}</span>
      </div>

      {(() => {
        switch (title) {
          case 'Descripció':
            return (
              <Form1
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
                threads={threads}
              />
            )
          case 'Plegador / Calandra':
            return (
              <Form2
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            )
          case 'Pinyons / Politja':
            return (
              <Form3
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            )
          case 'Graduació del punt i consum':
            return (
              <Form4
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
                games={games}
              />
            )
          case 'Alçada i sincronisme':
            return (
              <Form5
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            )
          case 'Observacions / Notes de fitxa tècnica de teixiduria':
            return (
              <Form6
                side={side}
                isAnswered={isAnswered}
                valuesBefore={valuesBefore}
                valuesAfter={valuesAfter}
              />
            )
          default:
            return null
        }
      })()}
    </div>
  )
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  side: PropTypes.string.isRequired,
  isAnswered: PropTypes.bool,
  valuesBefore: PropTypes.object,
  valuesAfter: PropTypes.object,
  threads: PropTypes.any,
  games: PropTypes.any
}
