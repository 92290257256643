import { ModalContext } from 'contexts/ModalContext'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import CheckListForm from 'forms/Control/CheckListMaq/CheckListMaqForm'
import useFetch from 'hooks/useFetch'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'wouter'

export default function CheckListMaqDetail({ params }) {
  const equipmentOptions = []
  const [equipmentList, equipmentListAction] = useFetch()
  const { equipment } = useContext(UserContext)

  const userOptions = []
  const [userList, userListAction] = useFetch()
  const { user } = useContext(UserContext)

  const { handleModal } = useContext(ModalContext)
  const checklistOptions = []
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const [client, saveClient] = useFetch()
  const isNew = params.id === 'nou'
  const [checklistList, checklistListAction] = useFetch()

  const [fields, setFields] = useState({
    Id: 0,
    EquipmentId: '',
    EquipmentName: '',
    Turn: '',
    Shed: '',
    UserId: '',
    UserName: '',
    OF: '',
    Date: '',
    Verification1: '',
    Verification2: '',
    Verification3: '',
    Verification4: '',
    Verification5: '',
    Verification6: '',
    Verification7: '',
    Verification8: '',
    Verification9: '',
    Verification10: '',
    Verification11: '',
    Verification12: '',
    Verification13: '',
    Verification14: '',
    Verification15: '',
    Description: '',
    IsOK: ''
  })

  const titleEdit = {
    name: `Modificar registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/checklistmaq`
      }
    ],
    deleteCheckListMaqBtn: true,
    checklistmaqId: params.id,
    button: 'print'
  }

  const titleCreate = {
    name: `Crear registre `,
    buttons: [
      {
        name: 'Tornar',
        link: `/checklistmaq`
      }
    ]
  }

  useEffect(() => {
    const userListAPIParams = {
      url: `user`,
      method: 'GET',
      messageKo: 'Error al recuperar Users del ECAP'
    }
    userListAction(userListAPIParams)
  }, [])

  useEffect(() => {
    if (userList.data) {
      userList.data.forEach((user) => {
        userOptions.push({
          value: user.Id,
          // label: user.Id + '-' + user.Name
          label: user.Name
        })
      })
    }
  }, [userList])

  useEffect(() => {
    const equipmentListAPIParams = {
      url: `inventorymachine/isTool`,
      method: 'GET',
      messageKo: 'Error al recuperar dades'
    }
    equipmentListAction(equipmentListAPIParams)
  }, [])

  useEffect(() => {
    if (equipmentList.data) {
      equipmentList.data.forEach((equipment) => {
        equipmentOptions.push({
          value: equipment.Id,
          // label: user.Id + '-' + user.Name
          label: equipment.Description
        })
      })
    }
  }, [equipmentList])

  // -----------------------
  useEffect(() => {
    if (checklistList.data) {
      // console.log('mtList.data: ',mtList.data)
      checklistList.data.forEach((checklist) => {
        checklistOptions.push({
          value: checklist.Id,
          label: `${checklist.Description}`
        })
      })
    }
  }, [checklistList])
  // -----------------------

  useEffect(() => {
    const checklistListAPIParams = {
      url: `checklistmaq`,
      method: 'GET',
      messageOK: 'Error al recuperar dades'
    }

    checklistListAction(checklistListAPIParams)

    if (params.id !== 'nou') {
      setTitle(titleEdit)
      const findClientById = {
        url: `checklistmaq/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recuperar dades'
      }

      saveClient(findClientById)
    } else setTitle(titleCreate)
  }, [])

  useEffect(() => {
    // Set initialValues
    if (client.data) {
      setFields({
        Id: client.data.Id,
        EquipmentId: client.data.EquipmentId,
        EquipmentName: client.data.EquipmentName,
        Turn: client.data.Turn,
        Shed: client.data.Shed,
        UserId: client.data.UserId,
        UserName: client.data.UserName,
        OF: client.data.OF,
        Date: client.data.Date,
        Verification1: client.data.Verification1,
        Verification2: client.data.Verification2,
        Verification3: client.data.Verification3,
        Verification4: client.data.Verification4,
        Verification5: client.data.Verification5,
        Verification6: client.data.Verification6,
        Verification7: client.data.Verification7,
        Verification8: client.data.Verification8,
        Verification9: client.data.Verification9,
        Verification10: client.data.Verification10,
        Verification11: client.data.Verification11,
        Verification12: client.data.Verification12,
        Verification13: client.data.Verification13,
        Verification14: client.data.Verification14,
        Verification15: client.data.Verification15,
        Description: client.data.Description,
        IsOK: client.data.IsOK
      })
    }
  }, [client.data])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFields((prevFields) => ({ ...prevFields, [name]: value }))
  }

  const handleSubmit = async (values) => {
    let url = `checklistmaq`
    let method = 'post'

    if (params.id !== 'nou') {
      method = 'put'
      url += `/${params.id}`
    }

    // Send PUT //
    const clientApiParams = {
      url,
      method,
      body: values,
      messageKo: 'Error al recuperar dades dels Check List',
      messageOk: 'CheckList actualitzat!'
    }
    await saveClient(clientApiParams)
    if (!client.error) setLocation('/checklistmaq')
  }

  return (
    <div className="w-full p-4 bg-white">
      <CheckListForm
        fields={fields}
        checklistList={checklistOptions}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isNew={isNew}
        users={userOptions}
        equipments={equipmentOptions}
      />
    </div>
  )
}

CheckListMaqDetail.propTypes = {
  params: PropTypes.any
}
